import React, { useEffect, useState, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardImgOverlay, CardBody } from '../../components/card/card.jsx';
import { AppSettings } from '../../config/app-settings.js';
import { getGenericData } from '../../api/generic-get.js';
import UserImage from '../../features/images/userImage.js';
import debounce from 'lodash.debounce';
import { AcceptFriendRequestForm } from './hooks/accept_friend_request_form.js';
import { RejectFriendRequestForm } from './hooks/reject_friend_request_form.js';
import { CancelFriendRequestForm } from './hooks/cancel_friend_request_form.js';
import { RemoveFriendshipForm } from './hooks/remove_friendship_form.js';
import AlertTransition from '../../features/alert_success_transitions.js';
import { Link } from 'react-router-dom';

function Friendship() {

	// Handling global settings
	const backend_endpoint = '/api/friendship';
	// END - Handling global settings

	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
	const [errMsg, setErrMsg] = useState({});
	const [ackMsg, setAckMsg] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	// Handling template context
	const context = useContext(AppSettings);

	useEffect(() => {
		context.setAppContentClass('p-3');
		context.setAppContentFullHeight(true);

		return function cleanUp() {
			context.setAppContentClass('');
			context.setAppContentFullHeight(false);
		};

		// eslint-disable-next-line
	}, []);
	// END - Handling template context

	// GET 
	// Handling fetching friendships
	const [friendships, setFriendships] = useState([]);

	const getFriendshipsData = async () => {
		try {
			const queryParams = new URLSearchParams({
				friendships: true,
			});
			const url = `${backend_endpoint}?${queryParams.toString()}`;
			const { data, error } = await getGenericData(url);

			if (data && Object.keys(data).length > 0 && !data.message) {
				setFriendships(data.friendships || []);
			} else {
				setFriendships([]);
			}

			if (error) {
				setErrMsg(error);
			}
		} catch (error) {
			setErrMsg(error);
		}
	};

	useEffect(() => {
		getFriendshipsData();
	}, []);

	// END - Handling fetching friendships

	// Handling fetching friendships
	const [friendshipRequests, setFriendshipRequests] = useState({ received_requests: [], sent_requests: [] });

	const getFriendshipRequestsData = async () => {
		try {
			const queryParams = new URLSearchParams({
				friendship_requests: true,
			});
			const url = `${backend_endpoint}?${queryParams.toString()}`;
			const { data, error } = await getGenericData(url);

			if (data && Object.keys(data).length > 0 && !data.message) {
				setFriendshipRequests(data.friendship_requests || { received_requests: [], sent_requests: [] });
			} else {
				setFriendshipRequests({ received_requests: [], sent_requests: [] });
			}

			if (error) {
				setErrMsg(error);
			}
		} catch (error) {
			setErrMsg(error);
		}
	};

	useEffect(() => {
		getFriendshipRequestsData();
	}, []);

	// END - Handling fetching friendships
	// END - GET

	const [searchTerm, setSearchTerm] = useState('');

	const filteredFriends = friendships.filter(friend =>
	    !searchTerm || (friend.username || "").toLowerCase().includes(searchTerm.toLowerCase())
	);
	const filteredSentRequests = friendshipRequests.sent_requests.filter(request =>
	    !searchTerm || (request.username || "").toLowerCase().includes(searchTerm.toLowerCase())
	);
	const filteredReceivedRequests = friendshipRequests.received_requests.filter(request =>
	    !searchTerm || (request.username || "").toLowerCase().includes(searchTerm.toLowerCase())
	);

	// POST
	// END - POST

	// Internal Data Management
	const [selectedFriend, setSelectedFriend] = useState(null);

	const handleFriendClick = (friend) => {
		setSelectedFriend(friend);
	};
	// END - Internal Data Management


	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
	const combinedSuccess = { ...ackMsg };
	const combinedErrors = { ...errMsg };

	// Function to handle closing the alert
	const handleClose = () => {
		setShowAlert(false);
		setErrMsg({});
	};
	const handleSuccessClose = () => {
		setShowSuccess(false);
		setAckMsg({});
	};

	// Debounce the function to avoid multiple updates
	const debouncedHandleClose = debounce(handleClose, 500);
	const debouncedHandleSuccessClose = debounce(handleSuccessClose, 500);

	useEffect(() => {
		let timer;
		// Handle error messages
		if (Object.keys(combinedErrors).length > 0) {
			setShowAlert(true);
			timer = setTimeout(() => {
				debouncedHandleClose();
			}, 5000);
		} else {
			setShowAlert(false);
		}

		// Handle success messages
		if (Object.keys(combinedSuccess).length > 0) {
			setShowSuccess(true);
			timer = setTimeout(() => {
				debouncedHandleSuccessClose();
			}, 5000);
		} else {
			setShowSuccess(false);
		}

		// Clean up function for both alerts
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
			debouncedHandleClose.cancel();
			debouncedHandleSuccessClose.cancel();
		};
	}, [combinedErrors, combinedSuccess]); // Depend on both state changes

	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	return (
		<div className="h-100">
			<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
			<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
			<Card className="h-100">
				<div className="fireteam">
					<div className="fireteam-sidebar">
						<div className="fireteam-sidebar-header">
							<h3 className="mb-10px">Fireteam</h3>
							<div className="position-relative">
								<button type="submit" className="btn position-absolute top-0"><i className="bi bi-search"></i></button>
								<input type="text" className="form-control rounded-pill ps-35px" placeholder="Search Friends"
									value={searchTerm}
									onChange={e => setSearchTerm(e.target.value)} />
							</div>
						</div>
						<div className="fireteam-sidebar-body">
							<PerfectScrollbar className="h-100">
								<div className="fireteam-item">
									<div data-toggle="fireteam-content" className="fireteam-link">
										<div className="fireteam-info">
											<div className="fireteam-name text-indigo">Friend requests sent</div>
										</div>
									</div>
								</div>
								{filteredSentRequests.length > 0 ? (
									filteredSentRequests.map((request, index) => (
										<div key={`friend-req-requested-${request.id}`} className="fireteam-item" onClick={() => handleFriendClick(request)}>
											<div data-toggle="fireteam-content" className="fireteam-link">
												<div className="fireteam-media">
													<Link to={`/profile/${request.user_id}`}>
														<UserImage imageType={request.profile_pic} altText="" className="ms-100 mh-100 rounded-circle" />
													</Link>
												</div>
												<div className="fireteam-info">
													<Link to={`/profile/${request.user_id}`} className='text-white text-decoration-none'>
														<div className="fireteam-name">{request.username || "Unset"} <span className="badge border border-warning text-warning mb-2">{request.status}</span></div>
													</Link>
													<div className="fireteam-text">{request.created_at}</div>
												</div>
												<div className="dropdown">
													<a href="#/" data-bs-toggle="dropdown" className="text-white text-opacity-50"><i className="fa fa-ellipsis-h"></i></a>
													<div className="dropdown-menu dropdown-menu-end">
														<CancelFriendRequestForm
															getFriendshipRequestsData={getFriendshipRequestsData}
															request_id={request.id}
															requests={friendshipRequests}
															backend_endpoint={backend_endpoint}
															setAckMsg={setAckMsg}
															setErrMsg={setErrMsg}
															ButtonClass="dropdown-item"
															ButtonText="CANCEL" />
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<div className="fireteam-item">
										<div data-toggle="fireteam-content" className="fireteam-link">
											<div className="fireteam-info">
												<div className="fireteam-name">No sent requests found</div>
											</div>
										</div>
									</div>
								)}
								<div className="fireteam-item">
									<div data-toggle="fireteam-content" className="fireteam-link">
										<div className="fireteam-info">
											<div className="fireteam-name text-indigo">Friend requests received</div>
										</div>
									</div>
								</div>
								{filteredReceivedRequests.length > 0 ? (
									filteredReceivedRequests.map((request, index) => (
										<div key={`friend-req-received-${request.id}`} className="fireteam-item" onClick={() => handleFriendClick(request)}>
											<div data-toggle="fireteam-content" className="fireteam-link">
												<div className="fireteam-media">
													<Link to={`/profile/${request.user_id}`}>
														<UserImage imageType={request.profile_pic} altText="" className="ms-100 mh-100 rounded-circle" />
													</Link>
												</div>
												<div className="fireteam-info">
													<Link to={`/profile/${request.user_id}`} className='text-white text-decoration-none'>
														<div className="fireteam-name">{request.username || "Unset"} <span className="badge border border-warning text-warning mb-2">{request.status}</span></div>
													</Link>
													<div className="fireteam-text">{request.created_at}</div>
												</div>
												<div className="dropdown">
													<a href="#/" data-bs-toggle="dropdown" className="text-white text-opacity-50"><i className="fa fa-ellipsis-h"></i></a>
													<div className="dropdown-menu dropdown-menu-end">
														<AcceptFriendRequestForm
															getFriendshipsData={getFriendshipsData}
															getFriendshipRequestsData={getFriendshipRequestsData}
															request_id={request.id}
															requests={friendshipRequests}
															backend_endpoint={backend_endpoint}
															setAckMsg={setAckMsg}
															setErrMsg={setErrMsg}
															ButtonClass="dropdown-item"
															ButtonText="ACCEPT" />
														<RejectFriendRequestForm
															getFriendshipRequestsData={getFriendshipRequestsData}
															request_id={request.id}
															requests={friendshipRequests}
															backend_endpoint={backend_endpoint}
															setAckMsg={setAckMsg}
															setErrMsg={setErrMsg}
															ButtonClass="dropdown-item"
															ButtonText="REJECT" />
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<div className="fireteam-item">
										<div data-toggle="fireteam-content" className="fireteam-link">
											<div className="fireteam-info">
												<div className="fireteam-name">No received requests found</div>
											</div>
										</div>
									</div>
								)}
								<div className="fireteam-item">
									<div data-toggle="fireteam-content" className="fireteam-link">
										<div className="fireteam-info">
											<div className="fireteam-name text-success">Friends</div>
										</div>
									</div>
								</div>
								{filteredFriends.length > 0 ? (filteredFriends.map((friend, index) => (
									<div key={`friend-${friend.id}`} className="fireteam-item" onClick={() => handleFriendClick(friend)}>
										<div data-toggle="fireteam-content" className="fireteam-link">
											<div className="fireteam-media">
												<Link to={`/profile/${friend.id}`}>
													<UserImage imageType={friend.profile_pic} altText="" className="ms-100 mh-100 rounded-circle" />
												</Link>
											</div>
											<div className="fireteam-info">
												<Link to={`/profile/${friend.id}`} className='text-white text-decoration-none'>
													<div className="fireteam-name">{friend.username || "Unset"}</div>
												</Link>
												<div className="fireteam-text">{friend.date}</div>
											</div>
											<div className="dropdown">
												<a href="#/" data-bs-toggle="dropdown" className="text-white text-opacity-50"><i className="fa fa-ellipsis-h"></i></a>
												<div className="dropdown-menu dropdown-menu-end">
													<RemoveFriendshipForm
														getFriendshipsData={getFriendshipsData}
														friend_id={friend.id}
														friends={friendships}
														backend_endpoint={backend_endpoint}
														setAckMsg={setAckMsg}
														setErrMsg={setErrMsg}
														ButtonClass="dropdown-item"
														ButtonText="UNFRIEND" />
												</div>
											</div>
										</div>
									</div>
								))
								) : (
									<div className="fireteam-item">
										<div data-toggle="fireteam-content" className="fireteam-link">
											<div className="fireteam-info">
												<div className="fireteam-name">No friends found</div>
											</div>
										</div>
									</div>
								)
								}
							</PerfectScrollbar>
						</div>
					</div>
					<div className="fireteam-content">
						<div className="fireteam-content-header">
							<div className="fireteam-content-header-media">
								<div className="media text-white text-opacity-75 fs-24px fw-bold">
									<i className="bi bi-person-square"></i>
								</div>
							</div>
							<div className="fireteam-content-header-info">
								FRIEND DETAILS
							</div>
						</div>
						<div className="fireteam-content-body">
							<PerfectScrollbar className="h-100">
								{selectedFriend ? (
									<div className="player-details">
										<Card>
											<div className="m-1 bg-white bg-opacity-15">
												<div className="position-relative overflow-hidden" style={{ height: '165px' }}>
													{selectedFriend.stats_pic ?
														<UserImage imageType={selectedFriend.stats_pic} altText="" className="card-img rounded-0" />
														:
														<UserImage imageType={selectedFriend.profile_pic} altText="" className="card-img rounded-0" />
													}
													<CardImgOverlay className="text-white text-center bg-dark-transparent-5">
														<div className="mb-2">
															<UserImage imageType={selectedFriend.profile_pic} altText="" width="80px" className="rounded-circle" />
														</div>
														<div>
															<div className="fw-bold">{selectedFriend.username}</div>
															<div className="fs-12px">{selectedFriend.usernmae || "No bio available"}</div>
														</div>
													</CardImgOverlay>
												</div>
												<CardBody className="py-2 px-3">
													<div className="row text-center">
														<div className="col-4">
															<div className="fw-bold">{selectedFriend.username}</div>
															<div className="fs-12px">posts</div>
														</div>
														<div className="col-4">
															<div className="fw-bold">{selectedFriend.username}</div>
															<div className="fs-12px">followers</div>
														</div>
														<div className="col-4">
															<div className="fw-bold">{selectedFriend.username}</div>
															<div className="fs-12px">following</div>
														</div>
													</div>
												</CardBody>
											</div>
										</Card>
									</div>
								) : (
									<div className="player-details">
										<Card>
											<div className="m-1 bg-white bg-opacity-15">
												<div className="position-relative overflow-hidden" style={{ height: '75px' }}>
													<CardImgOverlay className="text-white text-center bg-dark-transparent-5">
														<div className="mb-2">
														</div>
														<div>
															<div className="fw-bold">Select a friend to see more details</div>
														</div>
													</CardImgOverlay>
												</div>
											</div>
										</Card>
									</div>)}
							</PerfectScrollbar>
						</div>

						<div className="fireteam-content-footer">

						</div>
					</div>
				</div>
			</Card>
		</div>
	)
}

export default Friendship;
