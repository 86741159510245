import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';

export function LeaveLobbyForm({ lobby_id, backend_endpoint, setAckMsg, setErrMsg, ButtonClass, ButtonText, disabled }) {
	const formik = useFormik({
		initialValues: { lobby_id: lobby_id },
		validate: values => {
			const errors = {};
			if (!values.lobby_id) {
				errors.lobby_id = 'Lobby ID is required';
			} else if (typeof values.lobby_id !== 'number' || !Number.isInteger(values.lobby_id)) {
				errors.lobby_id = 'Lobby ID must be an integer';
			}
			return errors;
		},
		onSubmit: async (values, { setSubmitting, setErrors }) => {
			try {
				const { lobby_id } = values;
				const formIdentifier = 'leave_lobby'
				const payloadSubmission = { formIdentifier, lobby_id };
				const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
				if (data) {
					setAckMsg(data);
				}
				if (error) {
					setErrMsg(error);
				}
			} catch (error) {
				setErrMsg(error);
			} finally {
				setSubmitting(false);  // Ensures form is re-enable after async operation
			}
		}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<input type="hidden" name="lobby_id" value={formik.values.lobby_id} onChange={formik.handleChange} />
			<button type="submit" className={ButtonClass} disabled={disabled}>
				{ButtonText}
			</button>
		</form>
	);
}