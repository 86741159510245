import React, { useEffect, useState, useContext } from 'react';
import jsVectorMap from 'jsvectormap';
import 'jsvectormap/dist/maps/world.js';
import 'jsvectormap/dist/jsvectormap.min.css';
import { Card, CardBody, CardExpandToggler } from './../../components/card/card.jsx';
import Chart from 'react-apexcharts';
import * as Chartjs from 'chart.js/auto';

import { AppSettings } from '../../config/app-settings.js';
import { getGenericData } from '../../api/generic-get.js';
import debounce from 'lodash.debounce';
import AlertTransition from '../../features/alert_success_transitions.js';
import PerfectScrollbar from 'react-perfect-scrollbar';


function Dashboard() {

	// Handling global settings
	const backend_endpoint = '/api/dashboard';
	// END - Handling global settings

	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
	const [errMsg, setErrMsg] = useState({});
	const [ackMsg, setAckMsg] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	// Handling template context
	const context = useContext(AppSettings);

	useEffect(() => {
		context.setAppContentClass('p-3');
		context.setAppContentFullHeight(true);

		return function cleanUp() {
			context.setAppContentClass('');
			context.setAppContentFullHeight(false);
		};

		// eslint-disable-next-line
	}, []);
	// END - Handling template context

	// Component Variables
	const [totalUsers, setTotalUsers] = useState(0);
	const [activeUsers, setActiveUsers] = useState(0);
	const [monthlyUsers, setMonthlyUsers] = useState([]);
	const [usersByCountry, setUsersByCountry] = useState([]);
	const [chartOptions, setChartOptions] = useState(getChartOptions());
	const [profileStatsData, setProfileStatsData] = useState([]);


	function getChartOptions() {
		const themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();

		const gray800Rgb = getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim();


		return {
			bar: {
				colors: [themeColor],
				chart: { sparkline: { enabled: true } },
				tooltip: { x: { show: true }, y: { title: { formatter: () => '' } } }
			},
			line: {
				colors: [themeColor],
				chart: { sparkline: { enabled: true } },
				stroke: { curve: 'straight', width: 2 },
				tooltip: { x: { show: true }, y: { title: { formatter: () => '' } } }
			}
		};
	}


	const getUserStatsData = async () => {
		try {
			const queryParams = new URLSearchParams({
				user_stats: true,
			});
			const url = `${backend_endpoint}?${queryParams.toString()}`;
			const { data, error } = await getGenericData(url);

			if (data && Object.keys(data).length > 0 && !data.message) {
				console.log(data)
				setTotalUsers(data.user_stats.total_users);
				setActiveUsers(data.user_stats.active_users);
				setMonthlyUsers(data.user_stats.monthly_user_registration);
				setUsersByCountry(data.user_stats.users_by_country);
			} else {
				setTotalUsers(0);
				setActiveUsers(0);
				setMonthlyUsers([]);
				setUsersByCountry([]);
			}

			if (error) {
				setErrMsg(error);
			}
		} catch (error) {
			setErrMsg(error);
		}
	};

	const getProfileStatsData = async () => {
		try {
			const queryParams = new URLSearchParams({
				profile_stats: true,
			});
			const url = `${backend_endpoint}?${queryParams.toString()}`;
			const { data, error } = await getGenericData(url);

			if (data && Object.keys(data).length > 0 && !data.message) {
				console.log(data)
				setProfileStatsData(data);
			} else {
				setProfileStatsData([]);
			}

			if (error) {
				setErrMsg(error);
			}
		} catch (error) {
			setErrMsg(error);
		}
	};

	useEffect(() => {
		// Fetch the data first
		getUserStatsData();
		getProfileStatsData();
	}, []); // Empty array ensures it only runs once when the component mounts


	// KD Stats Chart Initialization
	useEffect(() => {

		let lineChart; // Declare a variable to hold the chart instance

		function initializeKDStatsChart() {
			const ctx = document.getElementById('lineChart').getContext('2d');
			const themeColor = getComputedStyle(document.body).getPropertyValue('--bs-theme').trim();
			//const themeColorRgb = getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb').trim();
			const themeFont = getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim();
			const gray900 = getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim();

			const WZColor = getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim();
			const WZBorderColor = getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim();

			const RESColor = getComputedStyle(document.body).getPropertyValue('--bs-info').trim();
			const RESBorderColor = getComputedStyle(document.body).getPropertyValue('--bs-info').trim();

			const MWColor = getComputedStyle(document.body).getPropertyValue('--bs-primary').trim();
			const MWBorderColor = getComputedStyle(document.body).getPropertyValue('--bs-primary').trim();

			const RANKColor = getComputedStyle(document.body).getPropertyValue('--bs-danger').trim();
			const RANKBorderColor = getComputedStyle(document.body).getPropertyValue('--bs-danger').trim();


			// Extract data for each KD type from profileStatsData
			const labels = profileStatsData.map(item => new Date(item.timestamp).toLocaleDateString());
			const mwKD = profileStatsData.map(item => item.mw_kd);
			const brKD = profileStatsData.map(item => item.br_kd);
			const resKD = profileStatsData.map(item => item.res_kd);
			const rankKD = profileStatsData.map(item => item.rank_kd);


			// Destroy the existing chart instance if it exists
			if (lineChart) {
				lineChart.destroy();
			}

			// Configure global chart settings
			Chartjs.Chart.defaults.font.family = themeFont;
			Chartjs.Chart.defaults.color = 'rgba(255, 255, 255, .5)';
			Chartjs.Chart.defaults.plugins.legend.display = true;
			Chartjs.Chart.defaults.plugins.tooltip.padding = 8;
			Chartjs.Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(' + gray900 + ', .95)';
			Chartjs.Chart.defaults.plugins.tooltip.titleFont.family = themeFont;
			Chartjs.Chart.defaults.plugins.tooltip.titleFont.weight = 600;
			Chartjs.Chart.defaults.plugins.tooltip.footerFont.family = themeFont;
			Chartjs.Chart.defaults.scale.grid.color = 'rgba(255, 255, 255, .25)';
			Chartjs.Chart.defaults.scale.ticks.backdropColor = 'rgba(255, 255, 255, .2)';

			// Create the chart instance
			lineChart = new Chartjs.Chart(ctx, {
				type: 'line',
				data: {
					labels: labels,
					datasets: [
						{
							label: 'WZ KD', // Label for the second line
							color: themeColor,
							backgroundColor: WZColor,
							borderColor: WZBorderColor,
							borderWidth: 1.5,
							pointBackgroundColor: WZBorderColor,
							pointBorderWidth: 1.5,
							pointRadius: 4,
							pointHoverBackgroundColor: WZBorderColor,
							pointHoverBorderColor: WZBorderColor,
							pointHoverRadius: 7,
							data: brKD, // Sample data for the second line
						},
						{
							label: 'RES KD', // Label for the second line
							color: themeColor,
							backgroundColor: RESColor,
							borderColor: RESBorderColor,
							borderWidth: 1.5,
							pointBackgroundColor: RESBorderColor,
							pointBorderWidth: 1.5,
							pointRadius: 4,
							pointHoverBackgroundColor: RESBorderColor,
							pointHoverBorderColor: RESBorderColor,
							pointHoverRadius: 7,
							data: resKD, // Sample data for the second line
						},
						{
							label: 'MW KD', // Label for the second line
							color: themeColor,
							backgroundColor: MWColor,
							borderColor: MWBorderColor,
							borderWidth: 1.5,
							pointBackgroundColor: MWBorderColor,
							pointBorderWidth: 1.5,
							pointRadius: 4,
							pointHoverBackgroundColor: MWBorderColor,
							pointHoverBorderColor: MWBorderColor,
							pointHoverRadius: 7,
							data: mwKD, // Sample data for the second line
						},
						{
							label: 'RANK KD', // Label for the second line
							color: themeColor,
							backgroundColor: RANKColor,
							borderColor: RANKBorderColor,
							borderWidth: 1.5,
							pointBackgroundColor: RANKBorderColor,
							pointBorderWidth: 1.5,
							pointRadius: 4,
							pointHoverBackgroundColor: RANKBorderColor,
							pointHoverBorderColor: RANKBorderColor,
							pointHoverRadius: 7,
							data: rankKD, // Sample data for the second line
						},

					]
				},
					options: {
						responsive: true,
						plugins: {
							tooltip: {
								backgroundColor: `rgba(${gray900}, .95)`,
							}
						},
						scales: {
							x: {
								type: 'category',
								ticks: { autoSkip: true, maxTicksLimit: 10 }
							},
							y: {
								beginAtZero: true,
								ticks: { stepSize: 1 }  // Adjust based on range of KD values
							}
						}
					}
				}
			);

		}

		initializeKDStatsChart();

		// Re-render chart on theme reload
		document.addEventListener('theme-reload', initializeKDStatsChart);

		// Cleanup function to remove the event listener and destroy the chart
		return () => {
			if (lineChart) {
				lineChart.destroy();
			}
			document.removeEventListener('theme-reload', initializeKDStatsChart);
		};
	}, [profileStatsData]);


	useEffect(() => {
		const themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
		const themeFont = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();


		const initializeMap = () => {
			const usersByCountryData = usersByCountry.reduce((acc, country) => {
				acc[country.countryCode] = {
					players: country.count,
					active: country.active_count,  // Add active count here
				};
				return acc;
			}, {});


			// Initialize the world map with user data and tooltips
			const map = new jsVectorMap({
				selector: '#world-map',
				map: 'world',
				showTooltip: true,
				zoomButtons: true,
				normalizeFunction: 'polynomial',
				hoverOpacity: 0.5,
				hoverColor: false,
				zoomOnScroll: true,
				zoomMax: 4,  // Maximum zoom level
				zoomMin: 2,   // Minimum zoom level
				// setFocus: {region: "GB"},
				series: {
					regions: [{
						values: usersByCountryData,  // Attach user data to countries
						scale: ['#eeeeee'],  // Colors for different levels of data
						normalizeFunction: 'polynomial',
					}]
				},
				// labels: { markers: { render: (marker) => marker.name } },
				focusOn: {
					region: "GB",
				},
				// markerStyle: {
				// 	initial: { fill: themeColor, stroke: 'none', r: 5 },
				// 	hover: { fill: themeColor }
				// },
				// markerLabelStyle: {
				// 	initial: {
				// 		fontFamily: themeFont,
				// 		fontSize: '12px',
				// 		fill: 'rgba(255, 255, 255, 0.75)'
				// 	}
				// },
				onRegionTooltipShow: function (event, tooltip, code) {
					const countryData = usersByCountryData[code];
					if (countryData) {
						const tooltipContent = `${tooltip.text()}<br> PLAYERS: ${countryData.players}<br> ACTIVE: ${countryData.active}`;
						tooltip.text(tooltipContent, true);  // Set the tooltip text in one call
						tooltip.css({ backgroundColor: '#000', fontFamily: themeFont });
					} else {
						tooltip.text(`${tooltip.text()} <br> No data`, true);
						tooltip.css({ backgroundColor: '#000', fontFamily: themeFont });
					}
				},
				regionStyle: {
					initial: {
						fill: '#ffffff',
						fillOpacity: 0.35,
						fontFamily: themeFont,
						stroke: themeColor,
						strokeWidth: 0.4,
						strokeOpacity: 1
					},
					hover: {
						fillOpacity: 0.7,
						fill: themeColor,
					},
					selected: { fill: themeColor },
				},
				backgroundColor: 'transparent',
			});

			// Set zoom level

			window.vectorMap = map;


			// Resize map on window resize or orientation change
			const handleResizeOrOrientationChange = debounce(() => {
				if (window.vectorMap) {
					window.vectorMap.updateSize(); // Resize the map
					window.vectorMap.setFocus({ region: "GB", animate: true });
				}
			}, 300); // Adjust the debounce delay as needed

			// Add event listeners for both resize and orientation change
			window.addEventListener('resize', handleResizeOrOrientationChange);
			window.addEventListener('orientationchange', handleResizeOrOrientationChange);


			// Update chart options on theme reload
			document.addEventListener('theme-reload', () => {
				setChartOptions(getChartOptions());
			});

			// Cleanup listener on unmount
			return () => {
				window.removeEventListener('resize', handleResizeOrOrientationChange);
				window.removeEventListener('orientationchange', handleResizeOrOrientationChange);
				handleResizeOrOrientationChange.cancel(); // Cancel any pending debounced calls


				document.removeEventListener('theme-reload', () => { });
				if (map) {
					map.destroy();
				}
			};
		};

		// Introduce a delay to allow data loading
		const timeout = setTimeout(() => {
			if (usersByCountry.length > 0) {
				initializeMap();
			}
		}, 500);  // Delay of 500ms (adjust as necessary)

		return () => clearTimeout(timeout);  // Cleanup the timeout on unmount
	}, [usersByCountry]);  // Re-run this effect when `usersByCountry` changes


	const handleCountryHover = (countryCode) => {
		const map = window.vectorMap;  // Reference to the jsVectorMap instance
		if (map && countryCode) {
			// Set focus to the hovered country
			map.setFocus({ region: countryCode, animate: true });
			map.setSelectedRegions(countryCode);
		}
	};

	const handleCountryLeave = () => {
		const map = window.vectorMap;
		if (map) {
			map.clearSelectedRegions();
			map.setFocus({ scale: 1, x: 0.1, y: 0.1, focusOn: { x: 0.5, y: 0.5, scale: 1 } });
		}
	};

	// Compute the cumulative total for the line chart
	const computeCumulativeTotal = () => {
		let cumulativeTotal = totalUsers;
		return monthlyUsers.map((month) => {
			cumulativeTotal -= month.count;
			return cumulativeTotal;
		}).reverse();  // Reverse to show decreasing total as we go backward
	};

	// For the bar chart, we reverse the monthlyUsers to show the most recent month on the right
	const getMonthlyUsersData = () => {
		return monthlyUsers.map(m => m.count).reverse();  // Reverse to show the most recent month on the right
	};

	// Function for Bar Chart
	const getMonthsLabelsForBarChart = () => {
		return monthlyUsers.map(m => m.month).reverse();  // Reverse to match the data order
	};

	// Function for Line Chart (adds "Current" label for the totalUsers)
	const getMonthsLabelsForLineChart = () => {
		const labels = monthlyUsers.map(m => m.month).reverse();  // Reverse to match the data order
		labels.push('Current');  // Add a label for the current total
		return labels;
	};

	const handleMenuToggle = () => {
		// Use a timeout to allow the menu animation to complete
		setTimeout(() => {
			if (window.vectorMap) {
				window.vectorMap.updateSize();  // Resize the map
				window.vectorMap.setFocus({ region: "GB", animate: true });
			}
		}, 500); // Adjust this delay as needed based on your menu animation
	};

	useEffect(() => {
		// Find the menu toggle button
		const menuToggleButton = document.querySelector('.menu-toggler');

		// Add the click event listener if the button exists
		if (menuToggleButton) {
			menuToggleButton.addEventListener('click', handleMenuToggle);
		}

		// Cleanup the event listener on unmount
		return () => {
			if (menuToggleButton) {
				menuToggleButton.removeEventListener('click', handleMenuToggle);
			}
		};
	}, []);


	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
	const combinedSuccess = { ...ackMsg };
	const combinedErrors = { ...errMsg };

	// Function to handle closing the alert
	const handleClose = () => {
		setShowAlert(false);
		setErrMsg({});
	};
	const handleSuccessClose = () => {
		setShowSuccess(false);
		setAckMsg({});
	};

	// Debounce the function to avoid multiple updates
	const debouncedHandleClose = debounce(handleClose, 500);
	const debouncedHandleSuccessClose = debounce(handleSuccessClose, 500);

	useEffect(() => {
		let timer;
		// Handle error messages
		if (Object.keys(combinedErrors).length > 0) {
			setShowAlert(true);
			timer = setTimeout(() => {
				debouncedHandleClose();
			}, 5000);
		} else {
			setShowAlert(false);
		}

		// Handle success messages
		if (Object.keys(combinedSuccess).length > 0) {
			setShowSuccess(true);
			timer = setTimeout(() => {
				debouncedHandleSuccessClose();
			}, 5000);
		} else {
			setShowSuccess(false);
		}

		// Clean up function for both alerts
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
			debouncedHandleClose.cancel();
			debouncedHandleSuccessClose.cancel();
		};
	}, [combinedErrors, combinedSuccess]); // Depend on both state changes	
	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	return (
		<PerfectScrollbar className="h-100 p-3">
			<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
			<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
			<div>
				<div className="row">
					{/* Total Users */}
					<div className="col-xl-3 col-lg-6">
						<Card className="mb-3">
							<CardBody>
								<div className="d-flex fw-bold small mb-3">
									<span className="flex-grow-1">PLAYERS TOTAL</span>
									<CardExpandToggler />
								</div>
								<div className="row align-items-center mb-2">
									<div className="col-7">
										<h3 className="mb-0">{totalUsers}</h3>
									</div>
									<div className="col-5">
										<div className="mt-n2">
											<Chart type="line"
												height={30}
												options={{
													...chartOptions.line,
													xaxis: {
														categories: getMonthsLabelsForLineChart(),  // Set the months as x-axis labels
													},
												}}
												series={[{ data: [...computeCumulativeTotal(), totalUsers] }]}  // Line chart with decreasing totals
											/>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>

					{/* Online Users */}
					<div className="col-xl-3 col-lg-6">
						<Card className="mb-3">
							<CardBody>
								<div className="d-flex fw-bold small mb-3">
									<span className="flex-grow-1">ONLINE</span>
									<CardExpandToggler />
								</div>
								<div className="row align-items-center mb-2">
									<div className="col-7">
										<h3 className="mb-0">{activeUsers}</h3>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>

					{/* Monthly Registered Users Line Chart */}
					<div className="col-xl-3 col-lg-6">
						<Card className="mb-3">
							<CardBody>
								<div className="d-flex fw-bold small mb-3">
									<span className="flex-grow-1">RECENTLY REGISTERED</span>
									<CardExpandToggler />
								</div>
								<div className="row align-items-center mb-2">
									<div className="col-7">
										<h3 className="mb-0">MONTHLY</h3>
									</div>
									<div className="col-5">
										<div className="mt-n2">
											<Chart
												type="bar"
												height={30}
												options={{
													...chartOptions.bar,
													xaxis: {
														categories: getMonthsLabelsForBarChart(),  // Set the months as x-axis labels
													},
												}}
												series={[
													{
														name: 'Registered Users',
														data: getMonthlyUsersData(),  // Reverse chronological order
													},
												]}
											/>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>

					{/* Users by Country Table */}
					<div className="col-xl-6">
						<Card className="mb-3">
							<CardBody>
								<div className="d-flex fw-bold small mb-3">
									<span className="flex-grow-1">PLAYERS BY COUNTRY</span>
									<CardExpandToggler />
								</div>
								<div className="ratio ratio-21x9 mb-3">
									<div id="world-map" className="jvectormap-without-padding"></div>
								</div>
								<div className="row gx-4">
									<div className="col-lg-6 mb-3 mb-lg-0">
										<table className="w-100 small mb-0 text-truncate text-white text-opacity-60">
											<thead>
												<tr className="text-white text-opacity-75">
													<th className="w-50">COUNTRY</th>
													<th className="w-25 text-end">PLAYERS</th>
													<th className="w-25 text-end">ACTIVE</th>
												</tr>
											</thead>
											<tbody>
												{usersByCountry.length > 0 ? (
													usersByCountry.map((country, index) => (
														<tr key={index}
															onMouseEnter={() => handleCountryHover(country.countryCode)}
															onMouseLeave={() => handleCountryLeave()}>
															<td>{country.country || "Unknown"}</td>
															<td className="text-end">{country.count}</td>
															<td className={`text-end ${country.active_count > 0 ? 'text-theme fw-bold' : ''}`}>
																{country.active_count}
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="3">No records found</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>


					{/* Players KD Stats*/}
					<div className="col-xl-6">
						<Card className="mb-3">
							<CardBody>
								<div className="d-flex fw-bold small mb-3">
									<span className="flex-grow-1">KD Stats Tracker</span>
									<CardExpandToggler />
								</div>
								<div className="ratio ratio-21x9 mb-3">
									<div className="col-lg-6">
										{/* Line Chart for KD Tracking */}
										<canvas id="lineChart"></canvas>  {/* This canvas displays chart1 */}
									</div>								
								</div>
							</CardBody>
						</Card>
					</div>

				</div>
			</div>
		</PerfectScrollbar>

	);
}

export default Dashboard;