import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import axiosInstance from '../api/external-api.service';

const useSocketConnection = (namespace, socket_update, updateData) => {
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);

    useEffect(() => {
        const socket = io(`${axiosInstance.defaults.baseURL}/${namespace}`, {
            path: '/socket.io',
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 2000,
            reconnectionDelayMax: 3000,
            randomizationFactor: 0.5,
            timeout: 15000,
            withCredentials: false
        });

        const handleReconnectAttempt = () => {
            const maxDelay = 30000;
            const minDelay = 2000;
            const delay = Math.min(minDelay * (2 ** reconnectAttempts), maxDelay);
            setTimeout(() => {
                if (socket && !socket.connected) {
                    socket.connect();
                }
            }, delay);
            setReconnectAttempts(attempts => attempts + 1);
        };

        const handleConnect = () => {
            console.log('Successfully connected with sid:', socket.id);
            setIsSocketConnected(true);
            setReconnectAttempts(0);
        };

        const handleDisconnect = reason => {
            console.log('Disconnected:', reason);
            setIsSocketConnected(false);
            handleReconnectAttempt();
        };

        const handleReconnection = attemptNumber => {
            console.log(`Reconnected to the server on attempt ${attemptNumber}`);
            setIsSocketConnected(true);
            updateData();
        };

        socket.on('connect', handleConnect);
        socket.on(socket_update, updateData);
        socket.on('disconnect', handleDisconnect);
        socket.on('reconnect', handleReconnection);
        socket.on('reconnect_attempt', handleReconnectAttempt);
        socket.on('reconnect_error', error => console.log('Reconnection failed due to error:', error));
        socket.on('reconnect_failed', () => console.log('Failed to reconnect to the server after all attempts'));
        socket.on('error', error => console.log('Error:', error));

        return () => {
            socket.off('connect', handleConnect);
            socket.off(socket_update, updateData);
            socket.off('disconnect', handleDisconnect);
            socket.off('reconnect', handleReconnection);
            socket.off('reconnect_attempt');
            socket.off('reconnect_error');
            socket.off('reconnect_failed');
            socket.off('error');

            socket.close();
        };
    }, []);

    return { isSocketConnected };
};

export default useSocketConnection;