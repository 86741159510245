// The below is useful for state that is kept on single page, and on per reload state us refreshed by call to
// database, if database call does not exist to set state data, then redux-persist is preferred, or 
// storing state in localStorage for later use on that page
// redux state is not persistent on page refresh

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import authSlice from './features/auth/authSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // You can choose which reducers to persist. Here, 'auth' is persisted.
};

const rootReducer = combineReducers({
  auth: authSlice,
  // other reducers can go here
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // middleware and other store enhancers can go here
});

export const persistor = persistStore(store);
