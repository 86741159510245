import axiosInstance, { callExternalApi } from "./external-api.service";

export const getGenericData = async (endpoint) => {
  const config = {
    url: endpoint,
    mode: 'cors',
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, X-CSRF-Token'
    },
    // withCredentials: true
  };

  const { data, error } = await callExternalApi(config);

  return {
    data: data || null,
    error,
  };
};
