import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';
import { markNotificationAsRead } from '../../notifications/components/utility_mark_as_read_notification.js';

export function JoinLobbyFromNotifyForm({lobby_id, notification_id, backend_endpoint, ButtonClass, ButtonText, navigate}) {
    const formik = useFormik({
        initialValues: { lobby_id: lobby_id },
        validate: values => {
            const errors = {};
            if (!values.lobby_id) {
                errors.lobby_id = 'Lobby ID is required';
            } else if (!Number.isInteger(+values.lobby_id)) {  // The '+' converts string to number
                errors.lobby_id = 'Lobby ID must be an integer';
            }
            return errors;
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const { lobby_id } = values;
                const formIdentifier = 'join_lobby';
                const payloadSubmission = { formIdentifier, lobby_id };
                const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
                
                if (data) {
                    await markNotificationAsRead(notification_id);
                    navigate('/lobby'); // Navigate to the lobby route on success
                }
                if (error) {
                    await markNotificationAsRead(notification_id);
                    console.error('Error:', error);
                }
            } catch (error) {
                console.error('Submission Error:', error);
            } finally {
                setSubmitting(false);  // Ensures form is re-enabled after async operation
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <input type="hidden" name="lobby_id" value={formik.values.lobby_id} onChange={formik.handleChange} />
            <button type="submit" className={ButtonClass} disabled={formik.isSubmitting}>
                {ButtonText}
            </button>
        </form>
    );
}