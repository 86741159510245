import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';

export function NotificationForm({ setNotificationAsRead, notification_id, notifications = [], backend_endpoint, ButtonClass }) {
	notifications = notifications || [];
	const formik = useFormik({
		initialValues: { notification_id: notification_id },
		validate: values => {
			const errors = {};
			if (!values.notification_id) {
				errors.notification_id = 'notification ID is required';
			} else if (typeof values.notification_id !== 'number' || !Number.isInteger(values.notification_id)) {
				errors.notification_id = 'notification ID must be an integer';
			} else if (!notifications || !notifications.some(notification => notification.id === values.notification_id)) {
				errors.notification_id = 'Invalid notification ID';
			}
			return errors;
		},
		onSubmit: async (values, { setSubmitting, setErrors }) => {
			try {
				const { notification_id } = values;
				const formIdentifier = 'read_notification'
				const payloadSubmission = { formIdentifier, notification_id };
				const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
				if (data) {
					setNotificationAsRead(notification_id);
				}
				if (error) {
					setErrors(error);
				}
			} catch (error) {
				setErrors({ submit: 'An error occurred during submission.' });
			} finally {
				setSubmitting(false);  // Ensures form is re-enable after async operation
			}
		}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<input type="hidden" name="notification_id" value={formik.values.notification_id} onChange={formik.handleChange} />
			<button type="submit" className={ButtonClass}>
				<i className="bi bi-check"></i>
			</button>
		</form>
	);
}