import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';
import { markNotificationAsRead } from '../../notifications/components/utility_mark_as_read_notification.js';

export function JoinLobbyFromNotifyNavForm({ lobby_id, notification_id, backend_endpoint, ButtonClass, ButtonText, navigate }) {
    const formik = useFormik({
        initialValues: { lobby_id: lobby_id },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const { lobby_id } = values;
                const formIdentifier = 'join_lobby';
                const payloadSubmission = { formIdentifier, lobby_id };
                const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);

                if (data) {
                    await markNotificationAsRead(notification_id);
                    navigate('/lobby'); // Use the passed navigate function
                }
                if (error) {
                    await markNotificationAsRead(notification_id);
                    console.error('Error:', error);
                }
            } catch (error) {
                console.error('Submission Error:', error);
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <input type="hidden" name="lobby_id" value={formik.values.lobby_id} onChange={formik.handleChange} />
            <button type="submit" className={ButtonClass} disabled={formik.isSubmitting}>
                {ButtonText}
            </button>
        </form>
    );
}