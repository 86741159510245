import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';

export function DeleteRendezvousForm({ rendezvous_id, backend_endpoint, getMyRendezvousData, setAckMsg, setErrMsg, ButtonClass, ButtonText }) {
    const formik = useFormik({
        initialValues: { rendezvous_id: rendezvous_id },
        validate: values => {
            const errors = {};
            if (!values.rendezvous_id) {
                errors.rendezvous_id = 'Rendezvous ID is required';
            } else if (typeof values.rendezvous_id !== 'number' || !Number.isInteger(values.rendezvous_id)) {
                errors.rendezvous_id = 'Rendezvous ID must be an integer';
            }
            return errors;
        },
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const { rendezvous_id } = values;
                const formIdentifier = 'delete_rendezvous'
                const payloadSubmission = { formIdentifier, rendezvous_id };
                const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
                if (data) {
                    setAckMsg(data);
                    await getMyRendezvousData();
                }
                if (error) {
                    setErrMsg(error);
                }
            } catch (error) {
                setErrMsg(error);
            } finally {
                setSubmitting(false);  // Ensures form is re-enable after async operation
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <input type="hidden" name="rendezvous_id" value={formik.values.rendezvous_id} onChange={formik.handleChange} />
            <button type="submit" className={ButtonClass}>
                {ButtonText}
            </button>
        </form>
    );
}