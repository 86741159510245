import React from 'react';

function Footer() {
	return (
		<div id="footer" className="app-footer">
			&copy; Villain_0x1034: 2022 HEXTRIM LTD All Right Reserved
		</div>
	);
}

export default Footer;