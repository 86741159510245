const Menu = [
	{ is_header: true, title: 'Navigation' },
	{ path: '/dashboard', icon: 'bi bi-activity', title: 'Dashboard', roles: ['admins', 'moderators', 'users'] },
	{ path: '/profile', icon: 'fa-regular fa-id-card', title: 'Profile', roles: ['admins', 'moderators', 'users'] },
	{ path: '/tournaments', icon: 'fa-solid fa-trophy', title: 'Tournaments', roles: ['admins', 'moderators'] },
	{ path: '/schedule', icon: 'bi bi-calendar2-range', title: 'Schedule', roles: ['admins', 'moderators'] },
	{ path: '/map', icon: 'bi bi-globe-americas', title: 'Maps', roles: ['admins', 'moderators'] },
	{ path: '/loadouts', icon: 'bi bi-box-fill', title: 'Loadouts', roles: ['admins', 'moderators'] },
	{ path: '/players', icon: 'fa-solid fa-people-group', title: 'Players Leaderboard', roles: ['admins', 'moderators', 'users'] },
	{ path: '/rendezvous', icon: 'bi bi-calendar2-event', title: 'Rendezvous', roles: ['admins', 'moderators', 'users'] },
	{ path: '/lobby', icon: 'fa-solid fa-users-viewfinder', title: 'Lobby', roles: ['admins', 'moderators', 'users'] },
	{ path: '/recent-players', icon: 'fa-solid fa-clock-rotate-left', title: 'Recent Players', roles: ['admins', 'moderators', 'users'] },
	{ path: '/fireteam', icon: 'fa-solid fa-person-rifle', title: 'Fireteam', roles: ['admins', 'moderators', 'users'] },
	{ path: '/banned-players', icon: 'fa-solid fa-user-injured', title: 'Banned', roles: ['admins', 'moderators', 'users'] },
	{ path: '/notifications', icon: 'fa-solid fa-bell', title: 'Notifications', roles: ['admins', 'moderators', 'users'] },
	{ path: '/admin', icon: 'bi bi-house-gear', title: 'Admin', roles: ['admins'] },
	{
		path: '/management', icon: 'bi bi-building-gear', title: 'Management', roles: ['admins', 'moderators'],
		children: [
			{ path: '/management/tnts/battleroyale', title: 'Tournaments Battle Royale' },
			{ path: '/management/tnts/resurgence', title: 'Tournaments Resurgence' }
		]
	}
];

export default Menu;