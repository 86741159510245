import React, { useEffect, useState, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardImgOverlay, CardBody } from '../../components/card/card.jsx';
import { AppSettings } from '../../config/app-settings.js';

import { getGenericData } from '../../api/generic-get.js';
import UserImage from '../../features/images/userImage.js';
import debounce from 'lodash.debounce';
import { SendFriendRequestForm } from '../friendship/hooks/send_friend_request_form.js';
import { BanPlayerForm } from '../banned-players/hooks/ban_player_form.js';
import { KickFromLobbyForm } from '../lobby/hooks/kick_from_lobby_form.js';
import AlertTransition from '../../features/alert_success_transitions.js';
import { SendInviteLinkForm } from '../lobby/hooks/send_invite_link_form.js';
import { Link } from 'react-router-dom';

function RecentPlayers() {

	// Handling global settings
	const backend_endpoint = '/api/recent-players';
	const backend_endpoint_friendship = '/api/friendship';
	const backend_endpoint_banned_players = '/api/banned-players';
	const backend_endpoint_lobby = '/api/lobby';
	// END - Handling global settings

	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
	const [errMsg, setErrMsg] = useState({});
	const [ackMsg, setAckMsg] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	// Handling template context
	const context = useContext(AppSettings);

	useEffect(() => {
		context.setAppContentClass('p-3');
		context.setAppContentFullHeight(true);

		return function cleanUp() {
			context.setAppContentClass('');
			context.setAppContentFullHeight(false);
		};

		// eslint-disable-next-line
	}, []);
	// END - Handling template context

	// GET 
	// GET - Handling fetching recentPlayers
	const [recentPlayers, setRecentPlayers] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	const filteredPlayers = recentPlayers.filter(player =>
		!searchTerm || (player.username || "").toLowerCase().includes(searchTerm.toLowerCase())
	);

	// useEffect(() => {
	// 	let isMounted = true;

	const getRecentPlayersData = async () => {
		try {
			const queryParams = new URLSearchParams({
				recent_players: true,
			});
			const url = `${backend_endpoint}?${queryParams.toString()}`;
			const { data, error } = await getGenericData(url);

			// if (!isMounted) {
			// 	return;
			// }

			// if (data) {
			if (data && Object.keys(data).length > 0 && !data.message) {
				setRecentPlayers(data.recent_players || []);
			} else {
				setRecentPlayers([]);
			}

			if (error) {
				setErrMsg(error);
			}
		} catch (error) {
			setErrMsg(error);
		}
	};

	// 	getRecentPlayersData();

	// 	return () => {
	// 		isMounted = false;
	// 	};
	// }, []);

	useEffect(() => {
		getRecentPlayersData();
	}, []);

	// END - GET - Handling fetching recentPlayers
	// END - GET

	// Internal Data Management
	const [selectedPlayer, setSelectedPlayer] = useState(null);

	const handlePlayerClick = (player) => {
		setSelectedPlayer(player);
	};
	// END - Internal Data Management

	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
	const combinedSuccess = { ...ackMsg };
	const combinedErrors = { ...errMsg };

	// Function to handle closing the alert
	const handleClose = () => {
		setShowAlert(false);
		setErrMsg({});
	};
	const handleSuccessClose = () => {
		setShowSuccess(false);
		setAckMsg({});
	};

	// Debounce the function to avoid multiple updates
	const debouncedHandleClose = debounce(handleClose, 500);
	const debouncedHandleSuccessClose = debounce(handleSuccessClose, 500);

	useEffect(() => {
		let timer;
		// Handle error messages
		if (Object.keys(combinedErrors).length > 0) {
			setShowAlert(true);
			timer = setTimeout(() => {
				debouncedHandleClose();
			}, 5000);
		} else {
			setShowAlert(false);
		}

		// Handle success messages
		if (Object.keys(combinedSuccess).length > 0) {
			setShowSuccess(true);
			timer = setTimeout(() => {
				debouncedHandleSuccessClose();
			}, 5000);
		} else {
			setShowSuccess(false);
		}

		// Clean up function for both alerts
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
			debouncedHandleClose.cancel();
			debouncedHandleSuccessClose.cancel();
		};
	}, [combinedErrors, combinedSuccess]); // Depend on both state changes	
	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	return (
		<div className="h-100">
			<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
			<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
			<Card className="h-100">
				<div className="recent-players">
					<div className="recent-players-sidebar">
						<div className="recent-players-sidebar-header">
							<h3 className="mb-10px">Recent Players</h3>
							<div className="position-relative">
								<button type="submit" className="btn position-absolute top-0"><i className="bi bi-search"></i></button>
								<input type="text" className="form-control rounded-pill ps-35px" placeholder="Search Players" onChange={e => setSearchTerm(e.target.value)} />
							</div>
						</div>
						<div className="recent-players-sidebar-body">
							<PerfectScrollbar className="h-100">
								{filteredPlayers.length > 0 ? filteredPlayers.map((player, index) => (
									<div key={player.id} className="recent-players-item" onClick={() => handlePlayerClick(player)}>
										<div data-toggle="recent-players-content" className="recent-players-link">
											<div className="recent-players-media">
												<Link to={`/profile/${player.id}`}>
													<UserImage imageType={player.profile_pic} altText="" className="ms-100 mh-100 rounded-circle" />
												</Link>
											</div>
											<div className="recent-players-info">
												<Link to={`/profile/${player.id}`} className='text-white text-decoration-none'>
													<div className="recent-players-name">
														{player.username || "Unset"} {player.has_active_session === true ? <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> : <i className="fa fa-circle fs-8px fa-fw text-danger me-1"></i>}
														{player.was_last_seen === 0 ? '0 minutes ago' : player.was_last_seen ? `${player.was_last_seen} minutes ago` : 'inactive'}
													</div>
												</Link>
												<div className="recent-players-text">{player.date}</div>
											</div>
											<div className="dropdown">
												<a href="#/" data-bs-toggle="dropdown" className="text-white text-opacity-50"><i className="fa fa-ellipsis-h"></i></a>
												<div className="dropdown-menu dropdown-menu-end">
													<SendFriendRequestForm
														user_id={player.id}
														users={recentPlayers}
														backend_endpoint={backend_endpoint_friendship}
														setAckMsg={setAckMsg}
														setErrMsg={setErrMsg}
														ButtonClass="dropdown-item"
														ButtonText="ADD FRIEND" />
													<SendInviteLinkForm
														user_id={player.id}
														users={recentPlayers}
														backend_endpoint={backend_endpoint_lobby}
														setAckMsg={setAckMsg}
														setErrMsg={setErrMsg}
														ButtonClass="dropdown-item"
														ButtonText="INVITE TO LOBBY" />
													<KickFromLobbyForm
														user_id={player.id}
														users={recentPlayers}
														backend_endpoint={backend_endpoint_lobby}
														setAckMsg={setAckMsg}
														setErrMsg={setErrMsg}
														ButtonClass="dropdown-item"
														ButtonText="KICK" />
													<BanPlayerForm
														onBanSuccess={getRecentPlayersData}
														user_id={player.id}
														users={recentPlayers}
														backend_endpoint={backend_endpoint_banned_players}
														setAckMsg={setAckMsg}
														setErrMsg={setErrMsg}
														ButtonClass="dropdown-item"
														ButtonText="BAN" />
												</div>
											</div>
										</div>
									</div>
								)) : (
									<div className="recent-players-item">
										<div data-toggle="recent-players-content" className="recent-players-link">
											<div className="recent-players-info">
												<div className="recent-players-name">No recent players found</div>
											</div>
										</div>
									</div>
								)
								}
							</PerfectScrollbar>
						</div>
					</div>
					<div className="recent-players-content">
						<div className="recent-players-content-header">
							<div className="recent-players-content-header-media">
								<div className="media text-white text-opacity-75 fs-24px fw-bold">
									<i className="bi bi-person-square"></i>
								</div>
							</div>
							<div className="recent-players-content-header-info">
								PLAYER DETAILS
							</div>
						</div>
						<div className="recent-players-content-body">
							<PerfectScrollbar className="h-100">
								{selectedPlayer ? (
									<div className="player-details">
										<Card>
											<div className="m-1 bg-white bg-opacity-15">
												<div className="position-relative overflow-hidden" style={{ height: '165px' }}>
													{selectedPlayer.stats_pic ?
														<UserImage imageType={selectedPlayer.stats_pic} altText="" className="card-img rounded-0" />
														:
														<UserImage imageType={selectedPlayer.profile_pic} altText="" className="card-img rounded-0" />
													}
													<CardImgOverlay className="text-white text-center bg-dark-transparent-5">
														<div className="mb-2">
															<UserImage imageType={selectedPlayer.profile_pic} altText="" width="80px" className="rounded-circle" />
														</div>
														<div>
															<div className="fw-bold">{selectedPlayer.username}</div>
															<div className="fs-12px">{selectedPlayer.usernmae || "No bio available"}</div>
														</div>
													</CardImgOverlay>
												</div>
												<CardBody className="py-2 px-3">
													<div className="row text-center">
														<div className="col-4">
															<div className="fw-bold">{selectedPlayer.username}</div>
															<div className="fs-12px">posts</div>
														</div>
														<div className="col-4">
															<div className="fw-bold">{selectedPlayer.username}</div>
															<div className="fs-12px">followers</div>
														</div>
														<div className="col-4">
															<div className="fw-bold">{selectedPlayer.username}</div>
															<div className="fs-12px">following</div>
														</div>
													</div>
												</CardBody>
											</div>
										</Card>
									</div>
								) : (
									<div className="player-details">
										<Card>
											<div className="m-1 bg-white bg-opacity-15">
												<div className="position-relative overflow-hidden" style={{ height: '75px' }}>
													<CardImgOverlay className="text-white text-center bg-dark-transparent-5">
														<div className="mb-2">
														</div>
														<div>
															<div className="fw-bold">Select a player to see more details</div>
														</div>
													</CardImgOverlay>
												</div>
											</div>
										</Card>
									</div>)}
							</PerfectScrollbar>
						</div>

						<div className="recent-players-content-footer">

						</div>
					</div>
				</div>
			</Card>
		</div>
	)
}

export default RecentPlayers;
