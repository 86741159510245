import { postGenericData } from "../../../api/generic-post";
export async function markNotificationAsRead(notification_id) {
    const payload = {
        formIdentifier: 'read_notification',
        notification_id: notification_id
    };
    try {
        const response = await postGenericData('/api/notifications', payload);
    } catch (error) {
        console.error('Error marking notification as read:', error);
    }
}