import React, { useEffect, useContext, useState, useRef, useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AppSettings } from '../../config/app-settings.js';
import { getGenericData } from '../../api/generic-get.js';
import { postGenericData } from '../../api/generic-post.js';
import debounce from 'lodash.debounce';
import AlertTransition from '../../features/alert_success_transitions.js';
import { Card, CardHeader } from '../../components/card/card.jsx';
import { Tooltip } from "react-tooltip";
import { useFormik } from 'formik';
import Moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Select from 'react-select';
import { DeleteRendezvousForm } from './hooks/delete_rendezvous.js';
import { InterestRendezvousForm } from './hooks/interest_rendezvous.js';
import UserImage from '../../features/images/userImage.js';
import useSocketConnection from '../../features/use_socket_connection.js';
import { Link } from 'react-router-dom';

function Rendezvous() {

	// Handling global settings
	const { current_user_id } = useSelector(state => ({
		current_user_id: state.auth.id,
	}), shallowEqual);

	// Handling global settings
	const backend_endpoint = '/api/rendezvous';
	// END - Handling global settings

	// Handling template context
	const context = useContext(AppSettings);

	useEffect(() => {
		context.setAppContentClass('p-1');
		context.setAppContentFullHeight(true);

		return function cleanUp() {
			context.setAppContentClass('');
			context.setAppContentFullHeight(false);
		};

		// eslint-disable-next-line
	}, []);
	// END - Handling template context

	// GET
	// GET - RENDEZVOUS
	const [myRendezvous, setMyRendezvous] = useState([]);
	const [otherRendezvous, setOtherRendezvous] = useState([]);

	const [activeModeFilter, setActiveModeFilter] = useState(null);

	const filteredAndSortedRendezvous = useMemo(() => {
		let filteredRendezvous = activeModeFilter
			? otherRendezvous.filter(r => r.mode === activeModeFilter)
			: otherRendezvous;

		return filteredRendezvous.map(r => ({
			...r,
			isInterested: r.interested_users.some(user => user.id === current_user_id)
		})).sort((a, b) => b.isInterested - a.isInterested); // This puts items where the user is interested first
	}, [otherRendezvous, current_user_id, activeModeFilter]);

	const getModeClass = (mode) => {
		if (/RANKMW/.test(mode)) return ['success', 'Ranked Multiplayer'];
		if (/MW/.test(mode)) return ['primary', 'Multiplayer'];
		if (/BR/.test(mode)) return ['indigo', 'Battle Royale'];
		if (/RES/.test(mode)) return ['warning', 'Resurgence'];
		if (/RANKWZ/.test(mode)) return ['danger', 'Ranked Resurgence'];
		if (/DMZ/.test(mode)) return ['yellow', 'DMZ'];
		if (/ZOMBIES/.test(mode)) return ['gray-300', 'ZOMBIES'];
		return 'text-default';
	};

	const getMyRendezvousData = async () => {
		try {
			const queryParams = new URLSearchParams({
				get_my_rendezvous: true,
			});
			const url = `${backend_endpoint}?${queryParams.toString()}`;
			const { data, error } = await getGenericData(url);

			if (data && Object.keys(data).length > 0 && !data.message) {
				setMyRendezvous(data.my_rendezvous || []);
			} else {
				setMyRendezvous([]);
			}

			if (error) {
				setErrMsg(error);
			}
		} catch (error) {
			setErrMsg(error);
		}
	};

	const getOtherRendezvousData = async () => {
		try {
			const queryParams = new URLSearchParams({
				get_other_rendezvous: true,
			});
			const url = `${backend_endpoint}?${queryParams.toString()}`;
			const { data, error } = await getGenericData(url);

			if (data && Object.keys(data).length > 0 && !data.message) {
				setOtherRendezvous(data.other_rendezvous || []);
			} else {
				setOtherRendezvous([]);
			}

			if (error) {
				setErrMsg(error);
			}
		} catch (error) {
			setErrMsg(error);
		}
	};

	useEffect(() => {
		getMyRendezvousData();
		getOtherRendezvousData();
	}, []);

	// END - GET RENDEZVOUS
	// END - GET

	// SOCKET HANDLE
	const { isSocketConnected } = useSocketConnection(
		'rendezvous',
		'rendezvousupdate',
		() => {
			getMyRendezvousData();
			getOtherRendezvousData();
		}
	);
	// END - SOCKETHANDLE

	// POST
	// POST - ADD Rendezvous
	const [isEditMode, setIsEditMode] = useState(false);
	const [currentRendezvous, setCurrentRendezvous] = useState(null);
	const modalRef = useRef(null);

	const selectOptions = [
		{ value: 'MW', label: 'Multiplayer' },
		{ value: 'BR', label: 'Battle Royale' },
		{ value: 'RES', label: 'Resurgence' },
		{ value: 'RANKWZ', label: 'Ranked Resurgence' },
		{ value: 'RANKMW', label: 'Ranked Multiplayer' },
		{ value: 'DMZ', label: 'DMZ' },
		{ value: 'ZOMBIES', label: 'ZOMBIES' }
	];

	const [tooltipContent, setTooltipContent] = useState({
		title: '',
		mode: '',
		description: '',
		dateRange: '',
	});

	const [dateRange, setDateRange] = useState('Select Duration');

	const form_add_rendezvous = useFormik({
		initialValues: {
			title: '',
			mode: '',
			description: '',
			start_date: '',
			end_date: '',
			dateRange: ''
		},
		validate: values => {
			const errors = {};
			if (!values.title) {
				errors.title = 'Title is required';
			} else if (values.title.length < 12) {
				errors.title = 'Title must be at least 12 characters long';
			}
			if (!values.mode) {
				errors.mode = 'Mode is required';
			}
			if (!values.description) {
				errors.description = 'Description is required';
			} else if (values.description.length < 30 || values.description.length > 250) {
				errors.description = 'Description must be between 30 and 250 characters long';
			}
			if (!values.start_date || !values.end_date) {
				errors.dateRange = 'Date range is required';
			}
			return errors;
		},
		onSubmit: async (values, { setSubmitting, setErrors }) => {
			try {
				console.log("Submitting", values);
				const formIdentifier = isEditMode ? 'edit_rendezvous' : 'add_rendezvous';
				const { title, mode, description, start_date, end_date } = values;
				const payloadSubmission = { formIdentifier, title, mode, description, start_date, end_date };

				if (isEditMode && currentRendezvous) {
					payloadSubmission.rendezvous_id = currentRendezvous.id; // Include ID for editing
				}

				const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
				if (data) {
					setAckMsg(data);
					setShowSuccess(true);

					if (modalInstance) {
						modalInstance.hide();
					}
					getMyRendezvousData(); // Refresh data after submission
					setIsEditMode(false);
					setCurrentRendezvous(null);
					setDateRange('Select Duration');

				}
				if (error) {
					if (typeof error === 'object') {
						if (typeof error.message === 'string') {
							// If the message is a string, set the entire error object as the message
							setErrMsg(error);
						} else if (typeof error.message === 'object') {
							// If the message is an object, unpack and set the error messages
							const errorMessage = Object.entries(error.message)
								.map(([key, value]) => {
									if (Array.isArray(value)) {
										return `${key}: ${value.join(', ')}`;
									}
									return `${key}: ${value}`;
								})
								.join('; ');
							setErrMsg({ message: errorMessage });
						}
					} else {
						// Handle other error formats (though not expected based on provided formats)
						setErrMsg({ message: String(error) });
					}
				}
			} catch (error) {
				setErrMsg({ submit: 'An error occurred during submission.' });
			} finally {
				setSubmitting(false);
			}
		}
	});

	// Inside your component
	const [modalInstance, setModalInstance] = useState(null);

	useEffect(() => {
		let instance = null;

		// Initialize the modal instance if the ref exists
		if (modalRef.current) {
			instance = new Modal(modalRef.current);
			setModalInstance(instance);
		}

		// Cleanup function to dispose of the modal instance when the component unmounts
		return () => {
			if (instance) {
				instance.dispose();
			}
		};
	}, []); // The empty dependency array ensures this runs only once after the component mounts

	const handleOpenModal = useCallback((rendezvous = null) => {
		setIsEditMode(!!rendezvous);
		setCurrentRendezvous(rendezvous);

		if (rendezvous) {
			// Pre-populate form with rendezvous data
			form_add_rendezvous.setValues({
				title: rendezvous.title,
				mode: rendezvous.mode,
				description: rendezvous.description,
				start_date: Moment(rendezvous.start_date + ' ' + rendezvous.start_time).format('YYYY-MM-DD HH:mm'),
				end_date: Moment(rendezvous.end_date + ' ' + rendezvous.end_time).format('YYYY-MM-DD HH:mm'),
				dateRange: `${Moment(rendezvous.start_date + ' ' + rendezvous.start_time).format('D MMM YYYY HH:mm')} - ${Moment(rendezvous.end_date + ' ' + rendezvous.end_time).format('D MMM YYYY HH:mm')}`
			});
			setDateRange(`${Moment(rendezvous.start_date + ' ' + rendezvous.start_time).format('D MMM YYYY HH:mm')} - ${Moment(rendezvous.end_date + ' ' + rendezvous.end_time).format('D MMM YYYY HH:mm')}`);
		} else {
			// Reset form for adding new rendezvous
			form_add_rendezvous.resetForm();
			setDateRange('Select Duration');
		}

		if (modalInstance) {
			modalInstance.show();
		}

	}, [modalInstance, form_add_rendezvous]);

	const handleCloseModal = useCallback(() => {
		if (modalInstance) {
			modalInstance.hide();
		}

		form_add_rendezvous.resetForm();
		setTooltipContent({ title: '', mode: '', description: '', dateRange: '' });
		setIsEditMode(false);
		setCurrentRendezvous(null);
		setDateRange('Select Duration');
	}, [modalInstance, form_add_rendezvous]);

	const handleFocus = (field) => {
		form_add_rendezvous.setFieldTouched(field, true);
	};

	const handleDateApplyEvent = (event, picker) => {
		var startDate = picker.startDate;
		var endDate = picker.endDate;
		var currentRange = startDate.format('D MMM YYYY HH:mm') + ' - ' + endDate.format('D MMM YYYY HH:mm');
		setDateRange(currentRange);
		form_add_rendezvous.setFieldValue('start_date', startDate.format('YYYY-MM-DD HH:mm'))
		form_add_rendezvous.setFieldValue('end_date', endDate.format('YYYY-MM-DD HH:mm'))
		form_add_rendezvous.setFieldValue('dateRange', currentRange, false);
		form_add_rendezvous.setFieldTouched('dateRange', true, true);

		// Clear the error for dateRange explicitly
		form_add_rendezvous.setFieldError('dateRange', '');
		form_add_rendezvous.setErrors({ ...form_add_rendezvous.errors, dateRange: '' });

		// Update tooltip content immediately
		setTooltipContent(prevState => ({
			...prevState,
			dateRange: currentRange ? 'Validation Passed' : form_add_rendezvous.errors.dateRange,
		}));
		form_add_rendezvous.validateField('dataRange');
	};

	const handleSelectChange = (selectedOption) => {
		form_add_rendezvous.setFieldValue('mode', selectedOption.value);
		form_add_rendezvous.setFieldTouched('mode', false);
	};

	useEffect(() => {
		setTooltipContent({
			title: form_add_rendezvous.errors.title || (form_add_rendezvous.touched.title && form_add_rendezvous.values.title ? 'Validation Passed' : ''),
			mode: form_add_rendezvous.errors.mode || (form_add_rendezvous.touched.mode && form_add_rendezvous.values.mode ? 'Validation Passed' : ''),
			description: form_add_rendezvous.errors.description || (form_add_rendezvous.touched.description && form_add_rendezvous.values.description ? 'Validation Passed' : ''),
			dateRange: form_add_rendezvous.errors.dateRange || (form_add_rendezvous.touched.dateRange && form_add_rendezvous.values.dateRange ? 'Validation Passed' : ''),
		});

	}, [form_add_rendezvous.errors, form_add_rendezvous.touched, form_add_rendezvous.values]);

	// END POST - ADD Rendezvous
	// END - POST

	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
	const [errMsg, setErrMsg] = useState({});
	const [ackMsg, setAckMsg] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
	const combinedSuccess = { ...ackMsg };
	const combinedErrors = { ...errMsg };

	// Function to handle closing the alert
	const handleClose = () => {
		setShowAlert(false);
		setErrMsg({}); form_add_rendezvous.setErrors({})
	};
	const handleSuccessClose = () => {
		setShowSuccess(false);
		setAckMsg({});
	};

	// Debounce the function to avoid multiple updates
	const debouncedHandleClose = debounce(handleClose, 500);
	const debouncedHandleSuccessClose = debounce(handleSuccessClose, 500);

	useEffect(() => {
		let timer;
		// Handle error messages
		if (Object.keys(combinedErrors).length > 0) {
			setShowAlert(true);
			timer = setTimeout(() => {
				debouncedHandleClose();
			}, 5000);
		} else {
			setShowAlert(false);
		}

		// Handle success messages
		if (Object.keys(combinedSuccess).length > 0) {
			setShowSuccess(true);
			timer = setTimeout(() => {
				debouncedHandleSuccessClose();
			}, 5000);
		} else {
			setShowSuccess(false);
		}

		// Clean up function for both alerts
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
			debouncedHandleClose.cancel();
			debouncedHandleSuccessClose.cancel();
		};
	}, [combinedErrors, combinedSuccess]); // Depend on both state changes    
	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	return (
		<PerfectScrollbar className="h-100 p-3">
			<div>
				<div>
					<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
					<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
				</div>
				<div id="rendezvous" className="mb-auto">
					<h4>Rendezvous</h4>
					<p>Below is a list of all "LFG" Rendezvous left by community.</p>
					<div className="mb-3">
						<a href="#/" data-bs-target="#modalAddRendezvous" data-bs-toggle="modal" className="btn btn-outline-theme m-1" onClick={() => handleOpenModal(null)}><i className="fa fa-plus-circle me-1"></i> Add Rendezvous</a>
					</div>
					<div className="mode-filters">
						{['MW', 'BR', 'RES', 'RANKMW', 'RANKWZ', 'DMZ', 'ZOMBIES'].map(mode => (
							<button
								key={mode}
								className={`btn btn-outline-secondary btn-sm m-1 ${mode === activeModeFilter ? 'active' : ''}`}
								onClick={() => setActiveModeFilter(mode === activeModeFilter ? null : mode)}
							>
								{getModeClass(mode)[1]}
							</button>
						))}
						<button
							className={`btn btn-outline-secondary btn-sm m-1 ${!activeModeFilter ? 'active' : ''}`}
							onClick={() => setActiveModeFilter(null)}
						>
							Show All
						</button>
					</div>

				</div>

				<div>
					<hr className="mb-3" />
					<div className="text-center"><strong>MY RENDEZVOUS</strong></div>
					<hr className="mb-3" />
				</div>

				<div className="row row-cols-2 row-cols-md-3" style={{ '--bs-gutter-y': '16px' }}>
					{myRendezvous.length > 0 ? myRendezvous.map((rendezvous, index) => (
						<div key={index} className="col">
							<Card className="h-100">
								<CardHeader className="d-flex align-items-center bg-white bg-opacity-15">
									<span className="flex-grow-1 fw-400 text-break">{rendezvous.username}</span>
								</CardHeader>
								<div className="list-group list-group-flush">
									<div className="list-group-item d-flex px-3">

										<div className="flex-fill">
											<div className="fw-400 text-break">{rendezvous.title}</div>
											<hr className="my-3" />
											<div className="small text-white text-break text-opacity-50 mb-2">{rendezvous.description}</div>
											<div>
												{rendezvous.mode && (
													<span className={`badge border border-${getModeClass(rendezvous.mode)[0]} text-${getModeClass(rendezvous.mode)[0]} me-1`}>{getModeClass(rendezvous.mode)[1]}</span>
												)
												}
											</div>
											<hr className="my-2" />
											<div className="d-flex align-items-center mb-1">
												<div className="widget-reminder">
													<div className="widget-reminder-item">
														<div className="widget-reminder-time">{rendezvous.start_time}<br />{rendezvous.end_time}</div>
														<div className="widget-reminder-divider bg-default"></div>
														<div className="widget-reminder-content">
															<div className="fs-13px">{rendezvous.start_date}</div>
															<div className="fs-13px">{rendezvous.end_date}</div>
														</div>
													</div>
												</div>
											</div>
											<hr className="my-2" />
											<div className="d-flex align-items-center mb-1">
												<div className="widget-user-list">
													{rendezvous.interested_users.map((user) => (
														<div key={user.id} className="widget-user-list-item">
															<Link to={`/profile/${user.id}`} title={user.username} className="widget-user-list-link">
																<UserImage imageType={user.profile_pic} altText={user.username} className="ms-100 mh-100 rounded-circle" />
															</Link>
														</div>
													))}
												</div>
											</div>
											<hr className="my-2" />
											<div className="form-group mb-0 small">
												<div className="mb-3 button-container">
													<button className="btn btn-outline-theme me-2 btn-sm" onClick={() => handleOpenModal(rendezvous)}>Edit</button>
													<DeleteRendezvousForm
														rendezvous_id={rendezvous.id}
														backend_endpoint={backend_endpoint}
														getMyRendezvousData={getMyRendezvousData}
														setAckMsg={setAckMsg}
														setErrMsg={setErrMsg}
														ButtonClass="btn btn-outline-danger btn-sm"
														ButtonText="Delete"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Card>
						</div>
					)) : (null)
					}
				</div>

				<div>
					<hr className="mb-3" />
					<div className="text-center"><strong>RENDEZVOUS BOARD</strong></div>
					<hr className="mb-3" />
				</div>

				<div className="row row-cols-2 row-cols-md-3" style={{ '--bs-gutter-y': '16px' }}>
					{filteredAndSortedRendezvous.length > 0 ? filteredAndSortedRendezvous.map((rendezvous, index) => (
						<div key={rendezvous.id} className="col">
							<Card className="h-100">
								<CardHeader className="d-flex align-items-center bg-white bg-opacity-15">
									<span className="flex-grow-1 fw-400 text-break">{rendezvous.username}</span>
								</CardHeader>
								<div className="list-group list-group-flush">
									<div className="list-group-item d-flex px-3">

										<div className="flex-fill">
											<div className="fw-400 text-break">{rendezvous.title}</div>
											<hr className="my-3" />
											<div className="small text-white text-break text-opacity-50 mb-2">{rendezvous.description}</div>
											<div>
												{rendezvous.mode && (
													<span className={`badge border border-${getModeClass(rendezvous.mode)[0]} text-${getModeClass(rendezvous.mode)[0]} me-1`}>{getModeClass(rendezvous.mode)[1]}</span>
												)
												}
											</div>
											<hr className="my-2" />
											<div className="d-flex align-items-center mb-1">
												<div className="widget-reminder">
													<div className="widget-reminder-item">
														<div className="widget-reminder-time">{rendezvous.start_time}<br />{rendezvous.end_time}</div>
														<div className="widget-reminder-divider bg-default"></div>
														<div className="widget-reminder-content">
															<div className="fs-13px">{rendezvous.start_date}</div>
															<div className="fs-13px">{rendezvous.end_date}</div>
														</div>
													</div>
												</div>
											</div>
											<hr className="my-2" />
											<div className="d-flex align-items-center mb-1">
												<div className="widget-user-list">
													{rendezvous.interested_users.map((user) => (
														<div key={user.id} className="widget-user-list-item">
															<Link to={`/profile/${user.id}`} title={user.username} className="widget-user-list-link">
																<UserImage imageType={user.profile_pic} altText={user.username} className="ms-100 mh-100 rounded-circle" />
															</Link>
														</div>
													))}
												</div>
											</div>
											<hr className="my-2" />
											<div className="form-group mb-0 small">
												<div className="mb-3 button-container">
													<InterestRendezvousForm
														rendezvous_id={rendezvous.id}
														backend_endpoint={backend_endpoint}
														getOtherRendezvousData={getOtherRendezvousData}
														setAckMsg={setAckMsg}
														setErrMsg={setErrMsg}
														ButtonClass={rendezvous.isInterested ? 'btn btn-outline-theme btn-sm active' : 'btn btn-outline-theme btn-sm'}
														ButtonText="Interested"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Card>
						</div>
					)) : (null)
					}
				</div>


				<div className={`modal fade`} id="modalAddRendezvous" ref={modalRef} style={{ display: 'none' }}>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{isEditMode ? 'Edit Rendezvous' : 'Add Rendezvous'}</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCloseModal}></button>
							</div>
							<div className="modal-body">
								<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
								<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
								<form onSubmit={form_add_rendezvous.handleSubmit}>

									<div className="mb-3">
										<label htmlFor="dateRange" className="form-label">Duration</label>
										<br />
										<DateRangePicker
											key={isEditMode ? 'edit' : 'create'}
											initialSettings={{
												startDate: isEditMode ? Moment(currentRendezvous.start_date + ' ' + currentRendezvous.start_time, "YYYY-MM-DD HH:mm").toDate() : Moment().startOf('day').toDate(),
												endDate: isEditMode ? Moment(currentRendezvous.end_date + ' ' + currentRendezvous.end_time, "YYYY-MM-DD HH:mm").toDate() : Moment().endOf('day').toDate(),
												maxSpan: { days: 7 },
												minDate: Moment().startOf('day').toDate(),
												container: document.body,
												timePicker: true,  // Enable time picker
												timePicker24Hour: true,  // Use 24-hour format
												timePickerIncrement: 15,  // Time pick increments of 15 minutes
												locale: {
													format: "DD/MM/YYYY HH:mm",
													applyClass: 'applyBtn',
												}
											}}
											onApply={handleDateApplyEvent}
										>
											<button
												id="dateRange"
												name="dateRange"
												onFocus={() => handleFocus('dateRange')}
												onBlur={form_add_rendezvous.handleBlur}
												type="button" className="btn btn-outline-theme text-truncate me-3 mb-2 mb-sm-0">
												<i className="fa fa-fw fa-calendar me-1"></i>
												<span>{dateRange}</span>
												<i className="fa fa-fw fa-caret-down me-n1"></i>
											</button>
										</DateRangePicker>
										<Tooltip anchorSelect="#dateRange" opacity={1} content={tooltipContent.dateRange}
											variant={form_add_rendezvous.errors.dateRange ? 'error' : 'dark'}
											place='right'
										/>
									</div>
									<div className="mb-3">
										<label htmlFor="title" className="form-label">Title</label>
										<input type="text" className="form-control" placeholder="e.g Looking for 2 Diamonds for a Ranked Play..."
											id="title"
											name="title"
											onChange={form_add_rendezvous.handleChange}
											onBlur={form_add_rendezvous.handleBlur}
											value={form_add_rendezvous.values.title || ''}
											onFocus={() => handleFocus('title')}
											aria-invalid={form_add_rendezvous.errors.title ? "true" : "false"}
											aria-describedby="title"
											data-tooltip-variant={form_add_rendezvous.errors.title ? "error" : "dark"}
										/>
										<Tooltip anchorSelect="#title" opacity={1} content={tooltipContent.title}
											variant={form_add_rendezvous.errors.title ? 'error' : 'dark'}
											place='right'
										/>
									</div>
									<div className="mb-3">
										<label className="form-label">MODE</label>
										<Select
										    inputId="mode"
											id="mode"
											name="mode"
											onChange={handleSelectChange}
											onBlur={form_add_rendezvous.handleBlur}
											onFocus={() => handleFocus('mode')}
											value={selectOptions.find(option => option.value === form_add_rendezvous.values.mode) || ''}
											aria-invalid={form_add_rendezvous.errors.mode ? "true" : "false"}
											aria-describedby="mode"
											data-tooltip-variant={form_add_rendezvous.errors.mode ? "error" : "dark"}
											options={selectOptions}
											classNamePrefix="react-select"
										/>
										<Tooltip anchorSelect="#mode" opacity={1} content={tooltipContent.mode}
											variant={form_add_rendezvous.errors.mode ? 'error' : 'dark'}
											place='right'
										/>
									</div>
									<div className="form-group">
										<label htmlFor="description" className="form-label">Description</label>
										<textarea className="form-control" rows="5"
											id="description"
											name="description"
											onChange={form_add_rendezvous.handleChange}
											onBlur={form_add_rendezvous.handleBlur}
											onFocus={() => handleFocus('description')}
											value={form_add_rendezvous.values.description || ''}
											aria-invalid={form_add_rendezvous.errors.description ? "true" : "false"}
											aria-describedby="description"
											data-tooltip-variant={form_add_rendezvous.errors.description ? "error" : "dark"}
										></textarea>
										<Tooltip anchorSelect="#description" opacity={1} content={tooltipContent.description}
											variant={form_add_rendezvous.errors.description ? 'error' : 'dark'}
											place='right'
										/>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-outline-default" data-bs-dismiss="modal" onClick={handleCloseModal}>Close</button>
										<button type="submit" className="btn btn-outline-theme" disabled={form_add_rendezvous.isSubmitting || !form_add_rendezvous.isValid}>
											{isEditMode ? 'Update' : 'Create'}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

			</div>
		</PerfectScrollbar >
	)
}

export default Rendezvous;