import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  roles: "guest",
  user: null,
  location: "/"
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      // Check if the roles field exists and is not "guest", implying a full update
      if (action.payload.roles && action.payload.roles !== "guest") {
        // Assuming a full user object update
        Object.assign(state, {
          isAuthenticated: true,
          ...action.payload,
        });
      } else if (action.payload.roles === "guest") {
        // Reset to initial state if role is explicitly set to "guest"
        Object.assign(state, initialState);
      } else {
        // Partial update
        // This branch allows for updating any part of the auth state, assuming
        // it's neither a full update nor a reset to guest. It just applies
        // whatever is in the payload to the state.
        Object.assign(state, action.payload);
      }

      // Logging to see the updated state
      //console.log("authSlice state.user:", action.payload.user);
    },
    setLocation: (state, action) => {
      if (action.payload)
        state.location = action.payload.pathname;
      else
        state.location = "/"
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setLocation } = authSlice.actions

export default authSlice.reducer