import React from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import UserImage from '../../features/images/userImage.js';
import { useNotifications } from './hooks/header_notifications.js';
import { logout } from '../../features/auth/authActions';
import { NotificationForm } from '../../pages/notifications/hooks/read_notification_form.js';
import { JoinLobbyFromNotifyForm } from '../../pages/lobby/hooks/join_lobby_from_notify_form.js';
import { useNavigate } from 'react-router-dom';
import NavigateTo from '../../pages/notifications/hooks/navigate_to_button.js';

function Header() {
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { isAuthenticated, user, id, userRole, profile_pic } = useSelector(state => ({
		id: state.auth.id,
		user: state.auth.user,
		userRole: state.auth.roles,
		isAuthenticated: state.auth.isAuthenticated,
		profile_pic: state.auth.profile_pic
	}), shallowEqual);

	// Handling global settings
	const backend_endpoint = '/api/notifications';
	const backend_endpoint_lobby = '/api/lobby';
	// END - Handling global settings

	const { notifications, setNotificationAsRead } = useNotifications(id, isAuthenticated); // Using optional chaining in case `user` is undefined initially


	const toggleAppSidebarDesktop = () => {
		var elm = document.querySelector('.app');
		elm.classList.toggle('app-sidebar-collapsed');
	}

	const toggleAppSidebarMobile = () => {
		var elm = document.querySelector('.app');
		elm.classList.toggle('app-sidebar-mobile-toggled');
	}

	const toggleAppHeaderSearch = () => {
		var elm = document.querySelector('.app');
		elm.classList.toggle('app-header-menu-search-toggled');
	}

	const handlelogout = () => {
		dispatch(logout());
	}

	return (
		<div id="header" className="app-header">
			<div className="desktop-toggler">
				<button type="button" className="menu-toggler" onClick={toggleAppSidebarDesktop}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</div>

			<div className="mobile-toggler">
				<button type="button" className="menu-toggler" onClick={toggleAppSidebarMobile}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</div>

			<div className="brand">
				<Link to="/" className="brand-logo">
					<span className="brand-img">
					</span>
				</Link>
			</div>

			<div className="menu">
				<div className="menu-item dropdown">
					<a href="#/" onClick={toggleAppHeaderSearch} className="menu-link">
						<div className="menu-icon"><i className="bi bi-search nav-icon"></i></div>
					</a>
				</div>
				<div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-icon"><i className="bi bi-grid-3x3-gap nav-icon"></i></div>
					</a>
					<div className="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1">
						<div className="row row-grid gx-0">
							<div className="col-4">
								<Link to="/intel" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-question-square h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-white">INTEL</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/settings" className="dropdown-item text-decoration-none p-3 bg-none">
									<div className="position-relative">
										<i className="bi bi-sliders h2 opacity-5 d-block my-1"></i>
									</div>
									<div className="fw-500 fs-10px text-white">SETTINGS</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-icon"><i className="bi bi-bell nav-icon"></i></div>
						{notifications.some(notification => !notification.read) && (
							<div className="menu-badge bg-theme"></div>
						)}
					</a>
					<div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
						<h6 className="dropdown-header fs-10px mb-1">NOTIFICATIONS</h6>
						<div className="dropdown-divider mt-1"></div>
						{notifications.length > 0 ? notifications.map((notification, index) => {
							const typePrefix = 'lobby_invite_id_';
							const notificationType = notification?.type ?? ''; // Ensure we always work with a string
							const startIndex = notificationType.indexOf(typePrefix); // Now safe to use indexOf

							// if (startIndex !== -1) {
							// 	console.log("Prefix found, ID should follow:", notificationType.substring(startIndex + typePrefix.length));
							// }

							return (
								<div key={notification.id} className="d-flex align-items-center py-10px dropdown-item text-wrap">
									<div className="fs-20px">
										{notification.read ?
											<i className="far fa-bell text-muted me-2"></i> :
											<i className="fas fa-bell me-2 text-theme"></i>}
									</div>
									<div className="flex-1 flex-wrap ps-3">
										<div className="mb-1 text-white">{notification.message}</div>
										<div className="small">{notification.created_at}</div>
										{startIndex !== -1 ? (
											<JoinLobbyFromNotifyForm
												lobby_id={notificationType.substring(startIndex + typePrefix.length)}
												notification_id={notification.id}
												backend_endpoint={backend_endpoint_lobby}
												ButtonClass="btn btn-sm btn-outline-theme mt-1"
												ButtonText="JOIN"
												navigate={navigate}
											/>
										) : (
											<NavigateTo
												type={notification.type}
												notification_id={notification.id}
												ButtonClass="btn btn-sm btn-outline-secondary mt-1"
												ButtonText="Navigate"
												navigate={navigate}
											/>
										)
										}
									</div>
									<div className="ps-2 fs-16px">
										<NotificationForm
											setNotificationAsRead={setNotificationAsRead}
											notification_id={notification.id}
											notifications={notifications}
											backend_endpoint={backend_endpoint}
											ButtonClass="icon-button" />
									</div>
								</div>
							);
						}) : (
							<div className="dropdown-notification-item p-3 text-center">
								No recent notifications found
							</div>
						)}
						<hr className="bg-white-transparent-5 mb-0 mt-2" />
						<div className="py-10px mb-n2 text-center">
							<Link to="/notifications" className="text-decoration-none fw-bold">SEE ALL</Link>
						</div>
					</div>
				</div>
				{isAuthenticated ? <div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-img online">
							<div className="d-flex align-items-center justify-content-center w-100 h-100 bg-white bg-opacity-25 text-white text-opacity-50 rounded-circle overflow-hidden">
								<UserImage imageType={profile_pic} altText="Profile Picture" height="60" />
							</div>
						</div>
						<div className="menu-text d-sm-block d-none">{user}</div>
					</a>
					<div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
						<Link to="/profile" className="dropdown-item d-flex align-items-center">PROFILE <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1"></i></Link>
						<Link to="/settings" className="dropdown-item d-flex align-items-center">SETTINGS <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i></Link>
						<div className="dropdown-divider"></div>
						<button to="/logout" className="dropdown-item d-flex align-items-center" onClick={handlelogout}>LOGOUT <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i></button>
					</div>
				</div> :
					<div className="menu-item dropdown dropdown-mobile-full">
						<Link to="/auth/login" className="menu-link d-flex align-items-center">
							<div className="menu-text d-sm-block d-none">Login <i className="fas fa-lg fa-fw me-2 fa-sign-in-alt ms-auto fs-16px my-n1"></i></div>
						</Link>
					</div>}
			</div>

			<form className="menu-search" method="POST" name="header_search_form">
				<div className="menu-search-container">
					<div className="menu-search-icon"><i className="bi bi-search"></i></div>
					<div className="menu-search-input">
						<input id="menu-search-input" name="menu-search-input" type="text" className="form-control form-control-lg" placeholder="Search menu..." />
					</div>
					<div className="menu-search-icon">
						<a href="#/" onClick={toggleAppHeaderSearch}><i className="bi bi-x-lg"></i></a>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Header;