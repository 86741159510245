import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';

export function AcceptFriendRequestForm({ getFriendshipsData, getFriendshipRequestsData, request_id, requests = [], backend_endpoint, setAckMsg, setErrMsg, ButtonClass, ButtonText }) {
	requests = requests || [];
	const formik = useFormik({
		initialValues: { request_id: request_id },
		validate: values => {
			const errors = {};
			if (!values.request_id) {
				errors.request_id = 'Request ID is required';
			} else if (typeof values.request_id !== 'number' || !Number.isInteger(values.request_id)) {
				errors.request_id = 'Request ID must be an integer';
			} else if (!requests || !requests.received_requests.some(user => user.id === values.request_id)) {
				errors.request_id = 'Invalid request ID';
			}
			return errors;
		},
		onSubmit: async (values, { setSubmitting, setErrors }) => {
			try {
				const { request_id } = values;
				const formIdentifier = 'accept_friendship_request'
				const payloadSubmission = { formIdentifier, request_id };
				const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
				if (data) {
					setAckMsg(data);
					getFriendshipsData();
					getFriendshipRequestsData();
				}
				if (error) {
					setErrMsg(error);
				}
			} catch (error) {
				setErrMsg(error);
			} finally {
				setSubmitting(false);  // Ensures form is re-enable after async operation
			}
		}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<input type="hidden" name="request_id" value={formik.values.request_id} onChange={formik.handleChange} />
			<button type="submit" className={ButtonClass}>
				{ButtonText}
			</button>
		</form>
	);
}