import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import Alert from 'react-bootstrap/Alert';

const AlertTransition = ({ show, type, messageMap, onClose }) => {
    const nodeRef = useRef(null);

    return (
        <Transition in={show} timeout={500} nodeRef={nodeRef}>
            {(state) => (
                <div ref={nodeRef} className={`alert-transition-${state}`}>
                    {show && (
                        <Alert variant={type} dismissible onClose={onClose}>
                            {Object.entries(messageMap).map(([key, value], index) => (
                                <div key={index}>
                                    <strong>
                                        <code style={{ color: type === 'danger' ? 'red' : 'green' }}>{`${key}: ${value}`}</code>
                                    </strong>
                                </div>
                            ))}
                        </Alert>
                    )}
                </div>
            )}
        </Transition>
    );
};

export default AlertTransition;