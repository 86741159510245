import React, { useState, useEffect, useContext } from 'react';
import { Card } from './../../components/card/card.jsx';
import { Modal } from 'bootstrap';
import { Tooltip } from "react-tooltip";
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { setUser } from './../../features/auth/authSlice.js';
import { getGenericData } from '../../api/generic-get.js'
import { postGenericData } from '../../api/generic-post.js'
import { postGenericFile } from '../../api/generic-post-multipart.js'
import debounce from 'lodash.debounce';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AlertTransition from '../../features/alert_success_transitions.js';
import { AppSettings } from '../../config/app-settings.js';
import { shallowEqual, useSelector } from 'react-redux';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{12,24}$/;
const ATVI_ID_REGEX = /^.+#[0-9]{5,}$/;

function Settings() {
	// There are two ways to get csrf token for form validation
	// 1) is to get token per form and return it in get method in flask then store it and inject it with post
	// 2) get token from cookie that is same for the session lenght and inject it with post
	// Second method is enabled here and first is commented out.

	// COMPONENT ENDPOINT
	const backend_endpoint = '/api/settings';

	// HANDLE CSRF TOKEN

	// HANDLE DISPATCH
	const dispatch = useDispatch()

	const { isAuthenticated, user, id, userRoles } = useSelector(state => ({
		id: state.auth.id,
		user: state.auth.user,
		userRoles: state.auth.roles,
		isAuthenticated: state.auth.isAuthenticated,
	}), shallowEqual);

	// Handling template context
	const context = useContext(AppSettings);

	useEffect(() => {
		context.setAppContentClass('p-1');
		context.setAppContentFullHeight(true);

		return function cleanUp() {
			context.setAppContentClass('');
			context.setAppContentFullHeight(false);
		};

		// eslint-disable-next-line
	}, []);
	// END - Handling template context

	// ATVI_ID LOGIC
	const [atviId, setAtviId] = useState('');
	const [validAtviId, setValidAtviId] = useState(false);
	const [atviIdFocus, setAtviIdFocus] = useState(false);

	// PASS CHANGE LOGIC
	const [password, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [password_confirm, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);

	const [old_password, setOldPwd] = useState('');

	useEffect(() => {
		setValidAtviId(ATVI_ID_REGEX.test(atviId));
	}, [atviId]);

	useEffect(() => {
		setValidPwd(PWD_REGEX.test(password));
		setValidMatch(password === password_confirm);
	}, [password, password_confirm])

	useEffect(() => {
		setErrMsg('');
	}, [password, password_confirm])

	const handlePwdChange = (event) => {
		let isMounted = true;
		event.preventDefault();

		const postPwdChange = async () => {

			const v2 = PWD_REGEX.test(password);
			if (!v2) {
				setErrMsg("Invalid Entry");
				return;
			}

			const { data, error } = await postGenericData(backend_endpoint, { ["pwd_change"]: { "old_password": old_password, "new_password": password } });

			if (!isMounted) {
				return;
			}

			if (data) {
				setShowModal(false);
				setAckMsg(data);
				setShowSuccess(true);
				const modal = Modal.getInstance(document.querySelector('#modalPassword'));
				modal.hide();
			}

			if (error) {
				setErrMsg(error);
			}
		};

		postPwdChange();

		return () => {
			isMounted = false;
		};
	};


	// USER PROFILE DATA LOGIC
	const [data, setData] = useState({
		username: '',
		nickname: '',
		atvi_id: '',
		discord_id: '',
		email: '',
		network: '',
		country: '',
		description: '',
		yt_link: '',
		ttv_link: '',
	});

	const modalConfig = {
		username: { label: 'Username' },
		nickname: { label: 'Nickname' },
		atvi_id: { label: 'ATVI ID' },
		discord_id: { label: 'Discord ID' },
		email: { label: 'Email' },
		network: { label: 'Network' },
		country: { label: 'Country' },
		description: { label: 'Description' },
		yt_link: { label: 'YouTube Link' },
		ttv_link: { label: 'Twitch Link' },
	};

	const [showModal, setShowModal] = useState(false);
	const [editingKey, setEditingKey] = useState(null);
	const [editValue, setEditValue] = useState('');

	useEffect(() => {
		let isMounted = true;

		const getUserData = async () => {
			const queryParams = new URLSearchParams({
				get_settings: true,
			});
			const url = `${backend_endpoint}?${queryParams.toString()}`;
			const { data, error } = await getGenericData(url);

			if (!isMounted) {
				return;
			}

			if (data) {
				const { csrf_token, ...RemoveTokenFromPayload } = data;
				setData(RemoveTokenFromPayload);
			}

			if (error) {
				setErrMsg(error);
			}
		};

		getUserData();

		return () => {
			isMounted = false;
		};
	}, []);

	const handleSaveClick = () => {
		let isMounted = true;

		const postUserData = async () => {
			const { data, error } = await postGenericData(backend_endpoint, { [editingKey]: editValue });

			if (!isMounted) {
				return;
			}

			if (data) {
				setShowModal(false);
				setData(prevData => ({ ...prevData, [editingKey]: editValue }));
				setAckMsg(data);
				setShowSuccess(true);
				const modal = Modal.getInstance(document.querySelector('#modal' + editingKey));
				modal.hide();
				// if using localStorage for setUser then the below would update current state and localStorage
				//dispatch(setUser(prevData => ({ ...prevData, [editingKey]: editValue })));
				dispatch(setUser({ [editingKey]: editValue }));

			}

			if (error) {
				if (typeof error === 'object') {
					if (typeof error.message === 'string') {
						// If the message is a string, set the entire error object as the message
						setErrMsg(error);
					} else if (typeof error.message === 'object') {
						// If the message is an object, unpack and set the error messages
						const errorMessage = Object.entries(error.message)
							.map(([key, value]) => {
								if (Array.isArray(value)) {
									return `${key}: ${value.join(', ')}`;
								}
								return `${key}: ${value}`;
							})
							.join('; ');
						setErrMsg({ message: errorMessage });
					}
				} else {
					// Handle other error formats (though not expected based on provided formats)
					setErrMsg({ message: String(error) });
				}
			}

		};

		postUserData();

		return () => {
			isMounted = false;
		};
	};


	const handleEditClick = (key) => {
		setEditingKey(key);
		setEditValue(data[key] || '');
		setShowModal(true);
	};

	const handleInputChange = (e) => {
		setEditValue(e.target.value);
	};

	const handleSetAtviIDChange = (e) => {
		setEditValue(e.target.value);
		setAtviId(e.target.value)
	};


	// PROFILE PICTURE UPLOAD LOGIC
	const [selectedProfileFile, setSelectedProfilePic] = useState(null);
	const [profilePicPreview, setProfilePicPreview] = useState(null);
	const [statsType, setStatsType] = useState('single_stats');  // Default to single

	const handleProfileFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedProfilePic(file);

		const reader = new FileReader();
		reader.onloadend = () => {
			setProfilePicPreview(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const handleProfilePicUpload = () => {
		let isMounted = true;

		const formData = new FormData();
		formData.append('file', selectedProfileFile);
		formData.append('file_type', 'profile_pic');

		const postPicture = async () => {
			const { data, error } = await postGenericFile(backend_endpoint, formData);

			if (!isMounted) {
				return;
			}

			if (data) {
				setShowModal(false);
				setAckMsg({ 'message': data.message });
				setShowSuccess(true);
				const modal = Modal.getInstance(document.querySelector('#modalProfilePic'));
				modal.hide();
				dispatch(setUser({ 'profile_pic': `profile-pics/${data.data}` }));

			}

			if (error) {
				setErrMsg(error);
			}
		};

		postPicture();

		return () => {
			isMounted = false;
		};
	};
	// END - PROFILE PICTURE UPLOAD LOGIC

	// STATS PIC UPLOAD LOGIC
	const [selectedStatsPic, setSelectedStatsPic] = useState(null);
	const [statsPicPreview, setStatsPicPreview] = useState(null);
	const [isUploading, setIsUploading] = useState(false);

	const handleStatsPicChange = (event) => {
		const file = event.target.files[0];
		setSelectedStatsPic(file);

		const reader = new FileReader();
		reader.onloadend = () => {
			setStatsPicPreview(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const handleStatsPicUpload = async () => {
		let isMounted = true;
		setIsUploading(true);  // Show spinner

		const formData = new FormData();
		formData.append('file', selectedStatsPic);
		formData.append('file_type', 'stats_pic');
		formData.append('stats', statsType);  // Add the selected stats type (single_stats or multiple_stats)

		// const postStatsPicture = async () => {
		try {
			const { data, error } = await postGenericFile(backend_endpoint, formData);

			if (!isMounted) {
				return;
			}

			if (data) {
				setShowModal(false);
				setAckMsg({ 'message': data.message });
				setShowSuccess(true);
				const modal = Modal.getInstance(document.querySelector('#modalStatsPic'));
				modal.hide();
				dispatch(setUser({ 'stats_pic': `stats-pics/${data.data}` }));
			}

			if (error) {
				setErrMsg(error);
			}
			//};
		} catch (error) {
			console.error('Upload failed', error);
		} finally {
			setIsUploading(false);  // Hide spinner
		}

		return () => {
			isMounted = false;
		};
	};
	// END - STATS PIC UPLOAD LOGIC

	// SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

	const [errMsg, setErrMsg] = useState({});
	const [ackMsg, setAckMsg] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);

	const combinedSuccess = { ...ackMsg };
	const combinedErrors = { ...errMsg };

	// Function to handle closing the alert
	const handleClose = () => {
		setShowAlert(false);
		setErrMsg({});
	};
	const handleSuccessClose = () => {
		setShowSuccess(false);
		setAckMsg({});
	};

	// Debounce the function to avoid multiple updates
	const debouncedHandleClose = debounce(handleClose, 500);
	const debouncedHandleSuccessClose = debounce(handleSuccessClose, 500);


	useEffect(() => {
		let timer;
		// Handle error messages
		if (Object.keys(combinedErrors).length > 0) {
			setShowAlert(true);
			timer = setTimeout(() => {
				debouncedHandleClose();
			}, 5000);
		} else {
			setShowAlert(false);
		}

		// Handle success messages
		if (Object.keys(combinedSuccess).length > 0) {
			setShowSuccess(true);
			timer = setTimeout(() => {
				debouncedHandleSuccessClose();
			}, 5000);
		} else {
			setShowSuccess(false);
		}

		// Clean up function for both alerts
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
			debouncedHandleClose.cancel();
			debouncedHandleSuccessClose.cancel();
		};
	}, [combinedErrors, combinedSuccess]); // Depend on both state changes	
	// END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...


	return (
		<PerfectScrollbar className="h-100 p-3">
			<div>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-10">
							<div className="row">
								<div className="col-xl-9">
									<div>
										<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
										<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
									</div>
									<div id="general" className="mb-5">
										<h4><i className="far fa-user fa-fw text-theme"></i> General</h4>
										<p>View and update your general account information and settings.</p>
										<Card>
											<div className="list-group list-group-flush">
												{Object.keys(data).map(key => (
													<div key={key} className="list-group-item d-flex align-items-center">
														<div className="flex-1 text-break">
															<div>{modalConfig[key].label}</div>
															<div className="text-white text-opacity-50">{data[key]}</div>
														</div>
														<div className="w-100px">
															<a href={`#modal${key}`} data-bs-toggle="modal" className="btn btn-outline-default w-100px" onClick={() => handleEditClick(key)}>
																Edit
															</a>
														</div>
													</div>
												))}
												<div className="list-group-item d-flex align-items-center">
													<div className="flex-1 text-break">
														<div>Password</div>
													</div>
													<div>
														<a href="#modalPassword" data-bs-toggle="modal" className="btn btn-outline-default w-100px">Edit</a>
													</div>
												</div>
											</div>
										</Card>
									</div>

									<div id="profile_pic" className="mb-5">
										<h4><i className="fa-solid fa-camera-retro fa-fw text-theme"></i> Profile Picture</h4>
										<p>Upload your profile picture.</p>
										<Card>
											<div className="list-group list-group-flush">
												<div key="profile_pic" className="list-group-item d-flex align-items-center">
													<div className="flex-1 text-break">
														<div>Choose your profile picture for upload from your device.</div>
													</div>
													<div>
														<a href="#modalProfilePic" data-bs-toggle="modal" className="btn btn-outline-default w-100px">
															Edit
														</a>
													</div>
												</div>
											</div>
										</Card>
									</div>

									<div id="stats_pic" className="mb-5">
										<h4><i className="fa-solid fa-chart-bar fa-fw text-theme"></i> Stats Picture</h4>
										<p>Upload your stats picture.</p>
										<Card>
											<div className="list-group list-group-flush">
												<div key="stats_pic" className="list-group-item d-flex align-items-center">
													<div className="flex-1 text-break">
														<div>Choose your stats picture for upload from your device.</div>
													</div>
													<div>
														<a href="#modalStatsPic" data-bs-toggle="modal" className="btn btn-outline-default w-100px">
															Edit
														</a>
													</div>
												</div>
											</div>
										</Card>
									</div>
									
									{userRoles.includes("admins") && (
									<div id="subscription" className="mb-5">
										<h4><i className="far fa-credit-card fa-fw text-theme"></i> Subscription</h4>
										<p>Manage your subscription</p>
										<Card>
											<div className="list-group list-group-flush">
												<div className="list-group-item d-flex align-items-center">
													<div className="flex-1 text-break">
														<div>Current Subscription Status</div>
														<div className="text-white text-opacity-50">
															Valid, Active, Premium
														</div>
													</div>
													<div>
														<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-outline-default w-100px">Edit</a>
													</div>
												</div>
											</div>
										</Card>
									</div>
									)}

								</div>

							</div>
						</div>
					</div>
				</div>

				{/* Modal id Form - Start */}
				{Object.keys(data).map(key => (
					<div key={`modal${key}`} id={`modal${key}`} className={`modal fade ${showModal && editingKey === key ? 'show' : ''}`} tabIndex="-1">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Edit {modalConfig[key].label}</h5>
									<button type="button" className="btn-close" onClick={() => setShowModal(false)} data-bs-dismiss="modal"></button>
								</div>
								<div className="modal-body">
									<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
									<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
									<label htmlFor={`editInput${key}`} className="form-label">{`Edit ${modalConfig[key].label}`}</label>
									{key === 'atvi_id' && (
										<>
											<input
												type="text"
												placeholder="Enter ATVI ID"
												id={`editInput${key}`}
												name={`editInput${key}`}
												value={atviId}
												onChange={handleSetAtviIDChange}
												onFocus={() => setAtviIdFocus(true)}
												onBlur={() => setAtviIdFocus(false)}
												required
												aria-invalid={validAtviId ? "false" : "true"}
												className="form-control bg-white bg-opacity-5"
												data-tooltip-variant={atviIdFocus && !validAtviId ? "error" : "dark"}
											/>
											<Tooltip
												anchorId="editInputatvi_id"
												style={{ width: "70%" }}
												content="Enter a valid ATVI ID following the unique Activision ID format: '<username>#<ID>'."
												className={atviIdFocus && !validAtviId ? "error" : "dark"}
												place="right"
											/>
										</>
									)}
									{key === 'country' && (
										<Select
											inputId={`editInput${key}`}
											name={`editInput${key}`}
											options={[
												{ value: '', label: 'Select a Country' },
												{ value: 'Czech Republic', label: 'Czech Republic' },
												{ value: 'France', label: 'France' },
												{ value: 'Germany', label: 'Germany' },
												{ value: 'Italy', label: 'Italy' },
												{ value: 'Netherlands', label: 'Netherlands' },
												{ value: 'Norway', label: 'Norway' },
												{ value: 'Poland', label: 'Poland' },
												{ value: 'Slovakia', label: 'Slovakia' },
												{ value: 'Spain', label: 'Spain' },
												{ value: 'Sweden', label: 'Sweden' },
												{ value: 'United Kingdom', label: 'United Kingdom' },
												{ value: 'United States', label: 'United States' },
												{ value: 'Canada', label: 'Canada' },
												{ value: 'Mexico', label: 'Mexico' }
											]}
											value={[
												{ value: '', label: 'Select a Country' },
												{ value: 'Czech Republic', label: 'Czech Republic' },
												{ value: 'France', label: 'France' },
												{ value: 'Germany', label: 'Germany' },
												{ value: 'Italy', label: 'Italy' },
												{ value: 'Netherlands', label: 'Netherlands' },
												{ value: 'Norway', label: 'Norway' },
												{ value: 'Poland', label: 'Poland' },
												{ value: 'Slovakia', label: 'Slovakia' },
												{ value: 'Spain', label: 'Spain' },
												{ value: 'Sweden', label: 'Sweden' },
												{ value: 'United Kingdom', label: 'United Kingdom' },
												{ value: 'United States', label: 'United States' },
												{ value: 'Canada', label: 'Canada' },
												{ value: 'Mexico', label: 'Mexico' }
											].find(option => option.value === editValue) || null}
											onChange={option => setEditValue(option ? option.value : '')}
											classNamePrefix="react-select"
										/>
									)}
									{key === 'network' && (
										<Select
											inputId={`editInput${key}`}
											name={`editInput${key}`}
											options={[
												{ value: '', label: 'Select a Network' },
												{ value: 'Battlenet', label: 'Battlenet' },
												{ value: 'Steam', label: 'Steam' },
												{ value: 'PS5', label: 'PS5' },
												{ value: 'XBOX', label: 'XBOX' }
											]}
											value={[
												{ value: '', label: 'Select a Network' },
												{ value: 'Battlenet', label: 'Battlenet' },
												{ value: 'Steam', label: 'Steam' },
												{ value: 'PS5', label: 'PS5' },
												{ value: 'XBOX', label: 'XBOX' }
											].find(option => option.value === editValue) || null}
											onChange={option => setEditValue(option ? option.value : '')}
											classNamePrefix="react-select"
										/>
									)}
									{key !== 'country' && key !== 'network' && key !== 'atvi_id' && (
										<input
											type="text"
											className="form-control"
											id={`editInput${key}`}
											name={`editInput${key}`}
											value={editValue}
											onChange={handleInputChange}
										/>
									)}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-outline-default" onClick={() => setShowModal(false)} data-bs-dismiss="modal">Close</button>
									<button type="button" className="btn btn-outline-theme" onClick={handleSaveClick}
										disabled={key === 'atvi_id' ? !validAtviId : false}  // Disable if editing 'atvi_id' and it's invalid
									>
										Save changes
									</button>
								</div>
							</div>
						</div>
					</div>
				))}
				<div key="modalPassword" className="modal fade" id="modalPassword">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Change Password</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
							</div>
							<div className="modal-body">
								<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
								<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
								<div className="mb-3">
									<div className="row row-space-10 mb-3">
										<div className="col-4">
											<input type="password"
												id="old_password"
												name="old_password"
												className="form-control"
												placeholder="Old Password"
												onChange={(event) => setOldPwd(event.target.value)}
											/>
										</div>
									</div>
									<div className="row row-space-10">
										<div className="col-4">
											<input type="password"
												placeholder="New Password"
												id="password"
												name="password"
												onChange={(event) => setPwd(event.target.value)}
												value={password}
												required
												aria-invalid={validPwd ? "false" : "true"}
												aria-describedby="password"
												onFocus={() => setPwdFocus(true)}
												onBlur={() => setPwdFocus(false)}
												className="form-control bg-white bg-opacity-5"
												data-tooltip-variant={pwdFocus && !validPwd ? "error" : "dark"}
											/>
											<Tooltip anchorId="password" style={{ width: "70%" }}
												content="Use Min 12 and Max 24 characters. Must include uppercase and lowercase letters, a number and a special character."
												className={pwdFocus && !validPwd ? "error" : "dark"} place="right" />
										</div>
										<div className="col-4">
											<input type="password"
												placeholder="Repeat New Password"
												id="confirm_pwd"
												name="confirm_pwd"
												onChange={(event) => setMatchPwd(event.target.value)}
												value={password_confirm}
												required
												aria-invalid={validMatch ? "false" : "true"}
												aria-describedby="confirm_pwd"
												onFocus={() => setMatchFocus(true)}
												onBlur={() => setMatchFocus(false)}
												className="form-control bg-white bg-opacity-5"
												data-tooltip-variant={matchFocus && !validMatch ? "error" : "dark"}
											/>
											<Tooltip anchorId="confirm_pwd" style={{ width: "70%" }}
												content="Please repeat your password."
												className={matchFocus && !validMatch ? "error" : "dark"} place="right" />
										</div>
									</div>
								</div>
								<div className="alert alert-muted">
									<b>Please note: </b>
									Only alphabetical characters, numbers and special characters (_, -, ?, !, +, =, and #) are allowed.
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-outline-default" onClick={() => setShowModal(false)} data-bs-dismiss="modal">Close</button>
								<button disabled={!validPwd || !validMatch ? true : false} type="button" className="btn btn-outline-theme" onClick={handlePwdChange}>Reset Passowrd</button>
							</div>
						</div>
					</div>
				</div>

				<div key="modalProfilePic" className="modal fade" id="modalProfilePic">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Change Profile Picture</h5>
								<button type="button" className="btn-close" onClick={() => setShowModal(false)} data-bs-dismiss="modal"></button>
							</div>
							<div className="modal-body">
								<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
								<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
								<div className="mb-3">
									<div className="row row-space-10 mb-3">
										<div className="col-auto">
											<form method='post' id='change_profile_pic' encType='multipart/form-data'>
												<div className='row'>
													<div className="mb-3">
														<label className="form-label" htmlFor="change_profile_pic">Choose file</label>
														<input type="file" className="form-control" id="change_profile_pic" onChange={handleProfileFileChange} />
													</div>
												</div>
											</form>
											<br />
											{profilePicPreview && <img src={profilePicPreview} alt="Preview" style={{ width: '200px', height: 'auto' }} />}
										</div>
									</div>
								</div>
								<div className="alert alert-muted">
									<b>Please note: </b>
									Only following file formats numbers (.jpg, .jpeg, .png, .gif) are allowed.
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-outline-default" onClick={() => setShowModal(false)} data-bs-dismiss="modal">Close</button>
								<button type="button" className="btn btn-outline-theme" onClick={handleProfilePicUpload}>
									Save changes
								</button>
							</div>
						</div>
					</div>
				</div>

				<div key="modalStatsPic" className="modal fade" id="modalStatsPic">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Change Stats Picture</h5>
								<button type="button" className="btn-close" onClick={() => setShowModal(false)} data-bs-dismiss="modal"></button>
							</div>
							<div className="modal-body">
								<AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
								<AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
								{/* File Upload */}
								<div className="mb-3">
									<form method='post' id='change_stats_pic' encType='multipart/form-data'>
										<label className="form-label" htmlFor="change_stats_pic">Choose file</label>
										<input type="file" className="form-control" id="change_stats_pic" onChange={handleStatsPicChange} />
									</form>
									<br />
									{statsPicPreview && <img src={statsPicPreview} alt="Preview" style={{ width: '200px', height: 'auto' }} />}
								</div>
								{/* Stats Type Selection using <Select> */}
								<div className="mb-3">
									<label className="form-label">Select Stats Type:</label>
									<Select
										options={[
											{ value: 'single_stats', label: 'MW Stats Image' },
											{ value: 'multiple_stats', label: 'MW + BR + RES Stats Image' },
										]}
										value={{ value: statsType, label: statsType === 'single_stats' ? 'MW Stats Image' : 'MW + BR + RES Stats Image' }}
										onChange={(option) => setStatsType(option.value)}
										classNamePrefix="react-select"
									/>
								</div>
								<div className="alert alert-muted">
									<b>Please note: </b>
									Only following file formats numbers (.jpg, .jpeg, .png, .gif) are allowed.
								</div>
								{isUploading && (
									<div className="alert alert-muted text-theme">
										<b>WARNING: </b>
										Validation process takes approx. 20-30 seconds.
										Do not close modal, redirect or close browser until status update.
									</div>
								)}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-outline-default" onClick={() => setShowModal(false)} data-bs-dismiss="modal">Close</button>
								<button type="button" className="btn btn-outline-theme" onClick={handleStatsPicUpload}>
									Save changes
								</button>
								{isUploading && (
									<div className="spinner-border text-warning end-0"></div>
								)}
							</div>
						</div>
					</div>
				</div>
				{/* Modal is Forms - End */}
			</div>
		</PerfectScrollbar>

	)
}

export default Settings;