import React from 'react';
import { Tooltip } from "react-tooltip";
import UserImage from '../../../features/images/userImage';
import { Link } from 'react-router-dom';

export const LobbyUserTooltip = ({ user, prefix, className, place }) => {
    return (
        <Tooltip
            key={`${prefix}-tooltip-${user.id}`}
            anchorId={`${prefix}-tooltip-${user.id}`}
            html={`
                <b class="text-warning">User:</b> ${user.username || 'N/A'}<br />
                <b class="text-white">Active:</b> ${user.has_active_session === true ? '<i class="fa fa-circle fs-8px fa-fw text-success me-1"></i>' : '<i class="fa fa-circle fs-8px fa-fw text-danger me-1"></i>'}<br />
                <b class="text-white">Last Seen:</b> ${user.was_last_seen === 0 ? '0 minutes ago' : user.was_last_seen ? `${user.was_last_seen} minutes ago` : 'inactive'} <br />
                <b class="text-teal">Activision ID:</b> ${user.atvi_id ? `${user.atvi_id} <span class="badge border ${user.verified ? 'border-indigo text-indigo' : 'border-danger text-danger'}">[${user.verified ? 'verified' : 'unverified'}]</span>` : '<b class="text-gray-600">N/A</b>'}<br />
                <b class="text-teal">Discord ID:</b> ${user.discord_id ? `${user.discord_id} <span class="badge border border-indigo text-indigo">[verified]</span>` : '<b class="text-gray-600">N/A</b>'}<br />
                <b>MW KD:</b> ${user.mw_kd !== '' ? `<b class="text-yellow">${user.mw_kd}</b>` : '<b class="text-gray-600">N/A</b>'}<br />
                <b>BR KD:</b> ${user.br_kd !== '' ? `<b class="text-yellow">${user.br_kd}</b>` : '<b class="text-gray-600">N/A</b>'}<br />
                <b>RES KD:</b> ${user.res_kd !== '' ? `<b class="text-yellow">${user.res_kd}</b>` : '<b class="text-gray-600">N/A</b>'}<br />
                <b>RANK KD:</b> ${user.rank_kd !== '' ? `<b class="text-yellow">${user.rank_kd}</b>` : '<b class="text-gray-600">N/A</b>'}<br />
                <b>RANK:</b> ${user.rank !== '' ? (
                    /IRIDESCENT/.test(user.rank) ? `<b class="text-indigo">${user.rank}</b>` :
                        /CRIMSON/.test(user.rank) ? `<b class="text-danger">${user.rank}</b>` :
                            /DIAMOND/.test(user.rank) ? `<b class="text-primary">${user.rank}</b>` :
                                /PLATINUM/.test(user.rank) ? `<b class="text-success">${user.rank}</b>` :
                                    /GOLD/.test(user.rank) ? `<b class="text-yellow">${user.rank}</b>` :
                                        /SILVER/.test(user.rank) ? `<b class="text-gray-300">${user.rank}</b>` :
                                            /BRONZE/.test(user.rank) ? `<b class="text-warning">${user.rank}</b>` :
                                                `<b class="text-default">${user.rank}</b>`
                ) : '<b class="text-gray-600">N/A</b>'}<br />
                `}
            className={className}
            place={place}
        />
    );
};

export const LobbyUserLink = ({ user, handleTouchStart, handleClickMobile, prefix }) => {
    return (
        <Link
            to={`/profile/${user.id}`}
            className="list-group-item list-group-item-action d-flex align-items-center text-white text-break"
            id={`${prefix}-tooltip-${user.id}`}
            onTouchStart={(e) => handleTouchStart(e, user.id)}
            onClick={(e) => handleClickMobile(e, user.id)}
        >
            <div className="w-40px h-40px d-flex align-items-center justify-content-center ms-n1">
                <UserImage imageType={user.profile_pic} altText="" className="ms-100 mh-100 rounded-circle" />
            </div>
            <div className="flex-fill ps-3">
                <div className="fw-bold">
                    {user.username}
                </div>
            </div>
        </Link>
    );
};