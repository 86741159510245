import axiosInstance, { callExternalApi, getCsrfToken } from "./external-api.service";

export const postGenericData = async (endpoint, payload) => {

  const csrfToken = getCsrfToken();
  
  const config = {
    url: endpoint,
    mode: 'cors',
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
      'X-CSRF-Token': csrfToken,
    },
    // withCredentials: true,
    data: JSON.stringify(payload)
  };
  
  const { data, error } = await callExternalApi(config);
  
  return {
    data: data || null,
    error,
  };
};