import React, { useEffect, useRef, useState, useContext, useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card, CardHeader, CardBody, CardGroup } from '../../components/card/card.jsx';
import Select from 'react-select';
import { getGenericData } from '../../api/generic-get.js';
import { postGenericData } from '../../api/generic-post.js';
import { useFormik } from 'formik';
import { Tooltip } from "react-tooltip";
import { Modal } from 'bootstrap';
import debounce from 'lodash.debounce';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AppSettings } from '../../config/app-settings.js';
import { LeaveLobbyForm } from './hooks/leave_lobby_form.js';
import { JoinLobbyForm } from './hooks/join_lobby_form.js';
import { KickFromLobbyForm } from './hooks/kick_from_lobby_form.js';
import { SendInviteForm } from './hooks/send_invite_form.js';
import { SendFriendRequestForm } from '../friendship/hooks/send_friend_request_form.js';
import { BanPlayerForm } from '../banned-players/hooks/ban_player_form.js';
import AlertTransition from '../../features/alert_success_transitions.js';
import { LobbyUserTooltip } from './components/lobby_tooltip.js';
import { LobbyUserLink } from './components/lobby_tooltip.js';
import useSocketConnection from '../../features/use_socket_connection.js';


const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
};

function Lobby() {

    // Handling template context
    const context = useContext(AppSettings);

    useEffect(() => {
        context.setAppContentClass('p-1');
        context.setAppContentFullHeight(true);

        return function cleanUp() {
            context.setAppContentClass('');
            context.setAppContentFullHeight(false);
        };

        // eslint-disable-next-line
    }, []);
    // END - Handling template context

    // Handling global settings
    const { current_user_id } = useSelector(state => ({
        current_user_id: state.auth.id,
    }), shallowEqual);

    const backend_endpoint = '/api/lobby';
    const backend_endpoint_friendship = '/api/friendship';
    // END - Handling global settings

    // SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
    const [errMsg, setErrMsg] = useState({});
    const [ackMsg, setAckMsg] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    // END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...


    // POST - Search Criteria
    const isInitializedRef = useRef(false);

    const [showTooltips, setShowTooltips] = useState(false); // State to control tooltip visibility

    // POST - Create lobby
    const [isEditMode, setIsEditMode] = useState(false); // Track if we're in edit mode
    const [currentLobby, setCurrentLobby] = useState(null); // Store current rendezvous data
    const modalRef = useRef(null);

    const [tooltipContent, setTooltipContent] = useState({
        lobby_name: '',
        mode: '',
        rank: '',
        size: '',
        kd: '',
        lang: ''
    });

    const modeOptions = [
        { value: 'BR', label: 'Battle Royale' },
        { value: 'RES', label: 'Resurgence' },
        { value: 'RANKWZ', label: 'Ranked Warzone' },
        { value: 'RANKMW', label: 'Ranked Multiplayer' },
        { value: 'MW', label: 'Multiplayer' },
        { value: 'DMZ', label: 'DMZ' },
        { value: 'ZOMBIES', label: 'ZOMBIES' }
    ];


    // Define sizeOptions for BR/RES and MW modes
    const sizeOptionsBR_RES = [
        { value: '2', label: 'DUOS' },
        { value: '3', label: 'TRIOS' },
        { value: '4', label: 'QUADS' }
    ];

    const sizeOptionsMW = [
        { value: '2', label: 'DUOS' },
        { value: '3', label: 'TRIOS' },
        { value: '4', label: 'QUADS' },
        { value: '5', label: 'FIVES' },
        { value: '6', label: 'SIXES' },
        { value: '7', label: 'SEVENS' },
        { value: '8', label: 'EIGHTS' },
        { value: '9', label: 'NINES' },
        { value: '10', label: 'TENS' }
    ];

    const rankOptions = [
        { value: 'B1_B3', label: 'B1 - B3' },
        { value: 'S1_S3', label: 'S1 - S3' },
        { value: 'G1_G3', label: 'G1 - G3' },
        { value: 'P1_P3', label: 'P1 - P3' },
        { value: 'D1_D3', label: 'D1 - D3' },
        { value: 'C1_C3', label: 'C1 - C3' },
        { value: 'IRI', label: 'IRIDESCENT' }
    ];

    const kdOptions = [
        { value: '0.50-0.75', label: '0.50-0.75' },
        { value: '0.76-1.00', label: '0.76-1.00' },
        { value: '1.01-1.25', label: '1.01-1.25' },
        { value: '1.26-1.50', label: '1.26-1.50' },
        { value: '1.51-1.75', label: '1.51-1.75' },
        { value: '1.76-2.00', label: '1.76-2.00' },
        { value: '2.01-2.99', label: '2.01-2.99' },
        { value: '3.00-5.00', label: '3.00-5.00' }
    ];

    const langOptions = [
        { value: 'ENG', label: 'ENG' },
        { value: 'PL', label: 'PL' },
        { value: 'DE', label: 'DE' },
        { value: 'FR', label: 'FR' },
        { value: 'ES', label: 'ES' },
        { value: 'IT', label: 'IT' },
        { value: 'CS', label: 'CS' },
        { value: 'SV', label: 'SV' },
        { value: 'NO', label: 'NO' },
        { value: 'SE', label: 'SE' },
        { value: 'NL', label: 'NL' }
    ];


    // Formik hook
    const form_create_lobby = useFormik({
        initialValues: {
            lobby_name: '',
            mode: '',
            rank: '',
            size: '',
            kd: '',
            lang: ''
        },
        validate: values => {
            const errors = {};

            // Validation for lobby name
            if (!values.lobby_name) {
                errors.lobby_name = 'Lobby name is required';
            } else if (values.lobby_name.length < 12 || values.lobby_name.length > 30) {
                errors.lobby_name = 'Lobby name must be between 12 and 30 characters';
            } else if (!/^[a-zA-Z0-9_?\-!+=_#. ]+$/.test(values.lobby_name)) {
                errors.lobby_name = 'Only alphabetical characters, numbers, and special characters (_, -, ?, !, +, =,  , and #) are allowed';
            }

            // Validation for mode
            if (!values.mode) {
                errors.mode = 'Mode is required';
            } else {
                const validModeOptions = ['BR', 'RES', 'RANKWZ', 'RANKMW', 'MW', 'DMZ', 'ZOMBIES'];
                if (!validModeOptions.includes(values.mode)) {
                    errors.mode = 'Invalid MODE selection!';
                }
            }

            // Validate size based on mode
            if (['BR', 'RES'].includes(values.mode)) {
                if (!['2', '3', '4'].includes(values.size)) {
                    errors.size = 'Invalid size selection for BR/RES modes';
                }
            } else if (values.mode === 'MW') {
                if (!['2', '3', '4', '5', '6', '7', '8', '9', '10'].includes(values.size)) {
                    errors.size = 'Invalid size selection for MW mode';
                }
            }

            // Validate rank if mode is RANKWZ or RANKMW
            if (['RANKWZ', 'RANKMW'].includes(values.mode) && !values.rank) {
                errors.rank = 'Rank is required';
            } else if (values.rank) {
                const validRankOptions = ['B1_B3', 'S1_S3', 'G1_G3', 'P1_P3', 'D1_D3', 'C1_C3', 'IRI'];
                if (!validRankOptions.includes(values.rank)) {
                    errors.rank = 'Invalid RANK selection!';
                }
            }

            // Validation for KD
            if (!values.kd) {
                errors.kd = 'KD is required';
            } else {
                const validKdOptions = ['0.50-0.75', '0.76-1.00', '1.01-1.25', '1.26-1.50', '1.51-1.75', '1.76-2.00', '2.01-2.99', '3.00-5.00'];
                if (!validKdOptions.includes(values.kd)) {
                    errors.kd = 'Invalid KD selection!';
                }
            }

            // Validation for language
            if (!values.lang) {
                errors.lang = 'Language is required';
            } else {
                const validLangOptions = ['PL', 'ENG', 'DE', 'FR', 'IT', 'ES', 'CS', 'SV', 'NO', 'NL', 'SE'];
                if (!validLangOptions.includes(values.lang)) {
                    errors.lang = 'Invalid language selection!';
                }
            }

            return errors;
        },
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const { lobby_name, mode, rank, size, kd, lang } = values;
                const formIdentifier = isEditMode ? 'edit_lobby' : 'create_lobby';
                // const payloadSubmission = { formIdentifier, lobby_name, mode, rank, size, kd, lang };
                const payloadSubmission = {
                    formIdentifier,
                    lobby_name,
                    mode,
                    rank,
                    size, // Send size as a numeric string
                    kd,
                    lang
                };

                if (isEditMode && currentLobby) {
                    payloadSubmission.lobby_id = currentLobby.lobby.id; // Include ID for editing
                }

                const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
                if (data) {
                    if (modalInstance) {
                        modalInstance.hide();
                    }
                    setIsEditMode(false);
                    setCurrentLobby(null);
                    setAckMsg(data);
                    myLobbyData();
                }
                if (error) {
                    setErrMsg(error);
                }
            } catch (error) {
                setErrMsg(error);
            } finally {
                setSubmitting(false);
            }
        }
    });


    // Inside your component
    const [modalInstance, setModalInstance] = useState(null);

    useEffect(() => {
        let instance = null;

        // Initialize the modal instance if the ref exists
        if (modalRef.current) {
            instance = new Modal(modalRef.current);
            setModalInstance(instance);
        }

        // Cleanup function to dispose of the modal instance when the component unmounts
        return () => {
            if (instance) {
                instance.dispose();
            }
        };
    }, []); // The empty dependency array ensures this runs only once after the component mounts

    const handleOpenModal = useCallback((myLobby = null) => {
        setIsEditMode(!!myLobby);
        setCurrentLobby(myLobby);

        if (myLobby) {
            // Pre-populate form with rendezvous data
            form_create_lobby.setValues({
                lobby_name: myLobby.lobby.name,
                mode: myLobby.lobby.mode,
                rank: myLobby.lobby.rank,
                size: myLobby.lobby.size,
                kd: myLobby.lobby.kd,
                lang: myLobby.lobby.lang
            });
        } else {
            // Reset form for adding new rendezvous
            form_create_lobby.resetForm();
        }

        if (modalInstance) {
            modalInstance.show();
        }

    }, [modalInstance, form_create_lobby]);

    const handleCloseModal = useCallback(() => {
        if (modalInstance) {
            modalInstance.hide();
        }

        form_create_lobby.resetForm();
        setTooltipContent({
            lobby_name: '',
            mode: '',
            rank: '',
            size: '',
            kd: '',
            lang: ''
        });
        setIsEditMode(false);
        setCurrentLobby(null);
    }, [modalInstance, form_create_lobby]);

    const handleFocus = (field) => {
        form_create_lobby.setFieldTouched(field, true);
    };

    useEffect(() => {
        setTooltipContent({
            lobby_name: form_create_lobby.errors.lobby_name || (form_create_lobby.touched.lobby_name && form_create_lobby.values.lobby_name ? 'Validation Passed' : ''),
            mode: form_create_lobby.errors.mode || (form_create_lobby.touched.mode && form_create_lobby.values.mode ? 'Validation Passed' : ''),
            rank: form_create_lobby.errors.rank || (form_create_lobby.touched.rank && form_create_lobby.values.rank ? 'Validation Passed' : ''),
            size: form_create_lobby.errors.size || (form_create_lobby.touched.size && form_create_lobby.values.size ? 'Validation Passed' : ''),
            kd: form_create_lobby.errors.kd || (form_create_lobby.touched.kd && form_create_lobby.values.kd ? 'Validation Passed' : ''),
            lang: form_create_lobby.errors.lang || (form_create_lobby.touched.lang && form_create_lobby.values.lang ? 'Validation Passed' : '')
        });
    }, [form_create_lobby.errors, form_create_lobby.touched, form_create_lobby.values]);

    // END - POST Create Lobby

    // GET - Fetch Lobby Data
    const [myLobby, setMyLobby] = useState(null);

    const myLobbyData = async () => {
        try {
            const queryParams = new URLSearchParams({
                my_lobby: true,
            });
            const url = `${backend_endpoint}?${queryParams.toString()}`;
            const { data, error } = await getGenericData(url);
            // if (data && Object.keys(data).length > 0 && !('message' in data)) {
            if (data && Object.keys(data).length > 0 && !data.message) {
                setMyLobby(data);
            } else {
                setMyLobby(null);
            }
            if (error) {
                setErrMsg(error);
            }
        } catch (error) {
            setErrMsg(error);
        }
    };

    useEffect(() => {
        myLobbyData();
    }, []);

    // END - GET - Fetch Lobby Data

    // GET - Find Lobbies
    const [findLobbies, setFindLobbies] = useState([]);
    const [activeModeFilter, setActiveModeFilter] = useState(null);

    const filteredAndSortedLobbies = useMemo(() => {
        let filtered = findLobbies || [];
        if (activeModeFilter) {
            filtered = filtered.filter(lobby => lobby.lobby.mode === activeModeFilter);
        }
        return filtered.sort((a, b) => parseFloat(b.lobby.kd) - parseFloat(a.lobby.kd));
    }, [findLobbies, activeModeFilter]);


    const findLobbiesData = async () => {
        try {
            const queryParams = new URLSearchParams({
                find_lobbies: true,
            });
            const url = `${backend_endpoint}?${queryParams.toString()}`;
            const { data, error } = await getGenericData(url);
            if (data && data.length > 0) {
                setFindLobbies(data);
            } else {
                setFindLobbies(null);
            }
            if (error) {
                setErrMsg(error);
            }
        } catch (error) {
            setErrMsg(error);
        }
    };
    // END - GET - Find Lobbies
    // END - GET

    // Data Mappings
    const getSizeText = (sizeValue) => {
        switch (sizeValue) {
            case 2:
            case '2':
                return 'DUOS';
            case 3:
            case '3':
                return 'TRIOS';
            case 4:
            case '4':
                return 'QUADS';
            case 5:
            case '5':
                return 'FIVES'
            case 6:
            case '6':
                return 'SIXES';
            case 7:
            case '7':
                return 'SEVENS';
            case 8:
            case '8':
                return 'EIGHTS';
            case 9:
            case '9':
                return 'NINES';
            case 10:
            case '10':
                return 'TENS';
            default:
                return ''; // Return an empty string or handle this case as needed
        }
    };
    // END - Data Mappings


    // Dynamically determine sizeOptions based on mode
    const getSizeOptionsByMode = (mode) => {
        if (['BR', 'RES'].includes(mode)) {
            return sizeOptionsBR_RES;
        } else if (mode === 'MW') {
            return sizeOptionsMW;
        }
        return [];
    };

    // STYLES
    // Select DropDown Visibility
    const customStyles = {
        menuPortal: base => ({
            ...base,
            zIndex: 10000, // Set the desired z-index value
        }),
    }
    // END - Select DropDown Visibility

    // SOCKET HANDLE
    const { isSocketConnected } = useSocketConnection(
        'lobbyupdates',
        'lobbyupdate',
        () => {
            myLobbyData();
            findLobbiesData();
        }
    );
    // END - SOCKETHANDLE

    const [touchedUsers, setTouchedUsers] = useState({});

    const handleTouchStart = (e, userId) => {
        if (isMobileDevice() && !touchedUsers[userId]) {
            e.preventDefault();
            setTouchedUsers({ ...touchedUsers, [userId]: true });

            // Reset the touch state after a short period
            setTimeout(() => {
                setTouchedUsers((prevState) => ({
                    ...prevState,
                    [userId]: false
                }));
            }, 50);
        }
    };

    const handleClickMobile = (e, userId) => {
        if (isMobileDevice()) {
            if (!touchedUsers[userId]) {
                e.preventDefault();
                setTouchedUsers({ ...touchedUsers, [userId]: true });

                // Reset the touch state after a short period
                setTimeout(() => {
                    setTouchedUsers((prevState) => ({
                        ...prevState,
                        [userId]: false
                    }));
                }, 50);
            } else {
                setTouchedUsers({ ...touchedUsers, [userId]: false });
            }
        }
    };

    // SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
    const combinedSuccess = { ...ackMsg };
    const combinedErrors = { ...errMsg };

    // Function to handle closing the alert
    const handleClose = () => {
        setShowAlert(false);
        setErrMsg({}); form_create_lobby.setErrors({});
    };
    const handleSuccessClose = () => {
        setShowSuccess(false);
        setAckMsg({});
    };

    // Debounce the function to avoid multiple updates
    const debouncedHandleClose = debounce(handleClose, 500);
    const debouncedHandleSuccessClose = debounce(handleSuccessClose, 500);

    useEffect(() => {
        let timer;
        // Handle error messages
        if (Object.keys(combinedErrors).length > 0) {
            setShowAlert(true);
            timer = setTimeout(() => {
                debouncedHandleClose();
            }, 5000);
        } else {
            setShowAlert(false);
        }

        // Handle success messages
        if (Object.keys(combinedSuccess).length > 0) {
            setShowSuccess(true);
            timer = setTimeout(() => {
                debouncedHandleSuccessClose();
            }, 5000);
        } else {
            setShowSuccess(false);
        }

        // Clean up function for both alerts
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
            debouncedHandleClose.cancel();
            debouncedHandleSuccessClose.cancel();
        };
    }, [combinedErrors, combinedSuccess]); // Depend on both state changes    
    // END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

    return (
        <PerfectScrollbar className="h-100 p-3">
            <div>
                <div>
                    <AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
                    <AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
                </div>
                <div id="select_search_criteria" className="mb-auto">
                    <h4>Lobby features</h4>
                    <p>Choose to either create your own lobby or find currently active lobbies.</p>
                    <Card>
                        <CardBody className="pb-auto">
                            <div className="row align-items-center">
                                <div className="col-sm-2">
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-outline-theme" data-bs-toggle="modal" data-bs-target="#modalCreateLobby" onClick={() => handleOpenModal(null)} disabled={!isSocketConnected}>
                                            CREATE LOBBY
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-auto">
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-outline-theme" onClick={() => findLobbiesData()}>
                                            FIND LOBBY
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div>
                    <hr className="mb-3" />
                    <div className="text-center"><strong>MY LOBBY</strong></div>
                    <hr className="mb-3" />
                </div>

                {myLobby && (
                    <div key={`my-lobby-${myLobby.lobby.id}`}>
                        <Card className="mt-2 mb-3">
                            <CardHeader className="d-flex align-items-center bg-white bg-opacity-15">
                                <table className="table table-card mb-0 mt-0 pb-0 pt-0 table-borderless">
                                    <thead>
                                        <tr className="border-top-0 mb-0 mt-0 pb-0 pt-0">
                                            <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">MODE: {myLobby.lobby.mode}</th>
                                            {(myLobby.lobby.mode === 'BR' || myLobby.lobby.mode === 'RES' || myLobby.lobby.mode === 'MW') && (
                                                <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">SIZE: {getSizeText(myLobby.lobby.size)}</th>
                                            )}
                                            {(myLobby.lobby.mode === 'RANKWZ' || myLobby.lobby.mode === 'RANKMW') && (
                                                <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">RANK: {myLobby.lobby.rank}</th>
                                            )}
                                            <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">KD: {myLobby.lobby.kd}</th>
                                            <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">LANG: {myLobby.lobby.lang}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </CardHeader>
                            <div className="list-group list-group-flush">
                                <div className="list-group-item px-3">
                                    <div className="text-white text-opacity-50 mb-2"><small><strong>NAME: </strong></small><strong className="bg-gray-900 text-success pb-1 ps-1 pe-1">{myLobby.lobby.name}</strong></div>
                                    {myLobby.lobby.mode === 'BR' && myLobby.lobby.size &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-indigo text-indigo">[BR {getSizeText(myLobby.lobby.size)}]</span></div></>
                                    }
                                    {myLobby.lobby.mode === 'RES' && myLobby.lobby.size &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-info text-info">[RES {getSizeText(myLobby.lobby.size)}]</span></div></>
                                    }
                                    {myLobby.lobby.mode === 'MW' && myLobby.lobby.size &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-primary text-primary">[MW {getSizeText(myLobby.lobby.size)}]</span></div></>
                                    }
                                    {myLobby.lobby.mode === 'RANKWZ' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-danger text-danger">[RANK WZ {myLobby.lobby.rank}]</span></div></>
                                    }
                                    {myLobby.lobby.mode === 'RANKMW' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-success text-success">[RANK MW {myLobby.lobby.rank}]</span></div></>
                                    }
                                    {myLobby.lobby.mode === 'DMZ' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-yellow text-yellow">[DMZ]</span></div></>
                                    }
                                    {myLobby.lobby.mode === 'ZOMBIES' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-gray-300 text-gray-300">[ZOMBIES]</span></div></>
                                    }
                                    <div className="text-white text-opacity-50 mb-2"><small><strong>OWNER: </strong></small><strong className="text-white pb-1 ps-1 pe-1">{myLobby.lobby.creator_username}</strong></div>

                                    <div className="justify-content-center">
                                        <CardBody>
                                            <CardGroup>
                                                {myLobby.users.filter(user => user.id === myLobby.lobby.creator_id).map((user, index) => (
                                                    <React.Fragment key={`creator-fragment-${user.id}`}>
                                                        <Card key={`creator-card-${user.id}`} className="m-1">
                                                            <LobbyUserLink key={`creator-link-${user.id}`} user={user} prefix="creator" handleTouchStart={handleTouchStart} handleClickMobile={handleClickMobile} />
                                                            {current_user_id === myLobby.lobby.creator_id ? (
                                                                null
                                                            ) : (
                                                                <div className="mb-0">
                                                                    <div className="btn-group btn-group-sm" style={{ display: 'block-ruby' }}> {/* ruby */}
                                                                        <SendFriendRequestForm
                                                                            user_id={user.id}
                                                                            users={myLobby.users}
                                                                            backend_endpoint={backend_endpoint_friendship}
                                                                            setAckMsg={setAckMsg}
                                                                            setErrMsg={setErrMsg}
                                                                            ButtonClass="btn btn-outline-secondary"
                                                                            ButtonText="ADD FRIEND" />
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                        </Card>
                                                        <LobbyUserTooltip key={`creator-tooltip-${user.id}`} user={user} prefix="creator" className="dark lobby-tooltip" place="top-start" />
                                                    </React.Fragment>
                                                ))}
                                                {myLobby.users.filter(user => user.id !== myLobby.lobby.creator_id).map((user, index) => (
                                                    <React.Fragment key={`user-fragment-${user.id}`}>
                                                        <Card key={`user-card-${user.id}`} className="m-1">
                                                            <LobbyUserLink key={`user-link-${user.id}`} user={user} prefix="user" handleTouchStart={handleTouchStart} handleClickMobile={handleClickMobile} />
                                                            {current_user_id === myLobby.lobby.creator_id ? (
                                                                <div className="mb-0">
                                                                    <div className="btn-group btn-group-sm" style={{ display: 'block-ruby' }}>
                                                                        <SendFriendRequestForm
                                                                            user_id={user.id}
                                                                            users={myLobby.users}
                                                                            backend_endpoint={backend_endpoint_friendship}
                                                                            setAckMsg={setAckMsg}
                                                                            setErrMsg={setErrMsg}
                                                                            ButtonClass="btn btn-outline-secondary"
                                                                            ButtonText="ADD FRIEND" />
                                                                        <SendInviteForm
                                                                            user_id={user.id}
                                                                            users={myLobby.users}
                                                                            backend_endpoint={backend_endpoint}
                                                                            setAckMsg={setAckMsg}
                                                                            setErrMsg={setErrMsg}
                                                                            ButtonClass="btn btn-outline-secondary"
                                                                            ButtonText="INVITE" />
                                                                        <KickFromLobbyForm
                                                                            user_id={user.id}
                                                                            users={myLobby.users}
                                                                            backend_endpoint={backend_endpoint}
                                                                            setAckMsg={setAckMsg}
                                                                            setErrMsg={setErrMsg}
                                                                            ButtonClass="btn btn-outline-secondary"
                                                                            ButtonText="KICK"
                                                                            disabled={!isSocketConnected} />
                                                                        <BanPlayerForm
                                                                            onBanSuccess={null}
                                                                            user_id={user.id}
                                                                            users={myLobby && myLobby.users}
                                                                            backend_endpoint={backend_endpoint}
                                                                            setAckMsg={setAckMsg}
                                                                            setErrMsg={setErrMsg}
                                                                            ButtonClass="btn btn-outline-secondary"
                                                                            ButtonText="BAN" />
                                                                    </div>
                                                                </div>
                                                            ) : current_user_id === user.id ? (
                                                                null
                                                            ) : (
                                                                <div className="mb-0">
                                                                    <div className="btn-group btn-group-sm" style={{ display: 'block-ruby' }}>
                                                                        <SendFriendRequestForm
                                                                            user_id={user.id}
                                                                            users={myLobby.users}
                                                                            backend_endpoint={backend_endpoint_friendship}
                                                                            setAckMsg={setAckMsg}
                                                                            setErrMsg={setErrMsg}
                                                                            ButtonClass="btn btn-outline-secondary"
                                                                            ButtonText="ADD FRIEND" />                                                                </div>
                                                                </div>
                                                            )
                                                            }
                                                        </Card>
                                                        <LobbyUserTooltip key={`user-tooltip-${user.id}`} user={user} prefix="user" className="dark lobby-tooltip" place="top-start" />
                                                    </React.Fragment>
                                                ))}
                                                {Array.from({ length: (myLobby.lobby.mode === 'RANKWZ' || myLobby.lobby.mode === 'DMZ' || myLobby.lobby.mode === 'ZOMBIES') ? 3 - myLobby.users.length : myLobby.lobby.mode === 'RANKMW' ? 4 - myLobby.users.length : myLobby.lobby.size - myLobby.users.length }).map((_, index) => (
                                                    <Card key={`empty-${index}`} className="m-1">
                                                        <a href="#/" className="list-group-item list-group-item-action d-flex align-items-center text-white text-break">
                                                            <div className="w-40px h-40px d-flex align-items-center justify-content-center ms-n1">
                                                                <img src="assets/img/user/user-5.jpg" alt="" className="ms-100 mh-100 rounded-circle" />
                                                            </div>
                                                            <div className="flex-fill ps-3">
                                                                <div className="fw-bold">
                                                                    [ FREE SLOT ]
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </Card>
                                                ))}

                                            </CardGroup>
                                            <div className="row align-items-center mt-3">
                                                <div className="col-sm-2">
                                                    <div className="mb-3">
                                                        <button type="button" className="btn btn-outline-theme" disabled={myLobby}>
                                                            JOIN
                                                        </button>
                                                    </div>
                                                </div>
                                                {myLobby.users.filter(user => user.id === myLobby.lobby.creator_id).map((user, index) => (
                                                    <React.Fragment key={`mylobby-edit-fragment-${user.id}`}>
                                                        {current_user_id === myLobby.lobby.creator_id ? (
                                                            <div className="col-sm-2">
                                                                <div className="mb-3">
                                                                    <button type="button" className="btn btn-outline-theme" onClick={() => handleOpenModal(myLobby)}>
                                                                        EDIT
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (null)
                                                        }
                                                    </React.Fragment>
                                                ))}
                                                <div className="col-md-auto">
                                                    <div className="mb-3">
                                                        <LeaveLobbyForm
                                                            lobby_id={myLobby.lobby.id}
                                                            backend_endpoint={backend_endpoint}
                                                            setAckMsg={setAckMsg}
                                                            setErrMsg={setErrMsg}
                                                            ButtonClass="btn btn-outline-theme"
                                                            ButtonText="LEAVE"
                                                            disabled={!isSocketConnected} />
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                )}

                <div>
                    <hr className="mb-3" />
                    <div className="text-center"><strong>FIND LOBBIES</strong></div>
                    <hr className="mb-3" />
                </div>

                <div className="mode-filters mb-3">
                    {['MW', 'BR', 'RES', 'RANKMW', 'RANKWZ', 'DMZ', 'ZOMBIES'].map(mode => (
                        <button
                            key={mode}
                            className={`btn btn-outline-secondary btn-sm m-1 ${mode === activeModeFilter ? 'active' : ''}`}
                            onClick={() => setActiveModeFilter(mode === activeModeFilter ? null : mode)}
                        >
                            {mode}
                        </button>
                    ))}
                    <button
                        className={`btn btn-outline-secondary btn-sm m-1 ${!activeModeFilter ? 'active' : ''}`}
                        onClick={() => setActiveModeFilter(null)}
                    >
                        Show All
                    </button>
                </div>

                {filteredAndSortedLobbies && filteredAndSortedLobbies.map((lobby, index) => (
                    <div key={`find-lobbies-${lobby.lobby.id}`}>
                        <Card className="mt-2 mb-3">
                            <CardHeader className="d-flex align-items-center bg-white bg-opacity-15">
                                <table className="table table-card mb-0 mt-0 pb-0 pt-0 table-borderless">
                                    <thead>
                                        <tr className="border-top-0 mb-0 mt-0 pb-0 pt-0">
                                            <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">MODE: {lobby.lobby.mode}</th>
                                            {(lobby.lobby.mode === 'BR' || lobby.lobby.mode === 'RES') && (
                                                <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">SIZE: {lobby.lobby.size}</th>
                                            )}
                                            {(lobby.lobby.mode === 'RANKWZ' || lobby.lobby.mode === 'RANKMW') && (
                                                <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">RANK: {lobby.lobby.rank}</th>
                                            )}
                                            <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">KD: {lobby.lobby.kd}</th>
                                            <th className="border-start-0 border-end-0 mb-0 mt-0 pb-0 pt-0">LANG: {lobby.lobby.lang}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </CardHeader>
                            <div className="list-group list-group-flush">
                                <div className="list-group-item px-3">
                                    <div className="text-white text-opacity-50 mb-2"><small><strong>NAME: </strong></small><strong className="bg-gray-900 text-success pb-1 ps-1 pe-1">{lobby.lobby.name}</strong></div>
                                    {lobby.lobby.mode === 'BR' && lobby.lobby.size &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-indigo text-indigo">[BR {getSizeText(lobby.lobby.size)}]</span></div></>
                                    }
                                    {lobby.lobby.mode === 'RES' && lobby.lobby.size &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-info text-info">[RES {getSizeText(lobby.lobby.size)}]</span></div></>
                                    }
                                    {lobby.lobby.mode === 'RANKWZ' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-danger text-danger">[RANK WZ {lobby.lobby.rank}]</span></div></>
                                    }
                                    {lobby.lobby.mode === 'RANKMW' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-success text-success">[RANK MW {lobby.lobby.rank}]</span></div></>
                                    }
                                    {lobby.lobby.mode === 'MW' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-primary text-primary">[MW {getSizeText(lobby.lobby.size)}]</span></div></>
                                    }
                                    {lobby.lobby.mode === 'DMZ' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-yellow text-yellow">[DMZ]</span></div></>
                                    }
                                    {lobby.lobby.mode === 'ZOMBIES' &&
                                        <><div className="text-white text-opacity-50 mb-2"><small><strong>MODE: </strong></small><span className="badge border border-gray-300 text-gray-300">[ZOMBIES]</span></div></>
                                    }
                                    <div className="text-white text-opacity-50 mb-2"><small><strong>OWNER: </strong></small><strong className="text-white pb-1 ps-1 pe-1">{lobby.lobby.creator_username}</strong></div>

                                    <div className="justify-content-center">
                                        <CardBody>
                                            <CardGroup>
                                                {lobby.users.filter(user => user.id === lobby.lobby.creator_id).map((user, index) => (
                                                    <React.Fragment key={`creator-fragment-${user.id}`}>
                                                        <Card key={`creator-card-${user.id}`} className="m-1">
                                                            <LobbyUserLink key={`creator-link-${user.id}`} user={user} prefix="creator" handleTouchStart={handleTouchStart} handleClickMobile={handleClickMobile} />
                                                        </Card>
                                                        <LobbyUserTooltip key={`creator-tooltip-${user.id}`} user={user} prefix="creator" className="dark lobby-tooltip" place="top-start" />
                                                    </React.Fragment>
                                                ))}
                                                {lobby.users.filter(user => user.id !== lobby.lobby.creator_id).map((user, index) => (
                                                    <React.Fragment key={`user-fragment-${user.id}`}>
                                                        <Card key={`user-card-${user.id}`} className="m-1">
                                                            <LobbyUserLink key={`user-link-${user.id}`} user={user} prefix="user" handleTouchStart={handleTouchStart} handleClickMobile={handleClickMobile} />
                                                        </Card>
                                                        <LobbyUserTooltip key={`user-tooltip-${user.id}`} user={user} prefix="user" className="dark lobby-tooltip" place="top-start" />
                                                    </React.Fragment>
                                                ))}
                                                {Array.from({ length: (lobby.lobby.mode === 'RANKWZ' || lobby.lobby.mode === 'DMZ' || lobby.lobby.mode === 'ZOMBIES') ? 3 - lobby.users.length : lobby.lobby.mode === 'RANKMW' ? 4 - lobby.users.length : lobby.lobby.size - lobby.users.length }).map((_, index) => (
                                                    <Card key={`empty-${index}`} className="m-1">
                                                        <a href="#/" className="list-group-item list-group-item-action d-flex align-items-center text-white text-break">
                                                            <div className="w-40px h-40px d-flex align-items-center justify-content-center ms-n1">
                                                                <img src="assets/img/user/user-5.jpg" alt="" className="ms-100 mh-100 rounded-circle" />
                                                            </div>
                                                            <div className="flex-fill ps-3">
                                                                <div className="fw-bold">
                                                                    [ FREE SLOT ]
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </Card>
                                                ))}
                                            </CardGroup>
                                            <div className="row align-items-center mt-3">
                                                <div className="col-sm-2">
                                                    <div className="mb-3">
                                                        <JoinLobbyForm
                                                            lobby_id={lobby.lobby.id}
                                                            lobbies={findLobbies}
                                                            backend_endpoint={backend_endpoint}
                                                            setAckMsg={setAckMsg}
                                                            setErrMsg={setErrMsg}
                                                            ButtonClass="btn btn-outline-theme"
                                                            ButtonText="JOIN"
                                                            disabled={
                                                                !isSocketConnected ||
                                                                (lobby.lobby.mode === 'RANKWZ' && 3 === lobby.users.length) ||
                                                                (lobby.lobby.mode === 'RANKMW' && 4 === lobby.users.length) ||
                                                                (lobby.lobby.size === lobby.users.length)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-auto">
                                                    <div className="mb-3">
                                                        <button type="button" className="btn btn-outline-theme" disabled={true}>
                                                            LEAVE
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                ))}

                {/* Modal Form - Start */}
                <div key="modalCreateLobby" id="modalCreateLobby" ref={modalRef} className={`modal fade`} style={{ display: 'none' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{isEditMode ? 'Edit Lobby' : 'Create Lobby'}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body">
                                <AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
                                <AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
                                <label htmlFor="lobby_name" className="form-label">Lobby Name </label>
                                <form onSubmit={form_create_lobby.handleSubmit}>

                                    {/* Name Field */}
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            placeholder="Lobby name"
                                            id="lobby_name"
                                            name="lobby_name"
                                            onChange={form_create_lobby.handleChange}
                                            value={form_create_lobby.values.lobby_name || ''}
                                            required
                                            onFocus={() => handleFocus('lobby_name')}
                                            onBlur={form_create_lobby.handleBlur}
                                            className="form-control"
                                            aria-invalid={form_create_lobby.errors.lobby_name ? "true" : "false"}
                                            aria-describedby="lobby_name"
                                            data-tooltip-variant={form_create_lobby.errors.lobby_name ? "error" : "dark"}
                                        />
                                        <Tooltip anchorSelect="#lobby_name" opacity={1} content={tooltipContent.lobby_name}
                                            variant={form_create_lobby.errors.lobby_name ? 'error' : 'dark'}
                                            place='right'
                                        />
                                    </div>
                                    <div className="alert alert-muted">
                                        <b>Please note: </b>
                                        Only alphabetical characters, numbers and special characters (_, -, ?, !, +, =, .,  , and #) are allowed.
                                    </div>

                                    {/* Mode Field */}
                                    <div className="mb-3">
                                        <label htmlFor="mode" className="form-label">Mode</label>
                                        <Select
                                            inputId="mode"
                                            name="mode"
                                            id="mode"
                                            options={modeOptions}
                                            value={modeOptions.find(option => option.value === form_create_lobby.values.mode) || null}
                                            onChange={option => form_create_lobby.setFieldValue('mode', option ? option.value : '')}
                                            classNamePrefix="react-select"
                                            onBlur={form_create_lobby.handleBlur}
                                            onFocus={() => handleFocus('mode')}
                                            aria-invalid={form_create_lobby.errors.mode ? "true" : "false"}
                                        />
                                        <Tooltip anchorSelect="#mode" opacity={1} content={tooltipContent.mode}
                                            variant={form_create_lobby.errors.mode ? 'error' : 'dark'}
                                            place='right'
                                        />
                                    </div>

                                    {/* Rank Field */}
                                    {['RANKWZ', 'RANKMW'].includes(form_create_lobby.values.mode) && (
                                        <div className="mb-3">
                                            <label htmlFor="rank" className="form-label">Rank</label>
                                            <Select
                                                inputId="rank"
                                                name="rank"
                                                id="rank"
                                                options={rankOptions}
                                                value={rankOptions.find(option => option.value === form_create_lobby.values.rank) || null}
                                                onChange={option => form_create_lobby.setFieldValue('rank', option ? option.value : '')}
                                                classNamePrefix="react-select"
                                                onBlur={form_create_lobby.handleBlur}
                                                onFocus={() => handleFocus('rank')}
                                                aria-invalid={form_create_lobby.errors.rank ? "true" : "false"}
                                            />
                                            <Tooltip anchorSelect="#rank" opacity={1} content={tooltipContent.rank}
                                                variant={form_create_lobby.errors.rank ? 'error' : 'dark'}
                                                place='right'
                                            />
                                        </div>
                                    )}

                                    {/* Size Field */}
                                    {['BR', 'RES', 'MW'].includes(form_create_lobby.values.mode) && (
                                        <div className="mb-3">
                                            <label htmlFor="size" className="form-label">Size</label>
                                            <Select
                                                inputId="size"
                                                name="size"
                                                id="size"
                                                options={getSizeOptionsByMode(form_create_lobby.values.mode)}
                                                value={getSizeOptionsByMode(form_create_lobby.values.mode).find(option => option.value === form_create_lobby.values.size) || null}
                                                onChange={option => form_create_lobby.setFieldValue('size', option ? option.value : '')}
                                                classNamePrefix="react-select"
                                                onBlur={form_create_lobby.handleBlur}
                                                onFocus={() => handleFocus('size')}
                                                aria-invalid={form_create_lobby.errors.size ? "true" : "false"}
                                            />
                                            <Tooltip anchorSelect="#size" opacity={1} content={tooltipContent.size}
                                                variant={form_create_lobby.errors.size ? 'error' : 'dark'}
                                                place='right'
                                            />
                                        </div>
                                    )}

                                    {/* KD Field */}
                                    <div className="mb-3">
                                        <label htmlFor="kd" className="form-label">KD</label>
                                        <Select
                                            inputId="kd"
                                            name="kd"
                                            id="kd"
                                            options={kdOptions}
                                            value={kdOptions.find(option => option.value === form_create_lobby.values.kd) || null}
                                            onChange={option => form_create_lobby.setFieldValue('kd', option ? option.value : '')}
                                            classNamePrefix="react-select"
                                            onBlur={form_create_lobby.handleBlur}
                                            onFocus={() => handleFocus('kd')}
                                            aria-invalid={form_create_lobby.errors.kd ? "true" : "false"}
                                        />
                                        <Tooltip anchorSelect="#kd" opacity={1} content={tooltipContent.kd}
                                            variant={form_create_lobby.errors.kd ? 'error' : 'dark'}
                                            place='right'
                                        />
                                    </div>

                                    {/* Language Field */}
                                    <div className="mb-3">
                                        <label htmlFor="lang" className="form-label">Language</label>
                                        <Select
                                            inputId="lang"
                                            name="lang"
                                            id="lang"
                                            options={langOptions}
                                            value={langOptions.find(option => option.value === form_create_lobby.values.lang) || null}
                                            onChange={option => form_create_lobby.setFieldValue('lang', option ? option.value : '')}
                                            classNamePrefix="react-select"
                                            onBlur={form_create_lobby.handleBlur}
                                            onFocus={() => handleFocus('lang')}
                                            aria-invalid={form_create_lobby.errors.lang ? "true" : "false"}
                                        />
                                        <Tooltip anchorSelect="#lang" opacity={1} content={tooltipContent.lang}
                                            variant={form_create_lobby.errors.lang ? 'error' : 'dark'}
                                            place='right'
                                        />
                                    </div>


                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-default" onClick={handleCloseModal} data-bs-dismiss="modal">Close</button>
                                        <button type="submit" className="btn btn-outline-theme" disabled={form_create_lobby.isSubmitting || !form_create_lobby.isValid}>
                                            {isEditMode ? 'UPDATE' : 'CREATE LOBBY'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Form - END */}
                <div>
                    <br>
                    </br>
                    <br>
                    </br>
                </div>

            </div>
        </PerfectScrollbar>

    );
}

export default Lobby;