import React, { useEffect, useContext, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AppSettings } from '../../config/app-settings.js';
import useCsrfToken from '../../api/csrf_token.js';
import { getGenericData } from '../../api/generic-get.js';
import debounce from 'lodash.debounce';
import AlertTransition from '../../features/alert_success_transitions.js';
import { Card, CardBody } from '../../components/card/card.jsx';
import { SendInviteLinkForm } from '../lobby/hooks/send_invite_link_form.js';
import { SendFriendRequestForm } from '../friendship/hooks/send_friend_request_form.js';
import ReactDOM from 'react-dom/client';

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css';
import 'datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');
require('datatables.net-bs5');
require('datatables.net-buttons');
require('datatables.net-buttons/js/buttons.colVis.min.js');
require('datatables.net-buttons/js/buttons.html5.min.js');
require('datatables.net-buttons/js/buttons.print.min.js');
require('datatables.net-buttons-bs5');
require('datatables.net-responsive');
require('datatables.net-responsive-bs5');
require('datatables.net-fixedcolumns');
require('datatables.net-fixedcolumns-bs5');

const rootsMap = new Map();

const createOrUpdateRoot = (container, component, rootsMap) => {
    if (!rootsMap.has(container)) {
        const root = ReactDOM.createRoot(container);
        root.render(component);
        rootsMap.set(container, root);
    } else {
        const root = rootsMap.get(container);
        root.render(component);
    }
};

const renderPlayersButtons = (player, players, csrfToken, backend_endpoint_lobby, backend_endpoint_friendship, rootsMap, setAckMsg, setErrMsg) => {
    const sendFriendRequestContainer = document.getElementById(`send-friend-request-form-container-${player.id}`);
    const sendInviteContainer = document.getElementById(`send-invite-form-container-${player.id}`);
    const sendFriendRequestContainerResponsive = document.getElementById(`send-friend-request-form-container-responsive-${player.id}`);
    const sendInviteContainerResponsive = document.getElementById(`send-invite-form-container-responsive-${player.id}`);

    if (sendFriendRequestContainer) {
        createOrUpdateRoot(sendFriendRequestContainer, (
            <SendFriendRequestForm
                user_id={player.id}
                users={players}
                csrfToken={csrfToken}
                backend_endpoint={backend_endpoint_friendship}
                setAckMsg={setAckMsg}
                setErrMsg={setErrMsg}
                ButtonClass="btn btn-sm btn-outline-danger"
                ButtonText="ADD FRIEND"
            />
        ), rootsMap);
    }

    if (sendInviteContainer) {
        createOrUpdateRoot(sendInviteContainer, (
            <SendInviteLinkForm
                user_id={player.id}
                users={players}
                csrfToken={csrfToken}
                backend_endpoint={backend_endpoint_lobby}
                setAckMsg={setAckMsg}
                setErrMsg={setErrMsg}
                ButtonClass="btn btn-sm btn-outline-success"
                ButtonText="INVITE TO LOBBY" />
        ), rootsMap);
    }

    if (sendFriendRequestContainerResponsive) {
        createOrUpdateRoot(sendFriendRequestContainerResponsive, (
            <SendFriendRequestForm
                user_id={player.id}
                users={players}
                csrfToken={csrfToken}
                backend_endpoint={backend_endpoint_friendship}
                setAckMsg={setAckMsg}
                setErrMsg={setErrMsg}
                ButtonClass="btn btn-sm btn-outline-danger button-size-small"
                ButtonText="ADD FRIEND"
            />
        ), rootsMap);
    }

    if (sendInviteContainerResponsive) {
        createOrUpdateRoot(sendInviteContainerResponsive, (
            <SendInviteLinkForm
                user_id={player.id}
                users={players}
                csrfToken={csrfToken}
                backend_endpoint={backend_endpoint_lobby}
                setAckMsg={setAckMsg}
                setErrMsg={setErrMsg}
                ButtonClass="btn btn-sm btn-outline-success button-size-small"
                ButtonText="INVITE TO LOBBY" />
        ), rootsMap);
    }
};

const observeAndRenderResponsiveButtons = (player, players, csrfToken, backend_endpoint_lobby, backend_endpoint_friendship, rootsMap, setAckMsg, setErrMsg) => {
    const observer = new MutationObserver((mutations, obs) => {
        mutations.forEach(mutation => {
            if (mutation.type === 'childList') {
                const sendFriendRequestContainerResponsive = document.getElementById(`send-friend-request-form-container-responsive-${player.id}`);
                const sendInviteContainerResponsive = document.getElementById(`send-invite-form-container-responsive-${player.id}`);

                if (sendFriendRequestContainerResponsive && sendInviteContainerResponsive) {
                    renderPlayersButtons(player, players, csrfToken, backend_endpoint_lobby, backend_endpoint_friendship, rootsMap, setAckMsg, setErrMsg);
                    //obs.disconnect(); // Stop observing once elements are found
                }
            }
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
};


function Players() {

    // Handling global settings
    const backend_endpoint = '/api/players';
    const backend_endpoint_lobby = '/api/lobby';
    const backend_endpoint_friendship = '/api/friendship';
    const csrfToken = useCsrfToken();
    // END - Handling global settings

    // Handling template context
    const context = useContext(AppSettings);

    useEffect(() => {
        context.setAppContentClass('p-1');
        context.setAppContentFullHeight(true);

        return function cleanUp() {
            context.setAppContentClass('');
            context.setAppContentFullHeight(false);
        };

        // eslint-disable-next-line
    }, []);
    // END - Handling template context

    // GET

    const [players, setPlayers] = useState([]);
    const [dataTable, setDataTable] = useState(null);

    const getPlayersData = async () => {
        try {
            const queryParams = new URLSearchParams({
                get_players: true,
            });
            const url = `${backend_endpoint}?${queryParams.toString()}`;
            const { data, error } = await getGenericData(url);

            if (data && Object.keys(data).length > 0 && !data.message) {
                setPlayers(data.players || []);
            } else {
                setPlayers([]);
            }

            if (error) {
                setErrMsg(error);
            }
        } catch (error) {
            setErrMsg(error);
        }
    };

    useEffect(() => {
        getPlayersData();
    }, []);
    // END - GET

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#datatablePlayers')) {
            $('#datatablePlayers').DataTable().clear().destroy();
        }

        const table = $('#datatablePlayers').DataTable({
            dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 'i><'mt-2 mb-0 col-md-6'p>>",
            lengthMenu: [10, 20, 30, 40, 50],
            autoWidth: false, // Disable automatic column width calculation
            scrollX: false, // Enable horizontal scrolling
            responsive: {
                details: {
                    renderer: function (api, rowIdx, columns) {
                        const data = $.map(columns, function (col) {
                            return col.hidden ?
                                `<tr data-dt-row="${col.rowIndex}" data-dt-column="${col.columnIndex}" class="text-wrap">
                                        <td>${col.title}:</td>
                                        <td>${col.data}</td>
                                    </tr>` :
                                '';
                        }).join('');

                        const rowData = api.row(rowIdx).data();
                        const actionCell = `
                                <div style="display: inline-flex; gap: 5px; margin-top: 2px;">
                                    <div id="send-friend-request-form-container-responsive-${rowData.id}"></div>
                                    <div id="send-invite-form-container-responsive-${rowData.id}"></div>
                                </div>
                            `;

                        return data ?
                            `<table>${data}</table><div>${actionCell}</div>` :
                            false;
                    }
                }
            },
            data: players,
            buttons: [],
            columns: [
                {
                    data: null,
                    title: '#',
                    render: (data, type, row, meta) => {
                        return meta.row + 1; // Display index as 1-based
                    }, width: '5%'
                },
                { data: 'atvi_id', title: 'ATVI ID', width: '30%', className: 'text-break' },
                { data: 'rank', title: 'RANK', width: '10%', className: 'text-nowrap' },
                { data: 'mw_kd', title: 'MW KD', width: '10%', className: 'text-nowrap' },
                { data: 'br_kd', title: 'BR KD', width: '10%', className: 'text-nowrap' },
                { data: 'res_kd', title: 'RES KD', width: '10%', className: 'text-nowrap' },
                { data: 'rank_kd', title: 'RANK KD', width: '10%', className: 'text-nowrap' },
                {
                    data: null,
                    title: 'Actions',
                    render: (data, type, row) => {
                        return `
                                <div style="display: inline-flex; gap: 5px;">
                                    <div id="send-friend-request-form-container-${row.id}"></div>
                                    <div id="send-invite-form-container-${row.id}"></div>
                                </div>
                            `;
                    }, width: '15%', className: 'text-nowrap dt-body-left'
                }
            ],
            drawCallback: function () {
                players.forEach(player => {
                    renderPlayersButtons(player, players, csrfToken, backend_endpoint_lobby, backend_endpoint_friendship, rootsMap, setAckMsg, setErrMsg);
                    observeAndRenderResponsiveButtons(player, players, csrfToken, backend_endpoint_lobby, backend_endpoint_friendship, rootsMap, setAckMsg, setErrMsg);
                });
            }
        });

        setDataTable(table);

        return function cleanUp() {
            if (dataTable) {
                dataTable.destroy(); // Properly destroy the DataTable
                setDataTable(null);  // Reset state after destroying
            }
        };
        

    }, [players]);

    useEffect(() => {
        const handleResize = debounce(() => {
            if (dataTable) {
                dataTable.columns.adjust().responsive.recalc();
            }
        }, 300); // Use debounce to avoid firing the function multiple times rapidly
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
            handleResize.cancel(); // Clean up debounce function
        };
    }, [dataTable]);

    useEffect(() => {
        const handleMenuToggle = () => {
            // Wait for the menu animation or CSS change, then trigger the table adjustment
            setTimeout(() => {
                if (dataTable) {
                    dataTable.columns.adjust().responsive.recalc();
                }
            }, 300); // Adjust the delay based on your menu animation timing
        };
    
        // Select the menu toggle button
        const menuToggleButton = document.querySelector('.menu-toggler');
        
        if (menuToggleButton) {
            menuToggleButton.addEventListener('click', handleMenuToggle);
        }
    
        return () => {
            if (menuToggleButton) {
                menuToggleButton.removeEventListener('click', handleMenuToggle);
            }
        };
    }, [dataTable]);
    
    // SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
    const [errMsg, setErrMsg] = useState({});
    const [ackMsg, setAckMsg] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    // END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...

    // SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...
    const combinedSuccess = { ...ackMsg };
    const combinedErrors = { ...errMsg };

    // Function to handle closing the alert
    const handleClose = () => {
        setShowAlert(false);
        setErrMsg({});
    };
    const handleSuccessClose = () => {
        setShowSuccess(false);
        setAckMsg({});
    };

    // Debounce the function to avoid multiple updates
    const debouncedHandleClose = debounce(handleClose, 500);
    const debouncedHandleSuccessClose = debounce(handleSuccessClose, 500);

    useEffect(() => {
        let timer;
        // Handle error messages
        if (Object.keys(combinedErrors).length > 0) {
            setShowAlert(true);
            timer = setTimeout(() => {
                debouncedHandleClose();
            }, 5000);
        } else {
            setShowAlert(false);
        }

        // Handle success messages
        if (Object.keys(combinedSuccess).length > 0) {
            setShowSuccess(true);
            timer = setTimeout(() => {
                debouncedHandleSuccessClose();
            }, 5000);
        } else {
            setShowSuccess(false);
        }

        // Clean up function for both alerts
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
            debouncedHandleClose.cancel();
            debouncedHandleSuccessClose.cancel();
        };
    }, [combinedErrors, combinedSuccess]); // Depend on both state changes    
    // END SUCCESS/ERROR - Handle Success and Errors from backend responses, Formik Forms, etc...


    return (
        <PerfectScrollbar className="h-100 p-3">
            <div>
                <div>
                    <AlertTransition show={showAlert} type="danger" messageMap={combinedErrors} onClose={handleClose} />
                    <AlertTransition show={showSuccess} type="success" messageMap={combinedSuccess} onClose={handleSuccessClose} />
                </div>
                <div id="datatable" className="mb-auto">
                    <h4>Players Leaderboard</h4>
                    <p>Below is a list of all our players.</p>
                    <Card>
                        <CardBody className="pb-auto">
                            <table id="datatablePlayers" className="table w-100 text-wrap">
                                <thead>
                                    <tr>
                                        <th className="">#</th>
                                        <th className="">ATVI ID</th>
                                        <th className="">RANK</th>
                                        <th className="">MW KD</th>
                                        <th className="">BR KD</th>
                                        <th className="">RES KD</th>
                                        <th className="">RANK KD</th>
                                        <th className="">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </PerfectScrollbar>
    )
}

export default Players;
