import React from 'react';
import App from './../app.jsx';
import { Navigate } from 'react-router-dom';
import PagesError from '../pages/error/error.js';
import NoPermission from '../pages/no-permission/no-permission.js';
import PrivateRoute from '../composables/privateRoute.js';
import PersistLogin from '../composables/persistLogin.js';
import PagesLogin from './../pages/auth/login.js';
import PagesRegister from './../pages/auth/register.js';
import PagesReset from './../pages/auth/reset.js';
import PagesRecover from './../pages/auth/recover.js';
import Dashboard from '../pages/dashboard/dashboard.js';
import Profile from '../pages/profile/profile.js';
import Tournaments from '../pages/tournaments/tournaments.js';
import Schedule from '../pages/schedule/schedule.js';
import Map from '../pages/map/map.js';
import Loadouts from '../pages/loadouts/loadouts.js';
import Lobby from '../pages/lobby/lobby.js';
import Settings from '../pages/settings/settings.js';
import Admin from '../pages/admin/admin.js';
import Faq from '../pages/intel/intel.js';
import RecentPlayers from '../pages/recent-players/recent-players.js';
import Friendship from '../pages/friendship/firendship.js';
import BannedPlayers from '../pages/banned-players/banned-players.js';
import Notifications from '../pages/notifications/notifications.js';
import Rendezvous from '../pages/rendezvous/rendezvous.js';
import Players from '../pages/players/players.js';
import TermsOfUse from '../pages/legal/terms-of-use.js';
import PrivacyPolicy from '../pages/legal/privacy-policy.js';
import Intel from '../pages/intel/intel.js';

const allowedRoles = {
  guest: "guest",
  user: "users",
  moderator: "moderators",
  admin: "admins"
}

const AppRoute = [
  {
    path: '*',
    element: <PersistLogin Component={App} />,
    children: [
      { path: '', element: <Navigate to='/dashboard' /> },
      // change the above from default dashboard to auth/login to break password reset/recover cycle from no-permissions
      // actually after reset it sets user hard on no-permissions and go-back button does not work
      // after recovery user is however authorised to access dashboard
      //{ path: '', element: <PersistLogin /> },
      { path: 'dashboard', element: <PrivateRoute Component={Dashboard} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      {
        path: 'auth/*',
        children: [
          { path: 'login', element: <PagesLogin /> },
          { path: 'register', element: <PagesRegister /> },
          { path: 'reset', element: <PagesReset /> },
          { path: 'recover', element: <PagesRecover /> },
        ]
      },
      { path: 'profile/:userId?', element: <PrivateRoute Component={Profile} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'tournaments', element: <PrivateRoute Component={Tournaments} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'schedule', element: <PrivateRoute Component={Schedule} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'map', element: <PrivateRoute Component={Map} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'loadouts', element: <PrivateRoute Component={Loadouts} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'players', element: <PrivateRoute Component={Players} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'rendezvous', element: <PrivateRoute Component={Rendezvous} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'lobby', element: <PrivateRoute Component={Lobby} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'recent-players', element: <PrivateRoute Component={RecentPlayers} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'fireteam', element: <PrivateRoute Component={Friendship} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'banned-players', element: <PrivateRoute Component={BannedPlayers} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'notifications', element: <PrivateRoute Component={Notifications} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'settings', element: <PrivateRoute Component={Settings} role={[allowedRoles.user, allowedRoles.moderator, allowedRoles.admin]} /> },
      { path: 'admin', element: <PrivateRoute Component={Admin} role={[allowedRoles.admin]} /> },
      {
        path: 'management/*',
        children: [
          { path: 'tnts/battleroyale', element: <PrivateRoute Component={Tournaments} role={[allowedRoles.moderator, allowedRoles.admin]} /> },
          { path: 'tnts/resurgence', element: <PrivateRoute Component={Tournaments} role={[allowedRoles.moderator, allowedRoles.admin]} /> }
        ]
      },
      { path: 'intel', element: <Intel /> },
      {
        path: 'legal/*',
        children: [
          { path: 'terms-of-use', element: <TermsOfUse /> },
          { path: 'privacy-policy', element: <PrivacyPolicy /> },
        ]
      },
      { path: 'no-permission', element: <NoPermission /> },
      { path: '*', element: <PagesError /> }
    ]
  }
];

export default AppRoute;