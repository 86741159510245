import axios from "axios";

import { store } from "../store.js";
import { logout } from "../features/auth/authActions.js";

const axiosInstance = axios.create({
  baseURL: 'https://warfare.zone',
  uploads: 'user-data',
  withCredentials: true,
});

// Create a context or use a state management solution (like Redux) to store the token
let csrfToken = '';

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Capture the CSRF token from the response headers
    const token = response.headers['x-csrf-token'] || response.headers['X-Csrf-Token'] || response.headers['X-CSRF-Token'];
    if (token) {
      csrfToken = token;
      //console.log(token);
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect user to login page and dispatch logout
      store.dispatch(logout()); // Make sure the logout function clears all relevant data
    }
    return Promise.reject(error); // Return any other error which is not 401
  }
);

export const callExternalApi = async (options) => {
  try {
    const { baseURL } = axiosInstance.defaults;
    const url = `${axiosInstance.defaults.baseURL}${options.url}`;

    const response = await axiosInstance({ ...options, url });
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    let message = "HTTP request failed";

    if (axios.isAxiosError(error) && error.response) {
      // Use interceptor to check for 401 Unauthorized error - force login if session token expires

      // Improved error message extraction
      if (error.response.data && error.response.data.response && error.response.data.response.errors) {
        // Specifically targeting the nested structure of errors
        const errors = error.response.data.response.errors;
        if (Array.isArray(errors) && errors.length > 0) {
          message = errors.join(', ');
        }
      } else if (error.response.data && error.response.data.message) {
        // A fallback to a potential "message" key in the error response
        message = error.response.data.message;
      } else if (error.response.statusText) {
        // Use statusText if no detailed error message is available
        message = error.response.statusText;
      } else if (error.message) {
        // Lastly, fallback to the generic error message from Axios
        message = error.message;
      }
    } else if (error instanceof Error) {
      // Non-Axios errors (e.g., network issues, timeouts)
      message = error.message;
    }

    console.log(message); // Logging the error message for debugging purposes

    return {
      data: null,
      error: {
        message,
      },
    };
  }
};

export default axiosInstance;
export const getCsrfToken = () => csrfToken;
