import React, { useEffect, useContext } from 'react';
import { AppSettings } from './../../config/app-settings.js';

function Admin() {
	// Handling template context
	const context = useContext(AppSettings);

	useEffect(() => {
		context.setAppContentClass('p-3');
		context.setAppContentFullHeight(true);

		return function cleanUp() {
			context.setAppContentClass('');
			context.setAppContentFullHeight(false);
		};

		// eslint-disable-next-line
	}, []);
	// END - Handling template context  

	return (
		<div></div>
	)
}

export default Admin;