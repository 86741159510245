import React, { useEffect, useState, useContext } from 'react';
import { Card, CardHeader, CardBody } from './../../components/card/card.jsx';
import 'lity';
import 'lity/dist/lity.min.css';
import { useSelector } from 'react-redux';
import UserImage from '../../features/images/userImage.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AppSettings } from '../../config/app-settings.js';
import { getGenericData } from '../../api/generic-get.js';
import { useParams } from 'react-router-dom';

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

function Profile() {
  const { userId } = useParams();
  const auth = useSelector(state => state.auth);
  const isCurrentUser = !userId || userId === auth.id;
  const userRoles = auth.roles

  const [profileStats, setProfileStats] = useState({});
  const [profileData, setProfileData] = useState({});
  const [errMsg, setErrMsg] = useState({});
  const [ackMsg, setAckMsg] = useState({});

  const context = useContext(AppSettings);

  useEffect(() => {
    context.setAppContentClass('p-3');
    context.setAppContentFullHeight(true);

    return function cleanUp() {
      context.setAppContentClass('');
      context.setAppContentFullHeight(false);
    };

    // eslint-disable-next-line
  }, []);

  const backend_endpoint = '/api/profile';

  const getProfileData = async () => {
    try {
      const queryParams = new URLSearchParams({
        profile_data: true,
      });
      const url = `${backend_endpoint}/${userId || auth.id}?${queryParams.toString()}`;
      const { data, error } = await getGenericData(url);

      if (data && !data.message) {
        setProfileData(data.profile || {});
      } else {
        setProfileData({});
      }

      if (error) {
        setErrMsg(error);
      }
    } catch (error) {
      setErrMsg(error);
    }
  };

  const getLatestProfileStats = async () => {
    try {
      const queryParams = new URLSearchParams({
        profile_stats: true,
      });
      const url = `${backend_endpoint}/${userId || auth.id}?${queryParams.toString()}`;
      const { data, error } = await getGenericData(url);

      if (data && !data.message) {
        setProfileStats(data.profile_stats || {});
      } else {
        setProfileStats({});
      }

      if (error) {
        setErrMsg(error);
      }
    } catch (error) {
      setErrMsg(error);
    }
  };

  useEffect(() => {
    getProfileData();
    getLatestProfileStats();
  }, [userId]);

  const transformProfileStats = (stats) => {
    return [
      { mode: 'Multiplayer', kd: stats.mw_kd || 'N/A' },
      { mode: 'Battle Royale', kd: stats.br_kd || 'N/A' },
      { mode: 'Resurgence', kd: stats.res_kd || 'N/A' },
      { mode: 'Ranked', kd: stats.rank_kd || 'N/A' },
      { mode: 'Rank', kd: stats.rank || 'N/A' },
    ].filter(item => item.kd !== undefined && item.kd !== "");
  };

  const getRankClass = (rank) => {
    if (/IRIDESCENT/.test(rank)) return 'text-indigo';
    if (/CRIMSON/.test(rank)) return 'text-danger';
    if (/DIAMOND/.test(rank)) return 'text-primary';
    if (/PLATINUM/.test(rank)) return 'text-success';
    if (/GOLD/.test(rank)) return 'text-yellow';
    if (/SILVER/.test(rank)) return 'text-gray-300';
    if (/BRONZE/.test(rank)) return 'text-warning';
    return 'text-default';
  };

  const profile = isCurrentUser ? { ...auth, ...profileData } : profileData;

  return (
    <div>
      <Card>
        <CardBody className="p-0">
          <div className="profile">
            <div className="profile-container">
              <div className="profile-sidebar">
                <div className="desktop-sticky-top">
                  <div className="profile-img">
                    <UserImage imageType={profile.profile_pic} altText="Profile Picture" />
                  </div>
                  <h4>{profile.nickname}</h4>
                  <div className="mb-1">
                    <span className="text-white">Active:</span> {!profile.has_active_session === true && isCurrentUser ? <i className="fa fa-circle fs-8px fa-fw text-success mt-2 mb-2"></i> : profile.has_active_session === true ? <i className="fa fa-circle fs-8px fa-fw text-success mt-2 mb-2"></i> : <i className="fa fa-circle fs-8px fa-fw text-danger mt-2 mb-2"></i>}
                  </div>
                  <div className="mb-2">
                    <span className="text-white">Last Seen:</span> {!profile.was_last_seen ? '0 minutes ago' : profile.was_last_seen ? `${profile.was_last_seen} minutes ago` : 'inactive'}
                  </div>
                  <div className="mb-3">
                    <i className="bi bi-activity text-white text-opacity-50"></i> {profile.atvi_id} {' '}
                    <span className={`badge border ${profile.verified ? 'border-indigo text-indigo' : 'border-danger text-danger'}`}>
                      [{profile.verified ? 'verified' : 'unverified'}]
                    </span>
                  </div>
                  <div className="mb-3">
                    <i className="fa fa-brands fa-discord fa-fw text-white text-opacity-50"></i> {profile.discord_id} {' '}
                    {profile.discord_id ? <span className="badge border border-indigo text-indigo">[verified]</span> : <span className="badge border border-danger text-danger">[unverified]</span>}
                  </div>
                  <div className="mb-3">
                    <i className="fa fa-brands fa-steam fa-fw text-white text-opacity-50"></i> {profile.network}
                  </div>
                  <p className="mb-3">
                    <i className="fa fa-solid fa-circle-info fa-fw text-white text-opacity-50"></i> {profile.description}
                  </p>
                  <div className="mb-3">
                    <i className="fa fa-map-marker-alt fa-fw text-white text-opacity-50"></i> {profile.country}
                  </div>
                  <div className="mb-3">
                    <i className="fa fa-brands fa-youtube fa-fw text-white text-opacity-50"></i> {profile.yt_link}
                  </div>
                  <div className="mb-3">
                    <i className="fa fa-brands fa-twitch fa-fw text-white text-opacity-50"></i> {profile.ttv_link}
                  </div>
                </div>
              </div>

              <div className="profile-content">
                <ul className="profile-tab nav nav-tabs nav-tabs-v2">
                  {isMobileDevice() ? (
                    <li className="nav-item">
                      <a href="#profile-user-details" className={`nav-link ${isMobileDevice() ? 'active' : ''}`} data-bs-toggle="tab">
                        <div className="nav-field">USER</div>
                        <div className="nav-value">DETAILS</div>
                      </a>
                    </li>
                  ) : (null)
                  }
                  <li className="nav-item">
                    <a href="#profile-user-stats" className={`nav-link ${isMobileDevice() ? '' : 'active'}`} data-bs-toggle="tab">
                      <div className="nav-field">USER</div>
                      <div className="nav-value">STATS</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#profile-user-events" className="nav-link" data-bs-toggle="tab">
                      <div className="nav-field">USER</div>
                      <div className="nav-value">EVENTS</div>
                    </a>
                  </li>
                </ul>
                <div className="profile-content-container">
                  <PerfectScrollbar className="h-100">

                    <div className="tab-content p-0">

                      {isMobileDevice() ? (
                        <div className={`tab-pane fade ${isMobileDevice() ? 'show active' : ''}`} id="profile-user-details">
                          <Card className="mb-3">
                            <CardHeader className="fw-bold bg-transparent">User Details</CardHeader>
                            <CardBody>
                              <UserImage imageType={profile.profile_pic} altText="Profile Picture" width="100px" className='rounded-circle' />
                              <br />
                              <br />
                              <h4>{profile.nickname}</h4>
                              <div className="mb-1">
                                <span className="text-white">Active:</span> {!profile.has_active_session === true && isCurrentUser ? <i className="fa fa-circle fs-8px fa-fw text-success mt-2 mb-2"></i> : profile.has_active_session === true ? <i className="fa fa-circle fs-8px fa-fw text-success mt-2 mb-2"></i> : <i className="fa fa-circle fs-8px fa-fw text-danger mt-2 mb-2"></i>} 
                              </div>
                              <div className="mb-2">
                                <span className="text-white">Last Seen:</span> {!profile.was_last_seen ? '0 minutes ago' : profile.was_last_seen ? `${profile.was_last_seen} minutes ago` : 'inactive'}
                              </div>
                              <div className="mb-3">
                                <i className="bi bi-activity text-white text-opacity-50"></i> {profile.atvi_id} {' '}
                                <span className={`badge border ${profile.verified ? 'border-indigo text-indigo' : 'border-danger text-danger'}`}>
                                  [{profile.verified ? 'verified' : 'unverified'}]
                                </span>
                              </div>
                              <div className="mb-3">
                                <i className="fa fa-brands fa-discord fa-fw text-white text-opacity-50"></i> {profile.discord_id} {' '}
                                {profile.discord_id ? <span className="badge border border-indigo text-indigo">[verified]</span> : <span className="badge border border-danger text-danger">[unverified]</span>}
                              </div>
                              <div className="mb-3">
                                <i className="fa fa-brands fa-steam fa-fw text-white text-opacity-50"></i> {profile.network}
                              </div>
                              <p className="mb-3">
                                <i className="fa fa-solid fa-circle-info fa-fw text-white text-opacity-50"></i> {profile.description}
                              </p>
                              <div className="mb-3">
                                <i className="fa fa-map-marker-alt fa-fw text-white text-opacity-50"></i> {profile.country}
                              </div>
                              <div className="mb-3">
                                <i className="fa fa-brands fa-youtube fa-fw text-white text-opacity-50"></i> {profile.yt_link}
                              </div>
                              <div className="mb-3">
                                <i className="fa fa-brands fa-twitch fa-fw text-white text-opacity-50"></i> {profile.ttv_link}
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      ) : (null)
                      }

                      <div className={`tab-pane fade ${isMobileDevice() ? '' : 'show active'}`} id="profile-user-stats">
                        <Card className="card mb-3">
                          <CardBody>
                            <div className="d-flex align-items-center mb-3">
                              <a href="#/">
                                <UserImage imageType={profile.profile_pic} altText="" width="50px" className='rounded-circle' />
                              </a>
                              <div className="flex-fill ps-2">
                                <div className="fw-bold"><a href="#/" className="text-decoration-none">Game</a> : <a href="#/" className="text-decoration-none">Official Stats</a></div>
                                <div className="small text-white text-opacity-50">Your Stats</div>
                              </div>
                            </div>

                            <p>Game Official Stats</p>
                            {profile.stats_pic && (
                              <div className="profile-img-list d-flex justify-content-center">
                                <div className="profile-img-list-item main"><a href={`/user-data/${profile.stats_pic}`} data-lity className="profile-img-list-link"><span className="profile-img-content" style={{ backgroundImage: `url(/user-data/${profile.stats_pic})` }}></span></a></div>
                              </div>
                            )
                            }
                            <hr className="mb-1" />
                            <table className="table table-bordered mb-0">
                              <thead className="table-active">
                                <tr>
                                  <th scope="col">MODE</th>
                                  <th scope="col">K/D</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transformProfileStats(profileStats).map((stat, index) => (
                                  <tr key={index}>
                                    <th scope="row">{stat.mode}</th>
                                    <td>
                                      {stat.mode === 'Rank' ? (
                                        stat.kd !== 'N/A' ? (
                                          <b className={getRankClass(stat.kd)}>{stat.kd}</b>
                                        ) : (
                                          <b className="text-gray-100">N/A</b>
                                        )
                                      ) : (
                                        <b className="text-gray-100">{stat.kd}</b>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                          </CardBody>
                        </Card>
                      </div>

                      {userRoles.includes("admins") && (
                      <div className="tab-pane fade" id="profile-user-events">
                        <Card className="mb-3">
                          <CardHeader className="d-flex align-items-center bg-white bg-opacity-15">
                            <span className="flex-fill">Your Upcoming Event</span>
                            <a href="#/" className="text-white text-opacity-50"><i className="bi bi-calendar-event"></i></a>
                          </CardHeader>
                          <div className="list-group list-group-flush">
                            <div className="list-group-item px-3">
                              <div className="text-white text-opacity-50 mb-2"><i className="fa fa-circle fs-8px fa-fw text-warning me-1"></i><small><strong>BR Tournament</strong></small></div>
                              <span className="badge border border-info text-info mb-2">[BR QUADS]</span>
                              <div className="small text-white text-opacity-50 mb-2">CODE <strong className="bg-gray-900 text-success">#29949</strong></div>
                              <Card className="text-white text-decoration-none mt-2 mb-3 text-break">
                                <CardBody>
                                  <div className="row no-gutters">
                                    <div className="col-md-8">
                                      <div className="small text-white text-opacity-50 mb-1 mt-n1">HOST</div>
                                    </div>
                                  </div>
                                  <a href="#/" className="list-group-item list-group-item-action d-flex align-items-center text-white">
                                    <div className="w-40px h-40px d-flex align-items-center justify-content-center ms-n1">
                                      <UserImage imageType={profile.profile_pic} altText="" className="ms-100 mh-100 rounded-circle" />
                                    </div>
                                    <div className="flex-fill ps-3">
                                      <div className="fw-bold">
                                        Wojciech Orlowski
                                      </div>
                                    </div>
                                  </a>
                                </CardBody>
                              </Card>

                              <div className="d-flex justify-content-center mb-2">
                                <a href="#/" className="btn btn-sm btn-outline-theme fs-11px">Open</a>
                              </div>

                            </div>
                          </div>
                        </Card>

                        <Card className="mb-3">
                          <CardHeader className="d-flex align-items-center bg-white bg-opacity-15">
                            <span className="flex-fill">Your Past Event</span>
                            <a href="#/" className="text-white text-opacity-50"><i className="bi bi-calendar-event"></i></a>
                          </CardHeader>
                          <div className="list-group list-group-flush">
                            <div className="list-group-item px-3">
                              <div className="text-white text-opacity-50 mb-2"><i className="fa fa-circle fs-8px fa-fw text-warning me-1"></i><small><strong>BR Tournament</strong></small></div>
                              <span className="badge border border-info text-info mb-2">[BR QUADS]</span>
                              <div className="small text-white text-opacity-50 mb-2">CODE <strong className="bg-gray-900 text-success">#29949</strong></div>
                              <Card className="text-white text-decoration-none mt-2 mb-3 text-break">
                                <CardBody>
                                  <div className="row no-gutters">
                                    <div className="col-md-8">
                                      <div className="small text-white text-opacity-50 mb-1 mt-n1">HOST</div>
                                    </div>
                                  </div>
                                  <a href="#/" className="list-group-item list-group-item-action d-flex align-items-center text-white">
                                    <div className="w-40px h-40px d-flex align-items-center justify-content-center ms-n1">
                                      <img src="/assets/img/user/profile.jpg" alt="" className="ms-100 mh-100 rounded-circle" />
                                    </div>
                                    <div className="flex-fill ps-3">
                                      <div className="fw-bold">
                                        Wojciech Orlowski
                                      </div>
                                    </div>
                                  </a>
                                </CardBody>
                              </Card>

                              <div className="d-flex justify-content-center mb-2">
                                <a href="#/" className="btn btn-sm btn-outline-theme fs-11px">Open</a>
                              </div>

                            </div>
                          </div>

                        </Card>
                        <hr className="mb-3 mt-1" />
                        <div className="d-flex align-items-center">
                          <div className="mx-2">
                            <span className="badge border border-indigo text-indigo">[TEAM K'llZ]</span>
                          </div>
                          <div className="widget-user-list">
                            <div className="widget-user-list-item"><a href="#/" className="widget-user-list-link"><img src="assets/img/user/user-1.jpg" alt="" /></a></div>
                            <div className="widget-user-list-item"><a href="#/" className="widget-user-list-link"><img src="assets/img/user/user-2.jpg" alt="" /></a></div>
                            <div className="widget-user-list-item"><a href="#/" className="widget-user-list-link"><img src="assets/img/user/user-3.jpg" alt="" /></a></div>
                          </div>
                          <div className="mx-2">
                            <span className="badge border border-info text-info">[PLACEMENT: 2nd]</span>
                          </div>
                        </div>

                      </div>
                      )}

                    </div>

                  </PerfectScrollbar>
                </div>
              </div>
            </div>

          </div>
        </CardBody>
      </Card>
      <br />
      <br />
    </div>
  )
}

export default Profile;
