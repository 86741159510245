import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { setLocation } from '../features/auth/authSlice';

const PrivateRoute = ({ Component, role = 'guest', ...rest }) => {
  const location = useLocation();
  const { auth, userRole } = useSelector(
    (state) => ({
      auth: state.auth,
      userRole: state.auth.roles,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch the action when the component mounts
    dispatch(setLocation(location));
  }, [dispatch, location]);

  if (!auth.isAuthenticated) {
    if (role === 'guest') return <Component {...rest} />;
    // Need to login if unauthorized
    return <Navigate to="/auth/login" />;
  }

  // No Permission
  if (role.indexOf(userRole) >= 0) return <Component {...rest} />;
  else return <Navigate to="/no-permission" state={{ from: location }} replace />;
};

export default PrivateRoute;
