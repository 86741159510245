// This is only needed if working with cookies in insecure way!
import { useEffect, useState } from 'react';

const useCsrfToken = () => {
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    // Function to get the value of a cookie by name
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    };

    // Fetch CSRF token from the cookie
    const token = getCookie('X-CSRF-Token');
    setCsrfToken(token);
  }, []);

  return csrfToken;
};

export default useCsrfToken;
