import React, { useRef, useEffect, useContext, useState } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { Tooltip } from "react-tooltip";
import { recover } from '../../features/auth/authActions.js';
import { useDispatch, useSelector } from "react-redux";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{12,24}$/;

function PagesRecover() {
	const location = useSelector(state => state.auth.location);
	const dispatch = useDispatch();
	const history = useNavigate();

	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);


	const errRef = useRef();

	const [userFocus, setUserFocus] = useState(false);
	const [searchParam, setSearchParam] = useSearchParams();
	const token = searchParam.get("token")

	const [password, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [password_confirm, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);
	const [errMsg, setErrMsg] = useState('');


	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setValidPwd(PWD_REGEX.test(password));
		setValidMatch(password === password_confirm);
	}, [password, password_confirm])

	useEffect(() => {
		setErrMsg('');
	}, [password, password_confirm])

	async function handleSubmit(event) {
		event.preventDefault();

		const v2 = PWD_REGEX.test(password);
		if (!v2) {
			setErrMsg("Invalid Entry");
			return;
		}
		console.log(token)
		dispatch(recover(token, password, password_confirm)).then(() => {
			history(location)
		})
		setRedirect(true);
	}

	if (redirect) {
		return <Navigate to='/' />;
	}
	return (
		<div>
			<div className="login-logo">
				<div className="login-brand-img">
					<img src="/assets/img/logo/warfaresvg.svg" alt="WARFARE ZONE - LOGO" />
				</div>
			</div>
			<div className="register">
				<div className="register-content">
					<form onSubmit={handleSubmit}>
						<br />
						<h1 className="text-center">Reset Password</h1>
						<p className="text-white text-opacity-50 text-center">Please provide your new password.</p>
						<div className="mb-3">
							<label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
							<input type="password"
								id="password"
								onChange={(event) => setPwd(event.target.value)}
								value={password}
								required
								aria-invalid={validPwd ? "false" : "true"}
								aria-describedby="password"
								onFocus={() => setPwdFocus(true)}
								onBlur={() => setPwdFocus(false)}
								className="form-control form-control-lg bg-white bg-opacity-5" placeholder="Passowrd"
								data-tooltip-variant={pwdFocus && !validPwd ? "error" : "dark"}
							/>
							<Tooltip anchorId="password" style={{ width: "70%" }} content="Use Min 12 and Max 24 characters.
                                                                Must include uppercase and lowercase letters, a number and a special character."
								className={pwdFocus && !validPwd ? "error" : "dark"} place="right" />
						</div>
						<div className="mb-3">
							<label htmlFor="confirm_pwd" className="form-label">Confirm Password <span className="text-danger">*</span></label>
							<input type="password"
								id="confirm_pwd"
								onChange={(event) => setMatchPwd(event.target.value)}
								value={password_confirm}
								required
								aria-invalid={validMatch ? "false" : "true"}
								aria-describedby="confirm_pwd"
								onFocus={() => setMatchFocus(true)}
								onBlur={() => setMatchFocus(false)}
								className="form-control form-control-lg bg-white bg-opacity-5" placeholder="Repeat Passowrd"
								data-tooltip-variant={matchFocus && !validMatch ? "error" : "dark"}
							/>
							<Tooltip anchorId="confirm_pwd" style={{ width: "70%" }} content="Please repeat your password."
								className={matchFocus && !validMatch ? "error" : "dark"} place="right" />
						</div>
						<div className="mb-3">
							<div className="form-check">
								<input className="form-check-input" type="checkbox" id="customCheck1" />
								<label className="form-check-label" htmlFor="customCheck1">I have read and agree to the <a href="#/">Terms of Use</a> and <a href="#/">Privacy Policy</a>.</label>
							</div>
						</div>
						<div className="mb-3">
							<button disabled={!validPwd || !validMatch ? true : false} type="submit" className="btn btn-outline-theme btn-lg d-block w-100">Reset Passowrd</button>
						</div>
						<div className="text-white text-opacity-50 text-center">
							Already a Soldier? <Link to="/auth/login">Sign In</Link>
						</div>
						<div className="mt-4">
							<div className="text-center text-white text-opacity-50">
								<i className="fa-brands fa-discord" style={{ fontSize: '2em' }}></i>
							</div>
						</div>
						<div className="mt-2">
							<div className="text-center text-white text-opacity-50">
								<Link to="https://discord.com/invite/jmQfQhD2Ny" className='text-white text-opacity-50 text-decoration-none login-link'>WARFARE ZONE DISCORD</Link>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default PagesRecover;
