import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../features/auth/authActions";
import { setUser } from '../features/auth/authSlice'

const PersistLogin = ({ Component }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    // The below is only used with extra logic && session for evaluating persistance.
    // Get Cookie from Browser
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    // Fetch CSRF token from the cookie, since session cookie is HttpOnly and cannot be accessed
    const session = getCookie('X-CSRF-Token');

    useEffect(() => {
        const persistLoginLogic = () => {
            // add && session to determine if user should logout if not having valid token
            // the token will be renewed by flask now via after_request in wsgi.py
            // however if need to change the logic use this extra condition
            // TODO: can implement logic that if X-CSRF-Token expires should call for new token via endpoint in flask.
            if (Object.keys(auth).length > 0) {
                // If user data exists and session is valid, dispatch setUser with persisted auth data
                dispatch(setUser(auth));
            } else {
                // If no user data or invalid session, logout and set user as guest
                dispatch(setUser({ roles: "guest", user: null }));
                dispatch(logout());
            }
        };

        persistLoginLogic(); // Call the logic on component mount

        // Optionally, you can add dependencies to the useEffect dependency array if needed
        // For example, if `auth` or `session` changes, you might want to trigger this logic again.
    }, [auth, session, dispatch]);

    return <Component />;
};

export default PersistLogin;