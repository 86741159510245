import axios from 'axios'
import { setUser } from './authSlice';
import { persistor } from '../../store.js';
import axiosInstance from '../../api/external-api.service.js';

// No need for cookie since in Flask SPA, first request is configured to ignore CSRF
// check config.py
// WTF_CSRF_CHECK_DEFAULT = False
// SECURITY_CSRF_IGNORE_UNAUTH_ENDPOINTS = True
// SECURITY_REDIRECT_BEHAVIOR = 'spa'

export const login = (email, password) => async (dispatch) => {
  try {
    // const response = await axios({
      const response = await axiosInstance({
      mode: 'cors',
      url: '/api/accounts/login',
      method: 'POST',
      withCredentials: true,
      data: { email, password },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
      }
    });

    localStorage.setItem("storetoken", JSON.stringify(response.data.response.user));
    dispatch(setUser(response.data.response.user));
  } catch (error) {
    throw error;
  }
};


export const register = (email, username, password, password_confirm) => async (dispatch) => {
  try {
    const response = await axiosInstance.post("/api/accounts/register", JSON.stringify({ email, username, password, password_confirm }), {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
      },
      withCredentials: true
    });

    // Assuming the response contains a user object in response.data.response.user
    localStorage.setItem("storetoken", JSON.stringify(response.data.response.user));
    dispatch(setUser(response.data.response.user));
    console.log(JSON.stringify(response.data.response.user));
    console.log(JSON.stringify(response.data));

    return response.data.response.user;  // Optionally resolve with the user data
  } catch (error) {
    throw error;  // It will allow the caller to handle rejection
  }
};


//export const register = (email, password, password_confirm) => dispatch => {
//  return new Promise((resolve, reject) => {
//    axiosInstance.post("/api/accounts/register", JSON.stringify({ email, password, password_confirm }),
//      {
//        headers: {
//          'Content-Type': 'application/json',
//          'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
//        },
//        withCredentials: true
//      }).then(resp => {
//        localStorage.setItem("storetoken", JSON.stringify(resp?.data.response.user));
//        dispatch(setUser(JSON.stringify(resp?.data.response.user)));
//        console.log(JSON.stringify(resp?.data.response.user));
//        //resolve(JSON.stringify(resp?.data.response.user));
//        resolve();
//      })
//      .catch(err => reject(err))
//  })
//}


export const reset = (email) => async () => {
  try {
    const response = await axiosInstance({
      mode: 'cors',
      url: '/api/accounts/reset',
      method: 'POST',
      withCredentials: true,
      data: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
      }
    });

    console.log("auth Action reset:", JSON.stringify(response.data));
    return JSON.stringify(response.data);  // Optionally resolve with the response data
  } catch (error) {
    throw error;
  }
};

//export const reset = (email) => dispatch => {
//  return new Promise((resolve, reject) => {
//    axiosInstance.post("/api/accounts/reset", JSON.stringify({ email }),
//      {
//        headers: {
//          'Content-Type': 'application/json',
//          'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
//        },
//        withCredentials: true
//      }).then(resp => {
//        console.log("auth Action reset:", JSON.stringify(resp?.data))
//        resolve(JSON.stringify(resp?.data))
//      })
//      .catch(err => reject(err))
//  })
//}



export const recover = (token, password, password_confirm) => async (dispatch) => {
  try {
    const response = await axiosInstance.post("/api/accounts/reset/" + token, JSON.stringify({ password, password_confirm }),
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
        },
        withCredentials: true
      });

    console.log("Recover response data:", JSON.stringify(response.data)); // For debugging

    // Only update or dispatch if necessary
    if (response.data && response.data.user) {
      localStorage.setItem("storetoken", JSON.stringify(response.data.user)); // Evaluate if this is necessary
      dispatch(setUser(response.data.user)); // Adjust based on actual returned user data
    }

    return JSON.stringify(response.data); // Optionally resolve with the response data or handle differently
  } catch (error) {
    throw error; // Proper error handling
  }
};




// FIX: there is a broken redirect after recover is complete (probably token, user in redux is not added) it stucks at no-permission
// but after a page refresh or clicking HOME it finally update user state and user logs back normally with valid session
//export const recover = (token, password, password_confirm) => dispatch => {
//  return new Promise((resolve, reject) => {
//    axiosInstance.post("/api/accounts/reset/" + token, JSON.stringify({ password, password_confirm }),
//      {
//        headers: {
//          'Content-Type': 'application/json',
//          'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
//        },
//        withCredentials: true
//      }).then(resp => {
//        localStorage.setItem("storetoken", JSON.stringify(resp?.data.response.user))
//        dispatch(setUser(JSON.stringify(resp?.data.response.user)));
//        console.log(JSON.stringify(resp?.data.response.user))
//        console.log(JSON.stringify(resp?.data))
//        resolve(JSON.stringify(resp?.data.response.user))
//      })
//      .catch(err => reject(err))
//  })
//}

export const logout = () => async dispatch => {
  try {
    await axiosInstance.get("/api/accounts/logout", {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': axiosInstance.defaults.baseURL,
      },
      withCredentials: true
    });

    localStorage.removeItem("storetoken");
    sessionStorage.clear();

    await dispatch(setUser({ roles: "guest", user: null }));

    // Ensure state updates have been completed
    return persistor.flush().then(() => {
      // After ensuring all updates are flushed, then purge
      return persistor.purge().then(() => console.log('Purge completed'));
    });

  } catch (error) {
    console.error("Logout failed:", error);
  }
};
