import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';

export function MarkAsReadNotificationForm({ notification_id, backend_endpoint, getNotificationsData, setAckMsg, setErrMsg, ButtonClass, ButtonText }) {
    const formik = useFormik({
        initialValues: { notification_id: notification_id },
        validate: values => {
            const errors = {};
            if (!values.notification_id) {
                errors.notification_id = 'Notification ID is required';
            } else if (typeof values.notification_id !== 'number' || !Number.isInteger(values.notification_id)) {
                errors.notification_id = 'Notification ID must be an integer';
            }
            return errors;
        },
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const { notification_id } = values;
                const formIdentifier = 'read_notification';
                const payloadSubmission = { formIdentifier, notification_id };
                const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);

                if (data) {
                    setAckMsg(data);
                    await getNotificationsData();
                }
                if (error) {
                    setErrMsg(error);
                }
            } catch (error) {
                setErrMsg(error);
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <input type="hidden" name="notification_id" value={formik.values.notification_id} onChange={formik.handleChange} />
            <button type="submit" className={ButtonClass}>
                {ButtonText}
            </button>
        </form>
    );
}
