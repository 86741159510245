import React, { useRef, useEffect, useContext, useState } from 'react';
import { Tooltip } from "react-tooltip";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { login } from '../../features/auth/authActions.js';
import { useDispatch, useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert';


const USER_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

function PagesLogin() {
	const location = useSelector(state => state.auth.location)
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const userRef = useRef();
	const [validName, setValidName] = useState(false);
	const [userFocus, setUserFocus] = useState(false);
	const [errMsg, setErrMsg] = useState('');

	// const [role, setRole] = useState('')
	const dispatch = useDispatch();
	const history = useNavigate()

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setValidName(USER_REGEX.test(email));
	}, [email])

	useEffect(() => {
		setErrMsg('');
	}, [email])

	async function handleSubmit(event) {
		event.preventDefault();

		const v1 = USER_REGEX.test(email);
		if (!v1) {
			setErrMsg("Invalid Entry");
			return;
		}

		// dispatch(login(email, password)).then(()=>{
		// 	history(location)
		// })

		// The below instead of above will prevent showing a page for split second if login fails.
		try {
			await dispatch(login(email, password));
			setRedirect(true); // Set redirect to true only if login is successful
		} catch (error) {
			console.log("Login failed:", error.response.data.response.errors.join(", "));
			setErrMsg({ 'message': error.response.data.response.errors.join(", ") });
			console.log(errMsg);
		}

	}

	if (redirect) {
		return <Navigate to={'/'} />;
	}
	return (
		<div>
			<div className="login-logo">
				<div className="login-brand-img">
    				<img src="/assets/img/logo/warfaresvg.svg" alt="WARFARE ZONE - LOGO" />
				</div>
			</div>
			<div className="login">
				<div className="login-content">

					<form onSubmit={handleSubmit}>
						{/* <h2 className="text-center" style={{ color: "#FFA500" }}> WARFARE.ZONE  </h2> */}
						<br />
						<div>
							{errMsg.message ? (
								<Alert onClose={() => setErrMsg({})} variant="danger" dismissible>
									{typeof errMsg.message === 'object' && Object.keys(errMsg.message).length > 0 ? (
										Object.entries(errMsg.message).map(([key, value], index) => (
											<div key={index}>
												<span>
													<strong>
														<code style={{ color: "red" }}>{`${key}: ${value}`}</code>
													</strong>
												</span>
											</div>
										))
									) : (
										<span>
											<strong>
												<code style={{ color: "red" }}>{errMsg.message}</code>
											</strong>
										</span>
									)}
								</Alert>
							) : null}
						</div>
						<h1 className="text-center">Sign In</h1>
						<div className="text-white text-opacity-50 text-center mb-4">
							Hello Soldier, please verify your identity.
						</div>
						<div className="mb-3">
							<label htmlFor="email" className="form-label">Email Address <span className="text-danger">*</span></label>
							<input type="email"
								id="email"
								ref={userRef}
								value={email}
								className="form-control form-control-lg bg-white bg-opacity-5"
								placeholder="Email Address"
								onChange={(e) => { setEmail(e.target.value) }}
								required
								autoComplete="off"
								aria-invalid={validName ? "false" : "true"}
								aria-describedby="email"
								onFocus={() => setUserFocus(true)}
								onBlur={() => setUserFocus(false)}
								data-tooltip-variant={userFocus && email && !validName ? "error" : "dark"}
							/>
							<Tooltip anchorId="email" style={{ width: "70%" }} content="Please enter your email address."
								className={userFocus && email && !validName ? "error" : "dark"}
								place="right" />
						</div>
						<div className="mb-3">
							<div className="d-flex">
								<label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
								<Link to="/auth/reset" className="ms-auto text-white text-decoration-none text-opacity-50">Forgot password?</Link>
							</div>
							<input type="password" id="password" value={password} className="form-control form-control-lg bg-white bg-opacity-5" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }} required />
							<Tooltip anchorId="password" style={{ width: "70%" }} content="Please enter your password." place="right" />
						</div>
						<div className="mb-3">
							<div className="form-check">
								<input className="form-check-input" type="checkbox" id="customCheck1" />
								<label className="form-check-label" htmlFor="customCheck1">Remember me</label>
							</div>
						</div>
						<button disabled={!validName ? true : false} type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Sign In</button>
						<div className="text-center text-white text-opacity-50">
							Not a Soldier yet? <Link to="/auth/register">Sign up</Link>
						</div>
						<div className="mt-4">
							<div className="text-center text-white text-opacity-50">
								<i className="fa-brands fa-discord" style={{ fontSize: '2em' }}></i>
							</div>
						</div>
						<div className="mt-2">
							<div className="text-center text-white text-opacity-50">
								<Link to="https://discord.com/invite/jmQfQhD2Ny" className='text-white text-opacity-50 text-decoration-none login-link'>WARFARE ZONE DISCORD</Link>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default PagesLogin;