import React from 'react';
import { NavScrollTo } from '../../components/nav-scroll-to/nav-scroll-to';

function PrivacyPolicy() {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10">
                    <div className="row">
                        <div className="col-xl-8">

                            <h1>Privacy and cookies policy</h1>

                            <div id="introduction" className="mb-5">
                                <h2>1. Introduction</h2>
                                <p>1.1 We are committed to safeguarding the privacy of our visitors and community members.</p>
                                <p>1.2 This policy applies where we are acting as a data controller with respect to the personal data of such persons; in other words, where we determine the purposes and means of the processing of that personal data.</p>
                                <p>1.3 Our website does not incorporates privacy controls about how we will process your personal data. Therefore all personal data you provide is subject to our terms and conditions which you either fully or not agree to.</p>
                                <p>1.4 We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.</p>
                                <p>1.5 In this policy, "we", "us" and "our" refer to WARFARE.ZONE. For more information about us, see Section 19.</p>
                            </div>

                            <div id="personal-data" className="mb-5">
                                <h2>2. The personal data that we collect</h2>
                                <p>2.1 In this Section 2 we have set out the general categories of personal data that we process.</p>
                                <p>2.2 We may process data enabling us to get in touch with you ("contact data"). The contact data may include email address and/or social media account identifiers. The source of the contact data is comes from you.</p>
                                <p>2.3 We may process your website user account data ("account data"). The account data may include your account identifier, name, email address, business name, account creation and modification dates, website settings, ATVI ID and Discord ID. The primary source of the account data is you.</p>
                                <p>2.4 We may process your information included in your personal profile on our website ("profile data"). The profile data may include your username, email address, profile pictures, gender. The source of the profile data is you.</p>
                                <p>2.5 We may process your personal data that are provided in the course of the use of our services and generated by our services in the course of such use ("service data"). The service data may include IP address. The source of the service data is you.</p>
                                <p>2.6 We may process information contained in or relating to any communication that you send to us or that we send to you ("communication data"). The communication data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms.</p>
                                <p>2.7 We may process data about your use of our website and services ("usage data"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system.</p>
                                <p>2.8 Please do not supply any other person's personal data to us, unless we prompt you to do so.</p>
                            </div>

                            <div id="purposes" className="mb-5">
                                <h2>3. Purposes of processing and legal bases</h2>
                                <p>3.1 In this Section 3, we have set out the purposes for which we may process personal data and the legal bases of the processing.</p>
                                <p>3.2 Operations - We may process your personal data for the purposes of operating our website and providing our services. The legal basis for this processing is our legitimate interests, namely the proper administration of our website, services and business.</p>
                                <p>3.3 Publications - We may process account data, profile data and/or service data for the purposes of publishing such data on our website and elsewhere through our services in accordance with your express instructions. The legal basis for this processing is your conent our legitimate interests, namely the publication of content in the ordinary course of our operations.</p>
                                <p>3.4 Relationships and communications - We may process contact data, account data and/or communication data for the purposes of managing our relationships, communicating with you (excluding communicating for the purposes of direct marketing) by email, providing support services and complaint handling. The legal basis for this processing is our legitimate interests, namely communications with our website visitors and service users, the maintenance of relationships, and the proper administration of our website, services and business.</p>
                                <p>3.5 Personalisation - We may process account data, service data and/or usage data for the purposes of personalising the content and advertisements that you see on our website and through our services to ensure that you only see material that is relevant to you. The legal basis for this processing is your consent and our legitimate interests, namely offering the best possible experience for our website visitors and service users.</p>
                                <p>3.6 Direct marketing - We may process contact data, account data and profile data for the purposes of creating, targeting and sending direct marketing communications by email and for marketing-related purposes. The legal basis for this processing is your consent and our legitimate interests, namely promoting our business and communicating marketing messages and offers to our website visitors and service users.</p>
                                <p>3.7 Research and analysis - We may process usage data and/or service data for the purposes of researching and analysing the use of our website and services, as well as researching and analysing other interactions with our business. The legal basis for this processing is our legitimate interests, namely monitoring, supporting, improving and securing our website, services and business generally.</p>
                                <p>3.8 Record keeping - We may process your personal data for the purposes of creating and maintaining our databases, back-up copies of our databases and our business records generally. The legal basis for this processing is our legitimate interests, namely ensuring that we have access to all the information we need to properly and efficiently run our business in accordance with this policy.</p>
                                <p>3.9 Security - We may process your personal data for the purposes of security and the prevention of fraud and other criminal activity. The legal basis of this processing is our legitimate interests, namely the protection of our website, services and business, and the protection of others.</p>
                                <p>3.10 Insurance and risk management - We may process your personal data where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks and/or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</p>
                                <p>3.11 Legal claims - We may process your personal data where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.</p>
                                <p>3.12 Legal compliance and vital interests - We may also process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or in order to protect your vital interests or the vital interests of another natural person.</p>
                            </div>

                            <div id="automated-decision-making" className="mb-5">
                                <h2>4. Automated decision-making</h2>
                                <p>4.1 We will use your personal data for the purposes of automated decision-making in relation to website services.</p>
                                <p>4.2 This automated decision-making will involve SBMM for Custom Matches, Tournaments, Lobbies assignments.</p>
                                <p>4.3 The significance and possible consequences of this automated decision-making are user experience and satisfaction from the website service usage.</p>
                            </div>

                            <div id="providing-personal-data" className="mb-5">
                                <h2>5. Providing your personal data to others</h2>
                                <p>5.1 We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy. Information about our group of companies can be found at /intel .</p>
                                <p>5.2 We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice.</p>
                                <p>5.3 We may disclose your email address to our suppliers or subcontractors. Insofar as reasonably necessary for advertisements and marketing purposes.</p>
                                <p>5.4 We may disclose contact data along with any other personal data contained in enquiries made through our website or services to one or more of those selected third party suppliers of goods and/or services identified on our website for the purpose of enabling them to contact you so that they can offer, market and sell to you relevant goods and/or services. Each such third party will act as a data controller in relation to the personal data that we supply to it; and upon contacting you, each such third party will supply to you a copy of its own privacy policy, which will govern that third party's use of your personal data.</p>
                                <p>5.5 In addition to the specific disclosures of personal data set out in this Section 5, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise, or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>
                            </div>

                            <div id="international-transfers" className="mb-5">
                                <h2>6. International transfers of your personal data</h2>
                                <p>6.1 In this Section 6, we provide information about the circumstances in which your personal data may be transferred to a third country under UK and/or EU data protection law.</p>
                                <p>6.2 We may transfer your personal data from the European Economic Area (EEA) to the UK and process that personal data in the UK for the purposes set out in this policy, and may permit our suppliers and subcontractors to do so, during any period with respect to which the UK is not treated as a third country under EU data protection law or benefits from an adequacy decision under EU data protection law; and we may transfer your personal data from the UK to the EEA and process that personal data in the EEA for the purposes set out in this policy, and may permit our suppliers and subcontractors to do so, during any period with respect to which EEA states are not treated as third countries under UK data protection law or benefit from adequacy regulations under UK data protection law.</p>
                                <p>6.3 We and our other group companies have offices and facilities in UK. The competent data protection authorities have made an adequacy determination with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the competent data protection authorities.</p>
                                <p>6.4 The hosting facilities for our website are situated in UK. The competent data protection authorities have made an adequacy determination with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the competent data protection authorities.</p>
                                <p>6.5 Specify category or categories of supplier or subcontractor are situated in UK. The competent data protection authorities have made an adequacy determination with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the competent data protection authorities.</p>
                                <p>6.6 You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</p>
                            </div>

                            <div id="retaining-deleting" className="mb-5">
                                <h2>7. Retaining and deleting personal data</h2>
                                <p>7.1 This Section 7 sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</p>
                                <p>7.2 Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</p>
                                <p>7.3 We will retain your personal data as follows:</p>
                                <p>(a) contact data will be retained for a minimum period of 7 days following the date of the most recent communication between you and us, and for a maximum period of 14 days following that date;</p>
                                <p>(b) account data will be retained for a minimum period of 7 days following the date of closure of the relevant account, and for a maximum period of 14 days following that date;</p>
                                <p>(c) profile data will be retained for a minimum period of 7 days following the date of deletion of the profile by you, and for a maximum period of 14 days following that date;</p>
                                <p>(d) service data will be retained for a minimum period of 7 days following the date of termination of the relevant contract, and for a maximum period of 14 days following that date;</p>
                                <p>(e) communication data will be retained for a minimum period of 7 days following the date of the communication in question, and for a maximum period of 14 days following that date;</p>
                                <p>(f) usage data will be retained for 14 days following the date of collection;</p>
                                <p>7.4 In some cases it is not possible for us to specify in advance the periods for which your personal data will be retained. In such cases, we will determine the period of retention based on the following criteria:</p>
                                <p>(a) the period of retention of personal data category will be determined based on default retention period determined by our risks management.</p>
                                <p>7.5 Notwithstanding the other provisions of this Section 7, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>
                            </div>

                            <div id="rights" className="mb-5">
                                <h2>8. Your rights</h2>
                                <p>8.1 In this Section 8, we have listed the rights that you have under data protection law.</p>
                                <p>8.2 Your principal rights under data protection law are:</p>
                                <p>(a) the right to access - you can ask for copies of your personal data;</p>
                                <p>(b) the right to rectification - you can ask us to rectify inaccurate personal data and to complete incomplete personal data;</p>
                                <p>(c) the right to erasure - you can ask us to erase your personal data;</p>
                                <p>(d) the right to restrict processing - you can ask us to restrict the processing of your personal data;</p>
                                <p>(e) the right to object to processing - you can object to the processing of your personal data;</p>
                                <p>(f) the right to data portability - you can ask that we transfer your personal data to another organisation or to you;</p>
                                <p>(g) the right to complain to a supervisory authority - you can complain about our processing of your personal data; and</p>
                                <p>(h) the right to withdraw consent - to the extent that the legal basis of our processing of your personal data is consent, you can withdraw that consent.</p>
                                <p>8.3 These rights are subject to certain limitations and exceptions. You can learn more about the rights of data subjects by visiting https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en and https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.</p>
                                <p>8.4 You may exercise any of your rights in relation to your personal data by written notice to us, using the contact details set out below.</p>
                            </div>

                            <div id="third-party-websites" className="mb-5">
                                <h2>9. Third party websites</h2>
                                <p>9.1 Our website includes hyperlinks to, and details of, third party websites.</p>
                                <p>9.2 In general we have no control over, and are not responsible for, the privacy policies and practices of third parties.</p>
                            </div>

                            <div id="personal-data-of-children" className="mb-5">
                                <h2>10. Personal data of children</h2>
                                <p>10.1 Our website and services are targeted at persons over the age of 16.</p>
                                <p>10.2 If we have reason to believe that we hold personal data of a person under that age in our databases, we will delete that personal data.</p>
                            </div>

                            <div id="updating-information" className="mb-5">
                                <h2>11. Updating information</h2>
                                <p>11.1 Please let us know if the personal information that we hold about you needs to be corrected or updated.</p>
                            </div>

                            <div id="acting-as-a-data-processor" className="mb-5">
                                <h2>12. Acting as a data processor</h2>
                                <p>12.1 In respect of any data you provided other than necessary by our services (like social links etc...), we do not act as a data controller; instead, we act as a data processor.</p>
                                <p>12.2 Insofar as we act as a data processor rather than a data controller, this policy shall not apply. Our legal obligations as a data processor are instead set out in the contract between us and the relevant data controller.</p>
                            </div>

                            <div id="about-cookies" className="mb-5">
                                <h2>13. About cookies</h2>
                                <p>13.1 A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>
                                <p>13.2 Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
                                <p>13.3 Cookies may not contain any information that personally identifies a user, but personal data that we store about you may be linked to the information stored in and obtained from cookies.</p>
                            </div>

                            <div id="cookies-we-use" className="mb-5">
                                <h2>14. Cookies that we use</h2>
                                <p>14.1 We use cookies for the following purposes:</p>
                                <p>(a) authentication and status - we use cookies to identify you when you visit our website and as you navigate our website, and to determine if you are logged into the website (cookies used for this purpose are: session);</p>
                                <p>(b) personalisation - we use cookies to store information about your preferences and to personalise the website for you (cookies used for this purpose are: storetoken);</p>
                                <p>(c) security - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally (cookies used for this purpose are: N/A);</p>
                                <p>(d) advertising - we use cookies to help us to display advertisements that will be relevant to you (cookies used for this purpose are: N/A);</p>
                                <p>(e) analysis - we use cookies to help us to analyse the use and performance of our website and services (cookies used for this purpose are: N/A); and</p>
                                <p>(f) cookie consent - we use cookies to store your preferences in relation to the use of cookies more generally (cookies used for this purpose are: N/A).</p>
                            </div>

                            <div id="service-providers" className="mb-5">
                                <h2>15. Cookies used by our service providers</h2>
                                <p>15.1 Our service providers use cookies and those cookies may be stored on your computer when you visit our website.</p>
                                <p>15.2 We use Google Analytics, which gathers information about the use of our website and uses cookies for this purpose. We use the information gathered by Google Analytics to create reports about the use of our website. You can find out more about Google's use of information by visiting https://policies.google.com/technologies/partner-sites and you can review Google's privacy policy at https://policies.google.com/privacy. The cookies used by Google Analytics are named _ga and _ga+container-id.</p>
                            </div>

                            <div id="managing-cookies" className="mb-5">
                                <h2>16. Managing cookies</h2>
                                <p>16.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser and from version to version. You can obtain up-to-date information about managing cookies via these links:</p>
                                <p>(a) https://support.google.com/chrome/answer/95647 (Chrome);</p>
                                <p>(b) https://support.mozilla.org/en-US/products/firefox/privacy-and-security (Firefox);</p>
                                <p>(c) https://help.opera.com/en/latest/security-and-privacy/ (Opera);</p>
                                <p>(d) https://support.apple.com/en-gb/guide/safari/welcome/mac (Safari); and</p>
                                <p>(e) https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd (Edge).</p>
                                <p>16.2 Blocking all cookies will have a negative impact upon the usability of many websites.</p>
                                <p>16.3 If you block cookies, you will not be able to use all the features on our website.</p>
                            </div>

                            <div id="cookie-preferences" className="mb-5">
                                <h2>17. Cookie preferences</h2>
                                <p>17.1 You can manage your preferences relating to the use of cookies on our website by visiting: /settings </p>
                            </div>

                            <div id="amendments" className="mb-5">
                                <h2>18. Amendments</h2>
                                <p>18.1 We may update this policy from time to time by publishing a new version on our website.</p>
                                <p>18.2 You should check this page occasionally to ensure you are happy with any changes to this policy.</p>
                                <p>18.3 We may notify you of changes to this policy by email.</p>
                            </div>

                            <div id="details" className="mb-5">
                                <h2>19. Our details</h2>
                                <p>19.1 This website is owned and operated by HEXTRIM LTD.</p>
                                <p>19.2 We are registered in Scotland under registration number SC641488, and our registered office is at 272 Bath Street, Glasgow, Scotland, G2 4JR.</p>
                                <p>19.3 Our principal place of business is at 272 Bath Street, Glasgow, Scotland, G2 4JR.</p>
                                <p>19.4 You can contact us:</p>
                                <p>(a) by post, to the postal address given above;</p>
                                <p>(b) using our website contact form;</p>
                                <p>(c) by email, using the email address published on our website.</p>
                            </div>

                            <div id="representatives" className="mb-5">
                                <h2>20. Representatives</h2>
                                <p>20.1 Our representative within the EU with respect to our obligations under data protection law is Villain_0x1034 and you can contact our representative by support@warfare.zone.</p>
                                <p>20.2 Our representative within the UK with respect to our obligations under data protection law is Villain_0x1034 and you can contact our representative by support@warfare.zone.</p>
                            </div>

                            <div id="data-protection-officer" className="mb-5">
                                <h2>21. Data protection officer</h2>
                                <p>21.1 Our data protection officer's contact details are: support@warfare.zone.</p>
                            </div>

                        </div>
                        <div className="col-xl-4 sticky-nav">
                            <NavScrollTo>
                                <nav className="nav">
                                    <a className="nav-link" href="#introduction" data-toggle="scroll-to">1. Introduction</a>
                                    <a className="nav-link" href="#personal-data" data-toggle="scroll-to">2. The personal data that we collect</a>
                                    <a className="nav-link" href="#purposes" data-toggle="scroll-to">3. Purposes of processing and legal bases</a>
                                    <a className="nav-link" href="#automated-decision-making" data-toggle="scroll-to">4. Automated decision-making</a>
                                    <a className="nav-link" href="#providing-personal-data" data-toggle="scroll-to">5. Providing your personal data to others</a>
                                    <a className="nav-link" href="#international-transfers" data-toggle="scroll-to">6. International transfers of your personal data</a>
                                    <a className="nav-link" href="#retaining-deleting" data-toggle="scroll-to">7. Retaining and deleting personal data</a>
                                    <a className="nav-link" href="#rights" data-toggle="scroll-to">8. Your rights</a>
                                    <a className="nav-link" href="#third-party-websites" data-toggle="scroll-to">9. Third party websites</a>
                                    <a className="nav-link" href="#personal-data-of-children" data-toggle="scroll-to">10. Personal data of children</a>
                                    <a className="nav-link" href="#updating-information" data-toggle="scroll-to">11. Updating information</a>
                                    <a className="nav-link" href="#acting-as-a-data-processor" data-toggle="scroll-to">12. Acting as a data processor</a>
                                    <a className="nav-link" href="#about-cookies" data-toggle="scroll-to">13. About cookies</a>
                                    <a className="nav-link" href="#cookies-we-use" data-toggle="scroll-to">14. Cookies that we use</a>
                                    <a className="nav-link" href="#service-providers" data-toggle="scroll-to">15. Cookies used by our service providers</a>
                                    <a className="nav-link" href="#managing-cookies" data-toggle="scroll-to">16. Managing cookies</a>
                                    <a className="nav-link" href="#cookie-preferences" data-toggle="scroll-to">17. Cookie preferences</a>
                                    <a className="nav-link" href="#amendments" data-toggle="scroll-to">18. Amendments</a>
                                    <a className="nav-link" href="#details" data-toggle="scroll-to">19. Our details</a>
                                    <a className="nav-link" href="#representatives" data-toggle="scroll-to">20. Representatives</a>
                                    <a className="nav-link" href="#data-protection-officer" data-toggle="scroll-to">21. Data protection officer</a>
                                </nav>
                            </NavScrollTo>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;