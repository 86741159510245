import { useEffect } from "react";
import {
  MapContainer,
  ImageOverlay,
  TileLayer,
  useMap,
  Marker, Popup
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

import L from "leaflet";

import "./coordinates-of-the-center-of-the-visible-map.css";

import newMarker from "./key.png";

const points = [
  {
    lat: 1925,
    lng: 3067,
    title: "Ahmed Grocery Store"
  },
  {
    lat: 52.22924516170657,
    lng: 21.011320352554325,
    title: 'point 2'
  },
  {
    lat: 52.229511304688444,
    lng: 21.01270973682404,
    title: 'point 3'
  },
  {
    lat: 52.23040500771883,
    lng: 21.012146472930908,
    title: 'point 4'
  },
];

const MyMarkers = ({ data }) => {
  return data.map(({ lat, lng, title }, index) => (
    <Marker
      icon={pointerIcon}
      key={index}
      position={{ lat, lng }}
    >
      <Popup>{title}</Popup>
    </Marker>
  ));
}

const GetCoordinates = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const legend = L.control({ position: "bottomleft" });

    const div = L.DomUtil.create("div", "legend");

    legend.onAdd = () => {
      const { lat, lng } = map.getCenter();
      const zoom = map.getZoom();

      L.DomEvent.disableClickPropagation(div);

      div.innerHTML = `center: ${lat.toFixed(5)}, ${lng.toFixed(
        5
      )} | zoom: ${zoom}`;

      return div;
    };

    legend.addTo(map);

    map.on("dragend zoomend", () => {
      const { lat, lng } = map.getCenter();
      const zoom = map.getZoom();
      div.innerHTML = `center: ${lat.toFixed(5)}, ${lng.toFixed(5)} | zoom: ${zoom}`;
    });
  }, [map]);

  return null;
};

const GetCurrentCoordinates = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    const info = L.DomUtil.create('div', 'legend');

    const positon = L.Control.extend({
      options: {
        position: 'bottomleft'
      },

      onAdd: function () {
        info.textContent = 'Click on map';
        return info;
      }
    })

    map.on('mousemove', (e) => {
      info.textContent = e.latlng;
    })

    map.addControl(new positon());

  }, [map])


  return null

}

const pointerIcon = new L.Icon({
  iconUrl: newMarker,
  iconSize: [50, 58], // size of the icon
  iconAnchor: [20, 58], // changed marker icon position
  popupAnchor: [0, -60], // changed popup position
});

export default function Map() {
  const bounds = [
    [0, 0],
    [4150, 4150],
  ];

  const setMaxBounds = [
    [-3000, -3000],
    [7150, 7150],
  ];
  
  const center = [1750.0, 2000.0];

  return (
      <MapContainer
        style={{ width: "100%", height: "550px", backgroundColor: "black", }}
        crs={L.CRS.Simple}
        zoom={-2}
        minZoom={-2}
        maxZoom={5}
        center={center}
        zoomControl={true}
        scrollWheelZoom={true}
        maxBounds={bounds}
        >
        <ImageOverlay
          bounds={bounds}
          url="https://github.com/SjorsWijsman/dmz-key-locations/raw/ffd9948a2ea7a9bbf8ba9147dd87528ef4f59970/static/map.png"
        />
        <GetCoordinates />
        <GetCurrentCoordinates />
        <MyMarkers data={points} />
      </MapContainer>
  );
}

//TODO1: when menu closed the map container does not stretch appropriatelly.