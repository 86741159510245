import { markNotificationAsRead } from '../components/utility_mark_as_read_notification.js';

const navigationMap = {
    "lobby_join": "/lobby",
    "lobby_leave": "/lobby",
    "rendezvous_interested": "/rendezvous",
    "rendezvous_uninterested": "/rendezvous",
    "friend_request": "/fireteam",
    "friend_request_accepted": "/fireteam",
    "friend_request_rejected": "/fireteam"
};

const NavigateTo = ({ type, notification_id, ButtonClass, ButtonText, navigate }) => {
    const handleNavigate = async () => {
        await markNotificationAsRead(notification_id);
        const path = navigationMap[type] || "/default";
        navigate(path);  // Use the passed navigate function
    };

    return (
        <button className={ButtonClass} onClick={handleNavigate}>
            {ButtonText}
        </button>
    );
};

export default NavigateTo;