import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';

export function SendFriendRequestForm({ user_id, users = [], backend_endpoint, setAckMsg, setErrMsg, ButtonClass, ButtonText }) {
    users = users || [];
    const formik = useFormik({
        initialValues: { user_id: user_id },
        validate: values => {
            const errors = {};
            if (!values.user_id) {
                errors.user_id = 'User ID is required';
            } else if (typeof values.user_id !== 'number' || !Number.isInteger(values.user_id)) {
                errors.user_id = 'User ID must be an integer';
            } else if (!users || !users.some(user => user.id === values.user_id)) {
                errors.user_id = 'Invalid user ID';
            }
            return errors;
        },
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const { user_id } = values;
                const formIdentifier = 'send_friendship_request'
                const payloadSubmission = { formIdentifier, user_id };
                const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
                if (data) {
                    setAckMsg(data);
                }
                if (error) {
                    setErrMsg(error);
                }
            } catch (error) {
                setErrMsg(error);
            } finally {
                setSubmitting(false);  // Ensures form is re-enable after async operation
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <input type="hidden" name="user_id" value={formik.values.user_id} onChange={formik.handleChange} />
            <button type="submit" className={ButtonClass}>
                {ButtonText}
            </button>
        </form>
    );
}