import { useFormik } from 'formik';
import { postGenericData } from '../../../api/generic-post.js';

export function RemoveFriendshipForm({ getFriendshipsData, friend_id, friends = [], backend_endpoint, setAckMsg, setErrMsg, ButtonClass, ButtonText }) {
	friends = friends || [];
	const formik = useFormik({
		initialValues: { friend_id: friend_id },
		validate: values => {
			const errors = {};
			if (!values.friend_id) {
				errors.friend_id = 'User ID is required';
			} else if (typeof values.friend_id !== 'number' || !Number.isInteger(values.friend_id)) {
				errors.friend_id = 'User ID must be an integer';
			} else if (!friends || !friends.some(user => user.id === values.friend_id)) {
				errors.friend_id = 'Invalid user ID';
			}
			return errors;
		},
		onSubmit: async (values, { setSubmitting, setErrors }) => {
			try {
				const { friend_id } = values;
				const formIdentifier = 'remove_friendship'
				const payloadSubmission = { formIdentifier, friend_id };
				const { data, error } = await postGenericData(backend_endpoint, payloadSubmission);
				if (data) {
					setAckMsg(data);
					await getFriendshipsData();
				}
				if (error) {
					setErrMsg(error);
				}
			} catch (error) {
				setErrMsg(error);
			} finally {
				setSubmitting(false);  // Ensures form is re-enable after async operation
			}
		}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<input type="hidden" name="friend_id" value={formik.values.friend_id} onChange={formik.handleChange} />
			<button type="submit" className={ButtonClass}>
				{ButtonText}
			</button>
		</form>
	);
}