import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import axiosInstance from '../../../api/external-api.service.js'
import { getGenericData } from '../../../api/generic-get.js';

export function useNotifications(id, isAuthenticated) {

    const notificationSound = new Audio('/assets/data/notifications/GeigerCounterDigi.mp3');

    const backend_endpoint = '/api/notifications';
    const [notifications, setNotifications] = useState([]);

    // Function to mark notification as read
    const setNotificationAsRead = notificationId => {
        setNotifications(currentNotifications =>
            currentNotifications.map(n =>
                n.id === notificationId ? { ...n, read: true } : n
            )
        );
    };

    const [reconnectAttempts, setReconnectAttempts] = useState(0);

    useEffect(() => {

        const getNotificationsData = async () => {
            try {
                const queryParams = new URLSearchParams({
                    notifications_header: true,
                });
                const url = `${backend_endpoint}?${queryParams.toString()}`;
                const { data, error } = await getGenericData(url);

                if (data && Object.keys(data).length > 0 && !data.message) {
                    setNotifications(data.notifications || []);
                    // console.log(data.notifications)
                } else {
                    setNotifications([]);
                }

                if (error) {
                    //setErrMsg(error);
                    console.error('Error fetching notifications:', error);
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        if (id && isAuthenticated) {
            getNotificationsData(); // Only call if user_id is present
        }

        const socket_namespace = 'notifications';
        const socket = io(`${axiosInstance.defaults.baseURL}/${socket_namespace}`, {
            path: '/socket.io',
            transports: ['websocket'],
            reconnection: true,       // Enable automatic reconnection
            reconnectionAttempts: Infinity, // Unlimited reconnection attempts
            reconnectionDelay: 2000,   // Initial delay before attempting a new reconnection (in milliseconds)
            reconnectionDelayMax: 30000, // Maximum delay between reconnections (in milliseconds)
            randomizationFactor: 0.5,  // Randomization factor for the reconnection delay to avoid surges
            timeout: 15000,            // Timeout for each connection attempt (in milliseconds)
            withCredentials: true // I DO NOT need to pass CREDENTIALS, using current_user.is_authenticated at the backend.
        });

        const handleReconnectAttempt = () => {
            const maxDelay = 30000;  // Max delay capped at 30 seconds
            const minDelay = 2000;
            //const delay = 2000;  // Use a fixed delay of 2 seconds after each attempt
            const delay = Math.min(minDelay * (5 ** reconnectAttempts), maxDelay);
            setTimeout(() => {
                if (socket && !socket.connected) {
                    socket.connect();
                }
            }, delay);

            setReconnectAttempts(attempts => attempts + 1);
        };

        const handleConnect = () => {
            console.log('Successfully connected with sid:', socket.id);
            setReconnectAttempts(0);  // Reset reconnection attempts on a successful connection
        };

        const handleNotification = notification => {
            console.log('Notification:', notification);

            notificationSound.play();

            setNotifications(prevNotifications => {
                // Add the new notification at the start of the array
                const updatedNotifications = [notification, ...prevNotifications];
                // Return only the first five notifications
                return updatedNotifications.slice(0, 5);
            });
        };

        const handleDisconnect = reason => {
            console.log('Disconnected:', reason);
            const shouldReconnect = reason === 'io server disconnect' || reason === 'ping timeout' || reason === 'transport close' || reason === 'transport error';
            if (shouldReconnect) {
                console.log('Attempting to reconnect due to', reason);
                handleReconnectAttempt();  // Use exponential backoff to attempt reconnection
            } else {
                console.log('Disconnected for another reason, attempting to reconnect');
                handleReconnectAttempt();  // Use exponential backoff to attempt reconnection
            }
        };

        const handleReconnection = attemptNumber => {
            console.log(`Reconnected to the server on attempt ${attemptNumber}`);
        };

        socket.on('connect', handleConnect);
        socket.on('notification', handleNotification);
        socket.on('disconnect', handleDisconnect);

        socket.on('reconnect', handleReconnection);
        socket.on('reconnect_attempt', () => {
            console.log(`Attempting to reconnect to the server`);
            handleReconnectAttempt();
        });
        socket.on('reconnect_error', error => console.log('Reconnection failed due to error:', error));
        socket.on('reconnect_failed', () => console.log('Failed to reconnect to the server after all attempts'));
        socket.on('error', error => console.log('Error:', error));

        return () => {
            socket.off('connect', handleConnect);
            socket.off('notification', handleNotification);
            socket.off('disconnect', handleDisconnect);
            socket.off('reconnect', handleReconnection);
            socket.off('reconnect_attempt');
            socket.off('reconnect_error');
            socket.off('reconnect_failed');
            socket.off('error');

            socket.close(); // Ensure the socket is closed when the component unmounts
        };

    }, []);

    return { notifications, setNotificationAsRead };
}