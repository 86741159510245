import React from 'react';
import axiosInstance from "../../api/external-api.service.js";

const UserImage = ({ imageType, altText, className = '', height, width, style = {}, defaultImage }) => {
    const imageUrl = imageType
        ? `${axiosInstance.defaults.baseURL}/${axiosInstance.defaults.uploads}/${imageType}`
        : defaultImage || `${axiosInstance.defaults.baseURL}/${axiosInstance.defaults.uploads}/image.jpg`;

    const combinedStyle = { ...style, height, width };

    return (
        <img src={imageUrl} alt={altText} className={className} style={combinedStyle} />
    );
};

export default UserImage;