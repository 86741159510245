import React from 'react';
import { NavScrollTo } from '../../components/nav-scroll-to/nav-scroll-to';
import { Link } from 'react-router-dom';

function Intel() {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10">
                    <div className="row">
                        <div className="col-xl-8">

                            <h1>Intel</h1>

                            <div id="mission" className="mb-5">
                                <h2>1. Mission Objectives</h2>
                                <p>This website was created to help the community to find players for various game modes, based on personal preferences.</p>
                                <p>You must have Discord ID be able to use main application features and to communicate with community memebrs on our Discord Community Channel.</p>
                                <p>The website application will not have chat functionlity. Its simply pointless to reinvent the wheel where our core application functionality is built around Discrod, which provides everything community needs for communication.</p>
                                <p>You can create Lobby and find players based on Mode, KD, RANK and playstyle.</p>
                                <p>Find players, make friends or browse recent players you played with to invite them again to your Lobby.</p>
                                <p>You can leave Rendezvous notes ("LFP", "LFG"), which can gain interests from other players, who may be interested in playing with you, based on schedule and availability.</p>
                                <p>You can import official game profile stats by uploading an offically generated image in your profile settings to become "verified" player.</p>
                                <p>Verified players should enjoy the recognition on the Community Leaderboard stats, and therefore have ability to form lobbies much easier.</p>
                                <p>We encorouge everyone to share their stats. Its not always about the KD. You can be solo daemon and play poorly as team leader of a weaker squad.</p>
                                <p>It's our aim for you to share stats as vital information for the another application core feature - Custom Matches.</p>
                                <p>You can create a Clan and add Clan Members.</p>
                                <p>Clans can compete against other Clans in Custom Matches.</p>
                                <p>Solo players can create Squads from random players based on the shared stats or join their Friends to compete against other Squads and Clans in Custom Matches.</p>
                                <p>Custom Matches feature however may shuffle players around to form fair Lobbies for fair Gameplay.</p>
                                <p>This will usually happen when the Custom Match lobby is unfairly uneven.</p>
                                <p>Custom Matches should provide our cummunity fun gameplay with moderate pace for everyone where the most skilled players should take team leader resposibility of their troops. Troops should be able to skill up too not only be the grind meat.</p>
                                <p>However! If you seek the pros like challenges, our next core application feature is dedicated especially for you.</p>
                                <p>Tournaments is the space where competetive players can compete againts other competetive players.</p>
                                <p>A solo with other random high KD/high RANK players vs another Random Squad, vs Fireteam Squads vs Clans. Free for all.</p>
                                <p>Compete with friends in Tournaments and gain recognition as most winning Team in the Community!</p>
                                <p>Every season Tournament Leaderboard stats gets wiped, so your team have always the chance to reach Hall of Fame for the Team of the Season!</p>
                                <p>Finally, we will provide Maps and Loadouts functionality, however our common sense tells us you should use other well established community solutions designated for this matter.</p>
                                <p>Cheating! Is strictly prohibited! If you find someone is cheating report to the admins or moderators.</p>
                                <p>Cheating account will be banned as well as any associated friends and Clan members will receive penelties, or bans too, no exception!</p>
                            </div>

                            <div id="motivation" className="mb-5">
                                <h2>2. Motivation</h2>
                                <p>"I found difficult to find players to play various games modes."</p>
                                <p>Beyond that I found difficult to play regular matches infested with cheaters where I lose gunfights where I should not.</p>
                                <p>To resolve this along with people who like to play fair either for fun or enjoy competetive play,</p>
                                <p>I created this application and this community to give us a chance to enjoy the game again.</p>
                                <p>Either if you would like to play a casual game mode, RANKED with players on similar level like you, or enjoy competing on a tournament within a closed community for highest recognition rewards,</p>
                                <p>this website and its community is for you.</p>
                                <p>Join us!</p>
                            </div>

                            <div id="howto" className="mb-5">
                                <h2>3. How To</h2>
                                <p>To use our website application you need to have Discord Account and first and foremost you need to join our Discord Channel at: <Link to="https://discord.com/invite/jmQfQhD2Ny" className='text-warning text-decoration-none login-link'>WARFARE ZONE DISCORD</Link></p>
                                <p>Next once you joined our Discord channel, to be able to use the application core features you should provide and verify your Discord ID at profile <Link to="/settings" className='text-warning text-decoration-none login-link'>/settings</Link></p>
                                <p>Provide your ATVI ID in settings.</p>
                                <p>For the best experience you would need to have the in game ID verified, by uploading the "ID stats picture" officially generated by the provider on twitter / X.</p>
                                <p>You can get that by creating a post on twitter / X with your in game ID and game provider associated hashtags. ("ATVI#ID #CODCombatRecord @CallofDuty")</p>
                                <p>Then select option from either 1 Stat image or 3 Stats for verification method, and upload the picture.</p>
                                <p>Ensure that the stat picture is in its original format (not trimmed, cropped, screenshoted) when you upload it for verification, otherwise its poor quality may prevent verification.</p>
                                <p>Once all of your details are provided you will be able to create Lobby using website features and use it on Discord to communicate with other players.</p>
                                <p>Stats will get cross verified manually, some other stats may need to be manually verified too for the moment.</p>
                                <p>In Development: Dashboard KD, KD Stats Tracking, Custom Matches, Tournaments</p>
                                <p>More... </p>
                                <p className="text-warning">-- Stay Frosty!  --</p>
                            </div>
                        </div>
                        <div className="col-xl-4 sticky-nav">
                            <NavScrollTo>
                                <nav className="nav">
                                    <a className="nav-link" href="#mission" data-toggle="scroll-to">1. Mission Objectives</a>
                                    <a className="nav-link" href="#motivation" data-toggle="scroll-to">2. Motivation</a>
                                    <a className="nav-link" href="#howto" data-toggle="scroll-to">3. How To</a>
                                </nav>
                            </NavScrollTo>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intel;